import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  SystemStyleObject,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import IconButton from "components/base/IconButton";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { Meeting, MeetingPlan as MeetingPlanType } from "./types";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import TabHeader from "components/base/TabHeader";
import { getMeetingVideo, getMeetings } from "reducers/meeting/meetingReducer";
import { ReactComponent as TaskListIcon } from "assets/icons/task-list.svg";
import { ReactComponent as MeetingPlanIcon } from "assets/icons/meeting-plan.svg";
import MeetingHistory from "./MeetingHistory";
import MeetingTasks from "./MeetingTasks";
import { addMeetingActionItem } from "reducers/action-item/actionItemReducer";
import MeetingDetails from "./MeetingDetails";
import { getTags } from "reducers/tags/tagsReducer";
import MeetingPlan from "../meeting-plan/MeetingPlan";
import Icon from "components/base/Icon";
import { addMeetingTemplate } from "reducers/meeting-template/meetingTemplateReducer";
import { MeetingTemplateTypeMap } from "components/templates/Templates";
import { isDateInPast, subtractDatesStrInMinutes } from "utils/common";
import { ReactComponent as MeetingResultsIcon } from "assets/icons/meeting-results.svg";
import MeetingResults from "../meeting-results/MeetingResults";
import { ReactComponent as TranscriptIcon } from "assets/icons/transcript.svg";
import Transcript from "components/meeting-results/Transcript";

export const tabSelectedStyle: SystemStyleObject = {
  borderBottom: "1px",
  borderColor: "echoBlue.500",
  color: "gray.800",
  "& path": { fill: "echoBlue.500" },
};

type TabId = "plan" | "tasks" | "history" | "results";

interface MeetingPageProps {}

const MeetingPage: React.FC<MeetingPageProps> = () => {
  const dispatch = useAppDispatch();
  const meetingState = useAppSelector((state) => state.meeting);

  const { meetingId } = useParams();

  const [meeting, setMeeting] = useState<Meeting | undefined>();

  const [activeMainTabIndex, setActiveMainTabIndex] = useState(0);

  const [activeDetailsTabIndex, setActiveDetailsTabIndex] = useState(0);
  const [cache, setCache] = useState<{ [key: string]: boolean }>({});
  const [searchTerms, setSearchTerms] = useState<{ [key in TabId]: string | null }>({
    plan: null,
    tasks: "",
    history: "",
    results: null,
  });

  const [newMeetingPlan, setNewMeetingPlan] = useState<MeetingPlanType | null>(null);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateType, setNewTemplateType] = useState(Object.keys(MeetingTemplateTypeMap)[0]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const isPastMeeting = meeting?.end_time !== undefined && isDateInPast(meeting.end_time);

  const MainTabs: { identifier: TabId; [key: string]: any }[] = isPastMeeting
    ? [
        {
          identifier: "results",
          name: "Meeting results",
          icon: MeetingResultsIcon,
          panel: <MeetingResults name={meeting.name} meetingResults={meetingState.meetingVideo?.meeting_results} />,
        },
      ]
    : [
        {
          identifier: "plan",
          name: "Meeting plan",
          icon: MeetingPlanIcon,
          panel: (
            <MeetingPlan
              plan={meeting?.meeting_plan}
              meetingId={meetingId}
              meetingDuration={subtractDatesStrInMinutes(meeting?.start_time, meeting?.end_time)}
              onSaveTemplate={(_, mp) => {
                setNewMeetingPlan(mp);
                setNewTemplateName(mp.name);
                setTimeout(() => {
                  setIsAddModalOpen(true);
                }, 10);
              }}
            />
          ),
        },
        {
          identifier: "history",
          name: "Meeting history",
          icon: NoteIcon,
          panel: (
            <MeetingHistory cache={cache} setCache={setCache} meeting={meeting!} searchTerm={searchTerms["history"]} />
          ),
        },
        {
          identifier: "tasks",
          name: "Pre-meeting tasks",
          icon: TaskListIcon,
          panel: (
            <MeetingTasks
              cache={cache}
              setCache={setCache}
              meetingId={Number(meetingId)}
              searchTerm={searchTerms["tasks"]}
            />
          ),
          onAdd: () => {
            dispatch(
              addMeetingActionItem({
                name: "",
                description: "",
                created_on_meeting: null,
                references: [{ id: Number(meetingId), type: "meeting" }],
              })
            );
          },
        },
      ];
  const DetailsTabs = isPastMeeting
    ? [
        {
          identifier: "transcript",
          name: "Transcript",
          icon: TranscriptIcon,
          panel: <Transcript transcript={meetingState.meetingVideo?.formatted_transcription} />,
        },
      ]
    : [{ identifier: "details", name: "Details", icon: PersonIcon, panel: <MeetingDetails meeting={meeting} /> }];

  useEffect(() => {
    dispatch(getTags());
  }, []);

  useEffect(() => {
    if (isPastMeeting && meetingId) {
      dispatch(getMeetingVideo(Number(meetingId)));
    }
  }, [isPastMeeting, meetingId]);

  useEffect(() => {
    let currMeeting = meetingState.meetings?.find((c) => c.id === Number(meetingId));
    if (currMeeting) {
      setMeeting(currMeeting);
    } else if (!meetingState.status["primary"] && !meetingState.errors["primary"]) dispatch(getMeetings());
  }, [meetingState.meetings, meetingId]);

  return (
    <Flex w="100%" direction={"column"}>
      <Box className="header" p={4}>
        <HStack gap={3} alignItems={"center"}>
          <Link to="/meetings">
            <IconButton icon={ArrowLeftIcon} color={"gray.500"} />
          </Link>
          <Breadcrumb sx={{ "& ol": { mb: 0, pl: 0 } }} fontWeight={500} color={"gray.500"} fontSize={"sm"}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/meetings">
                Meetings
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color={"gray.800"}>
              <BreadcrumbLink>
                <Center gap={2}>
                  <Text>{meeting?.name}</Text>
                </Center>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
      </Box>
      <Divider my={0} />
      <HStack gap={0} flex={1} alignItems={"stretch"}>
        <Box minW="75%">
          <Tabs
            index={activeMainTabIndex}
            variant="unstyled"
            isLazy={true}
            color={"gray.400"}
            onChange={(index) => {
              setActiveMainTabIndex(index);
            }}
          >
            <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
              {MainTabs.map((tab) => {
                return (
                  <Tab _selected={tabSelectedStyle} py={3} fontSize={"sm"} key={tab.name} borderRadius={0}>
                    <Icon as={tab.icon} mr={1} />
                    {tab.name}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels>
              {MainTabs.map((tab, index) => {
                return (
                  <Box key={tab.identifier}>
                    <TabHeader
                      tab={tab.identifier}
                      show={activeMainTabIndex === index}
                      {...(tab.onAdd ? { onAdd: tab.onAdd } : {})}
                      searchTerm={searchTerms[tab.identifier]}
                      setSearchTerm={(newValue) =>
                        setSearchTerms((prev) => {
                          return { ...prev, [tab.identifier]: newValue };
                        })
                      }
                    />
                    <TabPanel p={0}>{tab.panel}</TabPanel>
                  </Box>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>

        <Box borderLeft={"1px"} borderColor={"gray.100"} maxW={"25%"}>
          <Tabs
            index={activeDetailsTabIndex}
            variant="unstyled"
            isLazy={true}
            color={"gray.400"}
            onChange={(index) => setActiveDetailsTabIndex(index)}
            display={"flex"}
            flexDir={"column"}
          >
            <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
              {DetailsTabs.map((tab) => {
                return (
                  <Tab _selected={tabSelectedStyle} fontSize={"sm"} py={3} key={tab.identifier} borderRadius={0}>
                    <Icon as={tab.icon} mr={1} />
                    {tab.name}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels flex={1} overflowX={"auto"}>
              {DetailsTabs.map((tab) => {
                return (
                  <TabPanel key={tab.identifier} p={0}>
                    {tab.panel}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </HStack>
      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Save as template</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Input
              autoFocus
              id="name"
              name="name"
              placeholder="Please enter template name"
              onChange={(e) => setNewTemplateName(e.target.value)}
              value={newTemplateName}
              fontSize={"sm"}
              size={"sm"}
              color={"gray.800"}
              borderRadius={4}
              outline={"1px"}
              mb={4}
              _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
            />
            <Select
              fontSize={"sm"}
              size={"sm"}
              color={"gray.800"}
              borderRadius={4}
              outline={"1px"}
              minW={"fit-content"}
              mb={4}
              _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
              value={newTemplateType}
              onChange={(e) => {
                setNewTemplateType(e.target.value);
              }}
            >
              {Object.entries(MeetingTemplateTypeMap).map(([key, value]) => {
                return (
                  <option value={key} key={key}>
                    {value}
                  </option>
                );
              })}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button color={"gray.500"} mr={4} variant={"outline"} onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="echoBlue"
              onClick={() => {
                if (newMeetingPlan) {
                  dispatch(
                    addMeetingTemplate({
                      ...newMeetingPlan,
                      name: newTemplateName,
                      meta: { type: newTemplateType, checked: [] },
                    })
                  );
                }
                setIsAddModalOpen(false);
                setNewTemplateName("");
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MeetingPage;
