import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { ContactState } from "./contactTypes";
import { Contact } from "components/contacts/types";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { updateResource } from "utils/common";

const API_BASE = process.env.REACT_APP_TAILORR_API_ADDRESS;

export const getContacts = createAsyncThunk("contact/getContacts", async () => {
  const url = `${API_BASE}/contact/`;
  return await get(url);
});

export const updateContact = createAsyncThunk("contact/updateContact", async (body: Partial<Contact>) => {
  const { id, ...rest } = body;
  const url = `${API_BASE}/contact/${id}`;
  return await put(url, rest);
});

export const addContact = createAsyncThunk("contact/addContact", async (body: Partial<Contact>) => {
  const url = `${API_BASE}/contact/`;
  return await post(url, body);
});

export const deleteContact = createAsyncThunk("contact/deleteContact", async (id: number) => {
  const url = `${API_BASE}/contact/${id}`;
  await http_delete(url);
  return id;
});

const pending = (key: extraReducersKey) => (state: ContactState) => {
  state.status[key] = "fetching";
};

const fulfilled =
  (key: extraReducersKey) => (state: ContactState, action: PayloadAction<Contact[] | Contact | number>) => {
    state.status[key] = "fulfilled";
    delete state.errors[key];

    switch (key) {
      case "primary":
        state.contacts = action.payload as Contact[];
        break;

      case "add":
        state.currentContact = action.payload as Contact;
        break;

      case "update":
        state.contacts = updateResource(state.contacts, action.payload as Contact);
        break;

      case "delete":
        break;

      default:
        break;
    }
  };

const rejected = (key: extraReducersKey) => (state: ContactState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: ContactState = {
  contacts: [],
  currentContact: null,
  errors: {},
  status: {},
};

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
    setCurrContact: (state, action: PayloadAction<Contact>) => {
      state.currentContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getContacts, "primary");
    handleAsyncActions(builder, updateContact, "update");
    handleAsyncActions(builder, addContact, "add");
    handleAsyncActions(builder, deleteContact, "delete");
  },
});

const handleAsyncActions = (
  builder: ActionReducerMapBuilder<ContactState>,
  actionThunk: any,
  actionKey: extraReducersKey
) => {
  builder
    .addCase(actionThunk.pending, pending(actionKey))
    .addCase(actionThunk.fulfilled, fulfilled(actionKey))
    .addCase(actionThunk.rejected, rejected(actionKey));
};

type extraReducersKey = "primary" | "update" | "add" | "delete";

export const { resetStatus, resetError, setCurrContact } = contactSlice.actions;
export default contactSlice.reducer;
