import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { Workspace, WorkspaceState } from "./workspaceTypes";
import { User } from "reducers/user/userTypes";
import { PersonOption } from "components/contacts/table/Meta";

export const getWorkspaceUsers = createAsyncThunk("workspace/getWorkspaceUsers", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/workspace/users`;
  return await get(url);
});

export const addWorkspace = createAsyncThunk("workspace/addWorkspace", async (body: Partial<Workspace>) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/workspace/`;
  await post(url, body);
  return await get(url);
});

export const deleteWorkspace = createAsyncThunk("workspace/deleteWorkspace", async (id: number | string) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/workspace/`;
  await http_delete(url + id);
  return await get(url);
});

//PENDING

const isFetching = (key: string) => (state: WorkspaceState) => {
  state.status[key] = "fetching";
};

//FULFILLED

const gotWorkspaces = (key: string) => (state: WorkspaceState, action: PayloadAction<User[]>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];

  state.users = action.payload;
  state.usersOption = action.payload.map((user) => {
    return {
      id: user.id.toString(),
      type: "person",
      value: user.first_name + " " + user.last_name,
      avatar: "",
    } as PersonOption;
  });
};

const updatedWorkspace = (key: string) => (state: WorkspaceState, action: PayloadAction<Workspace[]>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];
};

//REJECTED

const notGotWorkspaces = (key: string) => (state: WorkspaceState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: WorkspaceState = {
  users: [],
  usersOption: [],
  errors: {},
  status: {},
};

export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceUsers.pending, isFetching("primary"))
      .addCase(getWorkspaceUsers.fulfilled, gotWorkspaces("primary"))
      .addCase(getWorkspaceUsers.rejected, notGotWorkspaces("primary"))

      .addCase(deleteWorkspace.pending, isFetching("delete"))
      .addCase(deleteWorkspace.fulfilled, gotWorkspaces("delete"))
      .addCase(deleteWorkspace.rejected, notGotWorkspaces("delete"));
  },
});

export const { resetStatus, resetError } = workspaceSlice.actions;
export default workspaceSlice.reducer;
