import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { TagState } from "./tagsTypes";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { Tag } from "components/meetings/types";

export const getTags = createAsyncThunk("tag/getTags", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/tag/`;
  return await get(url);
});

export const updateTag = createAsyncThunk("tag/updateTag", async (body: Partial<Tag>) => {
  const { id, ...rest } = body;
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/tag/`;
  await put(url + id, rest);
  return await get(url);
});

export const addTag = createAsyncThunk("tag/addTag", async (body: Partial<Tag>) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/tag/`;
  await post(url, body);
  return await get(url);
});

export const deleteTag = createAsyncThunk("tag/deleteTag", async (id: number | string) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/tag/`;
  await http_delete(url + id);
  return await get(url);
});

//PENDING

const isFetching = (key: string) => (state: TagState) => {
  state.status[key] = "fetching";
};

//FULFILLED

const gotTag = (key: string) => (state: TagState, action: PayloadAction<Tag>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];

  state.currentTag = action.payload;
};

const gotTags = (key: string) => (state: TagState, action: PayloadAction<Tag[]>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];

  state.payload = action.payload;
};

const updatedTag = (key: string) => (state: TagState, action: PayloadAction<Tag[]>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];
};

//REJECTED

const notGotTags = (key: string) => (state: TagState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: TagState = {
  payload: [],
  currentTag: null,
  errors: {},
  status: {},
};

export const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTags.pending, isFetching("primary"))
      .addCase(getTags.fulfilled, gotTags("primary"))
      .addCase(getTags.rejected, notGotTags("primary"))

      .addCase(updateTag.pending, isFetching("update"))
      .addCase(updateTag.fulfilled, gotTags("update"))
      .addCase(updateTag.rejected, notGotTags("update"))

      .addCase(addTag.pending, isFetching("add"))
      .addCase(addTag.fulfilled, gotTag("add"))
      .addCase(addTag.rejected, notGotTags("add"))

      .addCase(deleteTag.pending, isFetching("delete"))
      .addCase(deleteTag.fulfilled, gotTags("delete"))
      .addCase(deleteTag.rejected, notGotTags("delete"));
  },
});

export const { resetStatus, resetError } = tagSlice.actions;
export default tagSlice.reducer;
