import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  useStyleConfig,
} from "@chakra-ui/react";
import { Cell, ColumnDef, Table } from "@tanstack/react-table";
import { useFormik } from "formik";
import { Contact } from "../../types";
import { generateAccessorKey, generateCell, generateFilterFn } from "../../table/ColumnGenerator";
import { BackendColumnDef, CellTypesWithOptions, ColumnType } from "reducers/contact-table/contactTableTypes";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { addContactTableColumn, updateContactTable } from "reducers/contact-table/contactTableReducer";
import { useEffect, useState } from "react";
import EnterInput from "components/base/EnterInput";
import { valueTextMap } from "../ContactTable";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as ReOrderDotsVerticalIcon } from "assets/icons/re-order-dots-vertical.svg";
import IconButton from "components/base/IconButton";
import Badge from "components/base/Badge";
import { SelectOption, StatusOption } from "../Meta";
import { AvailableColors } from "types/types";
import { pickRandomItem, shallowCopy, subtractArrays } from "utils/common";
import DrawerOptions from "./DrawerOptions";

const generateOptionId = (type: string) => {
  const hash = (+new Date()).toString(36);
  return `_${type}_option-${hash}`;
};

interface NewColDrawerProps {
  table: Table<Contact>;
  isOpen: boolean;
  onClose: (accKey?: string, type?: ColumnType) => void;
  defaultType: ColumnType;
}

const NewColDrawer: React.FC<NewColDrawerProps> = ({ isOpen, onClose, table, defaultType }) => {
  const dispatch = useAppDispatch();

  const meta = useAppSelector((state) => state.contactTable.contactTable.meta);

  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        const drawer = document.getElementById("chakra-modal-new-col");
        const table = document.querySelector(".contact-table") as HTMLDivElement;
        const thead = table.querySelector("thead") as HTMLTableSectionElement;
        if (drawer && table && thead) {
          drawer.style.top = table.getBoundingClientRect().y + thead.getBoundingClientRect().height + 1 + "px";
          drawer.style.height = "fit-content";
          drawer.style.maxHeight =
            window.innerHeight - (table.getBoundingClientRect().y + thead.getBoundingClientRect().height + 20) + "px";
          drawer.style.borderTopLeftRadius = "4px";
          drawer.style.borderBottomLeftRadius = "4px";
          clearInterval(intervalId); // Stop the interval once successful
        }
      } catch (error) {
        console.error("Error while trying to assign a value:", error);
      }
    }, 10);

    if (!isOpen) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [isOpen]);

  const formik = useFormik({
    initialValues: {
      name: valueTextMap[defaultType],
      type: defaultType,
      options: [] as SelectOption[] | StatusOption[],
    },
    onSubmit: (values) => {
      if (!values.name) return;
      let accKey = generateAccessorKey(values.name);
      if (table.options.meta?.createColumn)
        table.options.meta.createColumn({
          accessorKey: accKey,
          header: values.name,
          enableColumnFilter: true,
          filterFn: generateFilterFn(values.type as ColumnType),
          cell: generateCell(values.type as ColumnType),
          meta: { type: values.type, options: { values: values.options }, archived: false },
        } as ColumnDef<Contact>);
      dispatch(
        addContactTableColumn({
          accessor_key: accKey,
          header: values.name,
          type: values.type as ColumnType,
          options: { values: values.options },
          archived: false,
          order: table.getAllColumns().length,
          hidden: false,
        })
      );
      table.options.meta!.setColumnOrder([...meta.columnOrder, accKey]);
      onClose(accKey, values.type as ColumnType);
    },
  });

  const addOption = (e: React.FocusEvent<HTMLInputElement>) => {
    if (newOptionValue.length === 0) return;
    const currOptions = shallowCopy(formik.values.options);
    if (
      defaultType === "status" &&
      (currOptions as StatusOption[]).find((option) => option.value === newOptionValue) !== undefined
    )
      return;
    else if ((currOptions as SelectOption[]).find((option) => option.value === newOptionValue) !== undefined) return;
    const optionId = generateOptionId(defaultType);
    const takenColors = formik.values.options.map((option) => option.color);
    const colorsLeft = subtractArrays(AvailableColors, takenColors);
    const newColor = colorsLeft.length > 0 ? pickRandomItem(colorsLeft) : pickRandomItem(AvailableColors);
    formik.setFieldValue("options", [
      ...formik.values.options,
      { type: defaultType, value: newOptionValue, color: newColor, id: optionId },
    ]);
    setNewOptionValue("");
    setTimeout(() => {
      // document.getElementById(optionId)?.focus();
      e.target.focus();
    }, 100);
  };

  return (
    <Drawer id="new-col" isFullHeight={false} size={"xs"} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay bgColor={"blackAlpha.50"} />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerHeader
            pt={3}
            pb={2}
            px={2}
            gap={2}
            borderBottomWidth="0px"
            fontSize={"sm"}
            fontWeight={500}
            color={"gray.800"}
            display={"flex"}
            alignItems={"center"}
          >
            <IconButton icon={ArrowLeftIcon} color={"gray.500"} onClick={() => onClose()} />
            <Box as="span">{valueTextMap[defaultType]} column</Box>
          </DrawerHeader>
          <DrawerBody>
            <Stack gap={2}>
              <Box>
                <Input
                  autoFocus
                  id="name"
                  name="name"
                  placeholder="Please enter column name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  fontSize={"sm"}
                  size={"sm"}
                  color={"gray.800"}
                  borderRadius={4}
                  outline={"1px"}
                  _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                />
              </Box>
              {CellTypesWithOptions.includes(defaultType) && (
                <>
                  <Divider my={1} />
                  <Flex justify={"space-between"} align={"center"}>
                    <Text m={0} fontSize={"sm"} fontWeight={500} color={"gray.800"}>
                      Options
                    </Text>
                    <IconButton icon={AddIcon} color={"gray.500"} onClick={() => setIsAddingOption(true)} />
                  </Flex>
                  {isAddingOption && (
                    <EnterInput
                      mt={2}
                      autoFocus={true}
                      value={newOptionValue}
                      onChange={(e) => setNewOptionValue(e.target.value)}
                      onBlur={(e, isEnterKey) => {
                        if (isEnterKey) addOption(e);
                      }}
                    />
                  )}
                  <DrawerOptions options={formik.values.options} setFieldValue={formik.setFieldValue} />
                </>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" size={"sm"} mr={3} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type="submit" variant={"outline"} size={"sm"} colorScheme="blue">
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};

export default NewColDrawer;
