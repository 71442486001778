import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { MeetingTemplate } from "components/meetings/types";
import { MeetingTemplateState } from "./meetingTemplateTypes";
import { addResource, deleteResource, updateResource } from "utils/common";

export const getMeetingTemplates = createAsyncThunk("meeting-template/getMeetingTemplates", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/meeting-template/`;
  return await get(url);
});

export const getMeetingTemplate = createAsyncThunk("meeting-template/getMeetingTemplate", async (id: number) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/meeting-template/${id}`;
  return await get(url);
});

export const updateMeetingTemplate = createAsyncThunk(
  "meeting-template/updateMeetingTemplate",
  async (body: Partial<MeetingTemplate>) => {
    const { id, ...rest } = body;
    const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/meeting-template/${id}`;
    return await put(url, rest);
  }
);

export const addMeetingTemplate = createAsyncThunk(
  "meeting-template/addMeetingTemplate",
  async (body: Partial<MeetingTemplate>) => {
    const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/meeting-template/`;
    return await post(url, body);
  }
);

export const deleteMeetingTemplate = createAsyncThunk("meeting-template/deleteMeetingTemplate", async (id: number) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/meeting-template/${id}`;
  await http_delete(url);
  return id;
});

const pending = (key: extraReducersKey) => (state: MeetingTemplateState) => {
  state.status[key] = "fetching";
};

const fulfilled =
  (key: extraReducersKey) =>
  (state: MeetingTemplateState, action: PayloadAction<MeetingTemplate[] | MeetingTemplate | number>) => {
    state.status[key] = "fulfilled";
    delete state.errors[key];

    switch (key) {
      case "primary":
        state.meetingTemplates = action.payload as MeetingTemplate[];
        break;

      case "get_meeting_plan":
        state.currMeetingTemplate = action.payload as MeetingTemplate;
        break;

      case "delete":
        state.meetingTemplates = deleteResource(state.meetingTemplates, action.payload as number);
        break;

      case "add":
        state.meetingTemplates = addResource(state.meetingTemplates, action.payload as MeetingTemplate);
        state.currMeetingTemplate = action.payload as MeetingTemplate;
        break;

      case "update":
        state.meetingTemplates = updateResource(state.meetingTemplates, action.payload as MeetingTemplate);
        state.currMeetingTemplate = action.payload as MeetingTemplate;
        break;

      default:
        break;
    }
  };

const rejected = (key: extraReducersKey) => (state: MeetingTemplateState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: MeetingTemplateState = {
  meetingTemplates: [],
  currMeetingTemplate: null,
  errors: {},
  status: {},
};

export const meetingTemplateSlice = createSlice({
  name: "meeting-template",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getMeetingTemplates, "primary");
    handleAsyncActions(builder, updateMeetingTemplate, "update");
    handleAsyncActions(builder, addMeetingTemplate, "add");
    handleAsyncActions(builder, deleteMeetingTemplate, "delete");
    handleAsyncActions(builder, getMeetingTemplate, "get_meeting_plan");
  },
});

const handleAsyncActions = (
  builder: ActionReducerMapBuilder<MeetingTemplateState>,
  actionThunk: any,
  actionKey: extraReducersKey
) => {
  builder
    .addCase(actionThunk.pending, pending(actionKey))
    .addCase(actionThunk.fulfilled, fulfilled(actionKey))
    .addCase(actionThunk.rejected, rejected(actionKey));
};

type extraReducersKey = "primary" | "update" | "add" | "delete" | "get_meeting_plan";

export const { resetStatus, resetError } = meetingTemplateSlice.actions;
export default meetingTemplateSlice.reducer;
