import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import { ReactComponent as MeetingPlanIcon } from "assets/icons/meeting-plan.svg";
import MeetingPlan from "components/meeting-plan/MeetingPlan";
import { MeetingPlan as MeetingPlanType, MeetingTemplate } from "components/meetings/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  addMeetingTemplate,
  deleteMeetingTemplate,
  getMeetingTemplates,
  updateMeetingTemplate,
} from "reducers/meeting-template/meetingTemplateReducer";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface GroupedTemplates {
  [type: string]: MeetingTemplate[];
}

export type MeetingTemplateTypes = "customer_success" | "sales" | "internal";

export const MeetingTemplateTypeMap = {
  customer_success: "Customer Success",
  sales: "sales",
  internal: "Internal",
};

const Templates = () => {
  const dispatch = useAppDispatch();

  const meetingTemplateState = useAppSelector((state) => state.meetingTemplate);

  const [selected, setSelected] = useState<number | null>(null);
  const [templateGroups, setTemplateGroups] = useState<GroupedTemplates>({});
  const [initialRender, setInitialRender] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTemplateType, setNewTemplateType] = useState(Object.keys(MeetingTemplateTypeMap)[0]);
  const [newMeetingPlan, setNewMeetingPlan] = useState<MeetingPlanType | null>(null);
  const [newTemplateModalType, setNewTemplateModalType] = useState("save_as");
  const [editMode, setEditMode] = useState(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deletingTemplate, setDeletingTemplate] = useState<MeetingTemplate | null>(null);

  function groupMeetingTemplatesByType() {
    return meetingTemplateState.meetingTemplates.reduce((acc: GroupedTemplates, mt) => {
      const type = MeetingTemplateTypeMap[mt.meta.type as MeetingTemplateTypes];
      if (type) {
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(mt);
      }
      return acc;
    }, {});
  }

  useEffect(() => {
    dispatch(getMeetingTemplates());
  }, []);

  useEffect(() => {
    if (meetingTemplateState.status.update === "fulfilled") {
      dispatch(getMeetingTemplates());

      if (meetingTemplateState.currMeetingTemplate?.id) {
        setSelected(meetingTemplateState.currMeetingTemplate.id);
      }
    }
  }, [meetingTemplateState.status.update]);

  useEffect(() => {
    if (meetingTemplateState.status.add === "fulfilled") {
      dispatch(getMeetingTemplates());
      if (meetingTemplateState.currMeetingTemplate?.id) {
        setSelected(meetingTemplateState.currMeetingTemplate.id);
      }
    }
  }, [meetingTemplateState.status.add]);

  useEffect(() => {
    if (meetingTemplateState.status.delete === "fulfilled") {
      dispatch(getMeetingTemplates());
      if (meetingTemplateState.currMeetingTemplate?.id) {
        setSelected(meetingTemplateState.currMeetingTemplate.id);
      }
    }
  }, [meetingTemplateState.status.delete]);

  useEffect(() => {
    const groups = groupMeetingTemplatesByType();
    setTemplateGroups(groups);
    if (initialRender && Object.keys(groups).length > 0) {
      setInitialRender(false);
      const firstType = Object.keys(groups)[0];
      if (firstType && groups[firstType].length > 0) {
        setSelected(groups[firstType][0].id);
      }
    }
  }, [meetingTemplateState.meetingTemplates]);

  const renderMeetingTemplate = () => {
    const mt = meetingTemplateState.meetingTemplates.find((mt) => mt.id === selected);
    if (mt) {
      return (
        <Box
          bg={"white"}
          p={"14px"}
          m={"14px"}
          h={"calc(100vh - 4rem)"}
          overflow={"auto"}
          borderRadius={8}
          border={"1px"}
          borderColor={"gray.100"}
        >
          <MeetingPlan
            key={mt.id + String(editMode)}
            plan={mt}
            meetingId={undefined}
            meetingDuration={undefined}
            isTemplate={true}
            editMode={editMode}
            onEditTemplate={(bool) => {
              if (bool === true) {
                setEditMode(true);
              } else {
                setIsDiscardModalOpen(true);
              }
            }}
            onSaveTemplate={(asNew, mp) => {
              if (asNew) {
                setNewMeetingPlan(mp);
                setNewTemplateModalType("save_as");
                setTimeout(() => {
                  setIsAddModalOpen(true);
                }, 10);
              } else {
                dispatch(updateMeetingTemplate({ id: mt.id, ...mp }));
                setEditMode(false);
              }
            }}
            viewOnly={!editMode}
          />
        </Box>
      );
    }
  };

  return (
    <>
      <Flex p={4} flex={1}>
        <Flex
          className="modal-menu"
          pr={4}
          flex={2}
          flexDir={"column"}
          whiteSpace={"nowrap"}
          height={"calc(100vh - 2rem)"}
        >
          <Badge
            icon={MeetingPlanIcon}
            border={0}
            fontWeight={500}
            color="gray.800"
            iconColor="gray.800"
            fontSize={"lg"}
            sx={{ "& svg": { fontSize: "lg" } }}
            p={0}
            mb={2}
          >
            <Text>Templates</Text>
          </Badge>
          <Text color={"gray.500"} fontWeight={500} fontSize={"sm"}>
            We have created templates especially for you.
          </Text>
          <Text color={"gray.500"} fontWeight={500} fontSize={"sm"}>
            Click on the template to speed up your work
          </Text>
          <Box flex={1} mt={4} overflow={"auto"} mb={2} sx={{ "&::-webkit-scrollbar": { scrollbarWidth: "none" } }}>
            {Object.entries(templateGroups).map(([type, templates], index) => (
              <Box key={type} mb={4}>
                <Text color={"gray.500"} fontWeight={500} fontSize={"xs"} mb={2}>
                  {type}
                </Text>
                {templates.map((mt) => (
                  <HStack
                    key={mt.id}
                    className="meeting-plan-section-header"
                    _hover={{
                      "& .templates-close": { opacity: 1, visibility: "visible" },
                      ...(mt.id !== selected && { bg: "gray.50" }),
                    }}
                    mb={2}
                    bg={mt.id === selected ? "gray.100" : ""}
                    borderRadius={8}
                    py={2}
                    px={3}
                    cursor={"pointer"}
                  >
                    <Text
                      key={mt.id}
                      color={"gray.800"}
                      fontWeight={500}
                      fontSize={"sm"}
                      onClick={() => {
                        setSelected(mt.id);
                      }}
                      flex={1}
                    >
                      {mt.name}
                    </Text>
                    <IconButton
                      className="templates-close"
                      as={"div"}
                      color={"gray.500"}
                      icon={CloseIcon}
                      onClick={() => {
                        setDeletingTemplate(mt);
                        setIsDeleteModalOpen(true);
                      }}
                      opacity={0}
                      visibility="hidden"
                      dark
                    />
                  </HStack>
                ))}
              </Box>
            ))}
          </Box>
          <Button
            colorScheme="echoBlue"
            py={5}
            mb={6}
            onClick={() => {
              setNewTemplateModalType("create_new");
              setTimeout(() => {
                setIsAddModalOpen(true);
              }, 10);
            }}
          >
            Create a new template
          </Button>
        </Flex>
        <Box className="template" bg={"gray.50"} borderRadius={8} flex={5}>
          {renderMeetingTemplate()}
        </Box>
      </Flex>
      <Modal isOpen={isAddModalOpen} onClose={() => setIsAddModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>{newTemplateModalType === "save_as" ? "Save" : "Create a"} template</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Input
              autoFocus
              id="name"
              name="name"
              placeholder="Please enter template name"
              onChange={(e) => setNewTemplateName(e.target.value)}
              value={newTemplateName}
              fontSize={"sm"}
              size={"sm"}
              color={"gray.800"}
              borderRadius={4}
              outline={"1px"}
              mb={4}
              _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
            />
            <Select
              fontSize={"sm"}
              size={"sm"}
              color={"gray.800"}
              borderRadius={4}
              outline={"1px"}
              minW={"fit-content"}
              mb={4}
              _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
              value={newTemplateType}
              onChange={(e) => {
                setNewTemplateType(e.target.value);
              }}
            >
              {Object.entries(MeetingTemplateTypeMap).map(([key, value]) => {
                return (
                  <option value={key} key={key}>
                    {value}
                  </option>
                );
              })}
            </Select>
          </ModalBody>
          <ModalFooter>
            <Button color={"gray.500"} mr={4} variant={"outline"} onClick={() => setIsAddModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="echoBlue"
              onClick={() => {
                if (newTemplateModalType === "save_as") {
                  if (newMeetingPlan) {
                    dispatch(
                      addMeetingTemplate({
                        ...newMeetingPlan,
                        name: newTemplateName,
                        meta: { type: newTemplateType, checked: [] },
                      })
                    );
                  }
                } else {
                  dispatch(
                    addMeetingTemplate({
                      sections: [],
                      description: "",
                      name: newTemplateName,
                      meta: { type: newTemplateType, checked: [] },
                    })
                  );
                }
                setIsAddModalOpen(false);
                setEditMode(false);
                setNewTemplateName("");
              }}
            >
              {newTemplateModalType === "save_as" ? "Save" : "Create"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDiscardModalOpen} onClose={() => setIsDiscardModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Discard changes</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>Your changes will not be saved</ModalBody>
          <ModalFooter>
            <Button color={"gray.500"} mr={4} variant={"outline"} onClick={() => setIsDiscardModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setEditMode(false);
                setIsDiscardModalOpen(false);
              }}
            >
              Discard changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Delete template</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>Are you sure you want to delete {deletingTemplate?.name} template?</ModalBody>
          <ModalFooter>
            <Button
              color={"gray.500"}
              mr={4}
              variant={"outline"}
              onClick={() => {
                setDeletingTemplate(null);
                setIsDeleteModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                dispatch(updateMeetingTemplate({ id: deletingTemplate?.id, archived: true }));
                setInitialRender(true);
                setIsDeleteModalOpen(false);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Templates;
