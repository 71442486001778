import { Box } from "@chakra-ui/react";
import { DndContext, DragEndEvent, UniqueIdentifier, closestCorners } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { FormikConfig, FormikErrors, FormikProps, FormikValues } from "formik";
import { SelectOption, StatusOption } from "../Meta";
import { useAppDispatch } from "hooks/reduxHooks";
import { Table } from "@tanstack/react-table";
import { Contact } from "components/contacts/types";
import { updateContactTableColumn } from "reducers/contact-table/contactTableReducer";
import Option from "../Option";
import { shallowCopy } from "utils/common";
import DeleteOptionModal from "../DeleteOptionModal";
import { useState } from "react";

interface DrawerOptionsProps {
  options: any[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          name: string;
          type: string;
          options: SelectOption[] | StatusOption[];
        }>
      >;
}

const DrawerOptions: React.FC<DrawerOptionsProps> = ({ options, setFieldValue }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [optionForDelete, setOptionForDelete] = useState();

  const getOptionPos = (id: UniqueIdentifier) => {
    return options.findIndex((option) => option.id === id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id === over.id) return;

    const originalPos = getOptionPos(active.id);
    const newPos = getOptionPos(over.id);

    const newOptions = arrayMove(options, originalPos, newPos);

    setFieldValue("options", newOptions);
  };

  const handleOptionChange = (newOption: SelectOption | StatusOption) => {
    const newOptions = options.map((option) => {
      if (option.id === newOption.id) return newOption;
      return option;
    });
    setFieldValue("options", newOptions);
  };

  const handleOptionDelete = (oldOption: SelectOption | StatusOption) => {
    const newOptions = options.filter((option) => option.id !== oldOption.id);
    setFieldValue("options", newOptions);
  };

  return (
    <>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
        <SortableContext items={options} strategy={verticalListSortingStrategy}>
          {options?.map((option: any) => (
            <Option
              onDelete={() => {
                setOptionForDelete(option);

                setIsDeleteModalOpen(true);
              }}
              option={option}
              key={option.id}
              onChange={(newOption) => {
                handleOptionChange(newOption);
              }}
            />
          ))}
        </SortableContext>
      </DndContext>
      <DeleteOptionModal
        isOpen={isDeleteModalOpen}
        onClose={(shouldDelete) => {
          if (shouldDelete) {
            handleOptionDelete(optionForDelete!);
          }
          setIsDeleteModalOpen(false);
        }}
        option={optionForDelete}
      />
    </>
  );
};

export default DrawerOptions;
