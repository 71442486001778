import { Button, ButtonProps } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import Icon from "./Icon";

interface IconButtonProps extends ButtonProps {
  icon: any;
  iconSize?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  iconClass?: string;
  dark?: boolean;
}
const IconButton: React.FC<IconButtonProps> = ({ icon, iconSize, onClick, iconClass, dark, mt, ...rest }) => {
  return (
    <Button
      h={"unset"}
      minW={"unset"}
      _hover={{ bg: dark ? "gray.200" : "gray.100" }}
      bg={"transparent"}
      borderRadius="full"
      aspectRatio={1}
      p={1}
      onClick={onClick}
      {...rest}
    >
      <Icon
        as={icon}
        fontSize={iconSize ? iconSize : "md"}
        color={rest.color ? rest.color : "gray.600"}
        className={iconClass}
        {...(mt !== undefined ? { mt: mt } : {})}
      />
      {rest.children}
    </Button>
  );
};

export default IconButton;
