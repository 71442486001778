import React, { useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { updateUserInfo } from "reducers/user/userReducer";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { parseServerError } from "utils/validate";
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import IconButton from "components/base/IconButton";
import { ReactComponent as EyeOnIcon } from "assets/icons/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";

interface ChangePasswordProps extends Omit<ModalProps, "children"> {}

interface Values {
  password: string;
  confirm: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ ...rest }) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");

  const [passwordVis, setPasswordVis] = useState(false);
  const [confirmVis, setConfirmVis] = useState(false);

  const initialValues = {
    password: "",
    confirm: "",
  };

  const schema = yup.object().shape({
    password: yup.string().required("Password is required"),
    confirm: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const renderErrorMessage = (formik: any) => {
    let message = "";
    if (formik.touched.password && formik.errors.password) {
      message = formik.errors.password;
    } else if (formik.touched.confirm && formik.errors.confirm) {
      message = formik.errors.confirm;
    } else if (errMsg) {
      message = parseServerError(errMsg);
    }
    return (
      <Text alignSelf={"start"} ml={2} mb={2} fontSize={"xs"} color={"red.500"} fontWeight={500}>
        {message}
      </Text>
    );
  };

  const handleChangePassword = (values: Values) => {
    const { password } = values;

    dispatch(updateUserInfo({ password, meta: { ...userState.user?.meta, onboardingStage: "connect_calendar" } }));
  };

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent maxW={"25rem"}>
        <ModalHeader>New password</ModalHeader>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleChangePassword}>
          {(formik) => (
            <>
              <ModalBody>
                <form id="login-form" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack gap={2} justifyContent={"center"}>
                    <InputGroup>
                      <Input
                        autoFocus
                        autoComplete="off"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        fontSize={"sm"}
                        size={"sm"}
                        color={"gray.800"}
                        borderRadius={4}
                        outline={"1px"}
                        _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                        type={passwordVis ? "text" : "password"}
                      />
                      <InputRightElement p={0} h={8} w={12}>
                        <IconButton
                          icon={passwordVis ? EyeOffIcon : EyeOnIcon}
                          onClick={() => setPasswordVis((prev) => !prev)}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        id="confirm"
                        name="confirm"
                        placeholder="Confirm"
                        onChange={formik.handleChange}
                        value={formik.values.confirm}
                        fontSize={"sm"}
                        size={"sm"}
                        color={"gray.800"}
                        borderRadius={4}
                        outline={"1px"}
                        _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                        type={confirmVis ? "text" : "password"}
                      />
                      <InputRightElement p={0} h={8} w={12}>
                        <IconButton
                          icon={confirmVis ? EyeOffIcon : EyeOnIcon}
                          onClick={() => setConfirmVis((prev) => !prev)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Stack>
                </form>
              </ModalBody>
              <ModalFooter flexDir={"column"}>
                {renderErrorMessage(formik)}
                <Button colorScheme="echoBlue" form="login-form" type="submit" w={"80%"}>
                  Update
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default ChangePassword;
