import { CellContext } from "@tanstack/react-table";
import React, { ReactNode, forwardRef, useEffect, useRef, useState } from "react";
import { Contact, ContactStamp } from "../types";
import DatePicker, { ReactDatePicker, ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerCustomInput from "../../base/DatePickerCustomInput";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContact } from "reducers/contact/contactReducer";

const CustomInput = forwardRef((props: any, ref) => {
  return <DatePickerCustomInput {...props} ref={ref} />;
});

const DateCell: React.FC<CellContext<Contact, string>> = ({ getValue, row, column, table }) => {
  const dispatch = useAppDispatch();
  const dateString = getValue();
  const initialValue = dateString ? new Date(dateString) : null;

  const dateRef = useRef<HTMLDivElement>(null);

  const [date, setDate] = useState<Date | null>(initialValue);

  const handleDateChange = (date: Date | null) => {
    setDate(date);
    table.options.meta?.updateData(row.index, column.id, date);
    dispatch(updateContact({ id: row.original.id, columns: { [column.id]: date } }));
  };

  return (
    <div ref={dateRef} className="date-cell">
      <DatePicker
        wrapperClassName="date-cell__wrapper"
        selected={date}
        onChange={handleDateChange}
        dateFormat="MMMM d, yyyy"
        customInput={<DatePickerCustomInput wrapperRef={dateRef.current} clearDate={() => handleDateChange(null)} />}
      />
    </div>
  );
};

export default DateCell;
