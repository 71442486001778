import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  FilterFnOption,
  SortingState,
  TableOptions,
} from "@tanstack/react-table";
import { BaseState } from "../baseTypes";
import { Contact } from "components/contacts/types";
import { Nullable } from "types/types";

export interface ContactTableMeta {
  sortState: SortingState;
  filterState: ColumnFiltersState;
  columnOrder: string[];
  primaryColumnsHiddenState: string[];
}

export interface BackendContactTable {
  columns: BackendColumnDef[];
  meta: ContactTableMeta;
}

export interface ContactTableState extends BaseState {
  contactTable: BackendContactTable;
  currentColumn: Nullable<BackendColumnDef>;
  sortState: SortingState;
  filterState: ColumnFiltersState;
}

export interface Filter {
  identifier: string;
  colName: string;
  colType: ColumnType;
  value: string | string[];
}

// export interface FilterState {
//   filters: Filter[];
// }

export type ColumnType =
  | "text"
  | "number"
  | "status"
  | "select"
  | "multi-select"
  | "person"
  | "multi-person"
  | "date"
  | "url";

export interface BaseCellType {
  value: string;
}

export type TextCellType = string;
export type NumberCellType = string;
export type UrlCellType = string;

export interface StatusCellType extends BaseCellType {}
export interface SelectCellType extends BaseCellType {}
export interface PersonCellType extends BaseCellType {}
export interface MultiSelectCellType {
  value: string[];
}
export interface MultiPersonCellType extends MultiSelectCellType {}
export interface DateCellType {
  value: Date;
}

export type ComboCellType = SelectCellType | MultiSelectCellType | PersonCellType | MultiPersonCellType;

export type CellTypeWithOptions = "status" | "select" | "multi-select";
export const CellTypesWithOptions = ["status", "select", "multi-select"];
export const SortableCellTypes = ["text", "number", "status", "select", "date", "url"];

export interface BackendColumnDef {
  type: ColumnType;
  accessor_key: string;
  header: string;
  options: { values: any[] };
  archived: boolean;
  order: number;
  hidden: boolean;
}

export interface ExtendedTableOptions {
  data: Contact[];
  columns: BackendColumnDef[];
}
