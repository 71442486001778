import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="not-found" style={{ margin: 10, fontSize: 20, textAlign: "center" }}>
      <h1>404 Page not found</h1>
      <div style={{ margin: 10 }}>
        <Link to="/">Go to home</Link>
      </div>
    </div>
  );
};

export default NotFound;
