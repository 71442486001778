import { Box, HStack, Popover, PopoverContent, PopoverTrigger, useDisclosure } from "@chakra-ui/react";
import Badge from "components/base/Badge";
import { SelectOption, StatusOption } from "./Meta";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ReactComponent as ReOrderDotsVerticalIcon } from "assets/icons/re-order-dots-vertical.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import IconButton from "components/base/IconButton";
import OptionMenu from "./OptionMenu";
import { useState } from "react";
import { AvailableColors } from "types/types";
import DeleteOptionModal from "./DeleteOptionModal";
import { shallowCopy } from "utils/common";
import Icon from "components/base/Icon";

interface OptionProps {
  option: SelectOption | StatusOption;
  onChange: (newOption: SelectOption | StatusOption) => void;
  onDelete: () => void;
}

const Option: React.FC<OptionProps> = ({ option, onChange, onDelete }) => {
  const id = option.id;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const { isOpen, onToggle, onClose } = useDisclosure();

  const [color, setColor] = useState(option.color);
  const [value, setValue] = useState(option.value);

  return (
    <HStack mb={1}>
      <HStack
        flex={1}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        key={id}
        transition={transition}
        transform={CSS.Transform.toString(transform)}
      >
        <Icon as={ReOrderDotsVerticalIcon} fontSize={"sm"} />
        <Badge bgColor={`${color}.50`} borderColor={`${color}.100`} color={`${color}.500`} fontSize={"sm"}>
          {value}
        </Badge>
      </HStack>
      <Popover gutter={4} placement="bottom-start" onClose={onClose} isOpen={isOpen}>
        <PopoverTrigger>
          <Box>
            <IconButton icon={ChevronRightIcon} fontSize={"sm"} onClick={onToggle} />
          </Box>
        </PopoverTrigger>
        <PopoverContent w={"fit-content"}>
          <OptionMenu
            options={AvailableColors}
            name={value}
            onNameChange={(e) => {
              setValue(e.target.value);
            }}
            onNameBlur={(e) => {
              let newOption = shallowCopy(option);
              newOption.value = e.target.value;
              onChange(newOption);
            }}
            onDelete={onDelete}
            selected={color}
            onOptionSelect={(newColor) => {
              setColor(newColor);
              let newOption = shallowCopy(option);
              newOption.color = newColor;
              onChange(newOption);
              onToggle();
            }}
          />
        </PopoverContent>
      </Popover>
    </HStack>
  );
};

export default Option;
