import {
  Box,
  BoxProps,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import EnterInput from "./EnterInput";
import Tag from "./Tag";
import { ColumnType } from "reducers/contact-table/contactTableTypes";
import ComboItemOptions from "./ComboItemOptions";
import Badge from "./Badge";
import { useAppDispatch } from "hooks/reduxHooks";
import { deleteTag, updateTag } from "reducers/tags/tagsReducer";

interface ComboPopoverProps extends BoxProps {
  options: any[];
  selected: any[];
  onItemClick: (value: any) => void;
  type: ColumnType;
  onRemove?: (id: number) => void;
  onAdd?: (name: string) => void;
}

const ComboPopover: React.FC<ComboPopoverProps> = ({
  children,
  options,
  selected,
  onItemClick,
  type,
  onRemove,
  onAdd,
}) => {
  const { onToggle, isOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  const popContentRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!popContentRef.current?.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen && popContentRef.current) {
      const enterInput = popContentRef.current.querySelector(".enter-input") as HTMLInputElement;
      setTimeout(() => {
        enterInput?.focus();
      }, 10);
    }
  }, [isOpen, popContentRef.current]);

  const renderSelectedItems = (): ReactNode => {
    if (!onRemove) return <></>;
    return (
      <HStack gap={1}>
        {options?.map((option) => {
          const tagSelected = selected && selected.some((sel) => sel.value === option.id);
          if (tagSelected) {
            return (
              <HStack
                key={option.value}
                justify={"space-between"}
                mr={0.5}
                _hover={{ bgColor: "gray.50" }}
                borderRadius={6}
                p={0.5}
                gap={0.5}
              >
                <Tag
                  key={option.id}
                  label={option.value}
                  onClick={() => onRemove && onRemove(option.id)}
                  onClose={() => onRemove && onRemove(option.id)}
                  showClose
                  {...(option.type === "select" || option.type === "multi-select" ? { color: option.color } : {})}
                  {...(option.type === "person" || option.type === "multi-person" ? { avatar: option.avatar } : {})}
                />
                {option.type === "select" ||
                  (option.type === "multi-select" && (
                    <ComboItemOptions
                      item={option}
                      onDelete={() => {
                        dispatch(deleteTag(option.id));
                      }}
                      onNameChange={(name) => {
                        dispatch(updateTag({ id: option.id, value: name }));
                      }}
                    />
                  ))}
              </HStack>
            );
          }
        })}
      </HStack>
    );
  };

  const renderMenuItems = (): ReactNode => {
    return (
      <Stack gap={0} maxH={60} overflowY={"auto"}>
        {options?.map((option) => {
          const tagSelected = selected && selected.some((sel) => sel.value === option.id);
          if (option.value.toLocaleLowerCase().startsWith(searchValue.toLocaleLowerCase())) {
            if (!tagSelected) {
              return (
                <HStack
                  key={option.value}
                  justify={"space-between"}
                  mr={0.5}
                  _hover={{ bgColor: "gray.50" }}
                  borderRadius={6}
                  p={0.5}
                  cursor={"pointer"}
                  onClick={(e) => {
                    const optionsClicked = (e.target as HTMLElement).classList.contains("icon-button");
                    const optionsPopper = popContentRef.current?.querySelector(
                      `.combo-item-options__popover-content--${option.id}`
                    );
                    if (
                      (!optionsClicked && !optionsPopper?.contains(e.target as HTMLElement)) ||
                      ["person", "multi-person"].includes(type)
                    ) {
                      const close =
                        ["select", "person"].includes(type) || (selected && selected.length === options.length - 1);
                      onItemClick(option);
                      if (close) onClose();
                    }
                  }}
                >
                  <Tag
                    className="menu-item"
                    label={option.value}
                    onClick={() => {}}
                    {...(option.type === "select" || option.type === "multi-select" ? { color: option.color } : {})}
                    {...(option.type === "person" || option.type === "multi-person" ? { avatar: option.avatar } : {})}
                  />

                  {onAdd &&
                    (option.type === "select" ||
                      (option.type === "multi-select" && (
                        <ComboItemOptions
                          item={option}
                          onDelete={() => {
                            dispatch(deleteTag(option.id));
                          }}
                          onNameChange={(name) => {
                            dispatch(updateTag({ id: option.id, value: name }));
                          }}
                        />
                      )))}
                </HStack>
              );
            }
          }
        })}
      </Stack>
    );
  };

  return (
    <Popover closeOnBlur={false} gutter={0} placement="bottom-start" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box onClick={onToggle}>{children}</Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent ref={popContentRef} w={"fit-content"}>
          <PopoverBody>
            <Stack gap={1}>
              <EnterInput
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onBlur={(e, isEnterKey) => {
                  if (isEnterKey) {
                    (popContentRef.current?.querySelector(".menu-item") as HTMLDivElement)?.click();
                    onClose();
                    setTimeout(() => {
                      setSearchValue("");
                    }, 100);
                  }
                }}
              />
              {renderSelectedItems()}
              <Text fontSize={"xs"} color={"gray.600"}>
                Select an option{onAdd ? " or create one" : ""}
              </Text>
              {renderMenuItems()}
              {searchValue.length > 0 && onAdd && (
                <HStack
                  cursor={"pointer"}
                  _hover={{ bgColor: "gray.50" }}
                  borderRadius={6}
                  p={0.5}
                  onClick={() => {
                    onAdd(searchValue);
                    onClose();
                  }}
                  className="menu-item"
                >
                  <Text color={"gray.700"} fontSize={"xs"}>
                    Create
                  </Text>
                  <Badge>{searchValue}</Badge>
                </HStack>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ComboPopover;
