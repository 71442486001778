import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  SystemStyleObject,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import IconButton from "components/base/IconButton";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { Contact } from "../contacts/types";
import { getContacts, setCurrContact } from "reducers/contact/contactReducer";
import ContactMeetings from "./ContactMeetings";
import { ReactComponent as CallIcon } from "assets/icons/call.svg";
import { ReactComponent as NoteIcon } from "assets/icons/note.svg";
import { ReactComponent as CommentIcon } from "assets/icons/comment.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as TaskListIcon } from "assets/icons/task-list.svg";
import ContactTasks from "./ContactTasks";
import ContactNotes from "./ContactNotes";
import ContactDetails from "./ContactDetails";
import ContactComments from "./ContactComments";
import TabHeader from "components/base/TabHeader";
import Avatar from "components/base/Avatar";
import { addContactActionItem } from "reducers/action-item/actionItemReducer";
import { getContactMeetings } from "reducers/meeting/meetingReducer";
import { getMeetingTemplates } from "reducers/meeting-template/meetingTemplateReducer";
import { getTags } from "reducers/tags/tagsReducer";
import Icon from "components/base/Icon";

export const tabSelectedStyle: SystemStyleObject = {
  borderBottom: "1px",
  borderColor: "echoBlue.500",
  color: "gray.800",
  "& path": { fill: "echoBlue.500" },
};

interface ContactPageProps {}

const ContactPage: React.FC<ContactPageProps> = () => {
  const dispatch = useAppDispatch();
  const contactState = useAppSelector((state) => state.contact);

  const { contactId } = useParams();

  const [contact, setContact] = useState<Contact | undefined>();
  const [activeMainTabIndex, setActiveMainTabIndex] = useState(0);
  const [activeDetailsTabIndex, setActiveDetailsTabIndex] = useState(0);
  const [cache, setCache] = useState<{ [key: string]: boolean }>({});
  const [searchTerms, setSearchTerms] = useState({ meetings: "", tasks: "", notes: null });

  const MainTabs: { identifier: "meetings" | "tasks" | "notes"; [key: string]: any }[] = [
    {
      identifier: "meetings",
      name: "Meetings",
      icon: CallIcon,
      panel: (
        <ContactMeetings
          cache={cache}
          setCache={setCache}
          contactId={Number(contactId)}
          searchTerm={searchTerms["meetings"]}
        />
      ),
    },
    {
      identifier: "tasks",
      name: "Tasks",
      icon: TaskListIcon,
      panel: (
        <ContactTasks
          contactId={Number(contactId)}
          cache={cache}
          setCache={setCache}
          searchTerm={searchTerms["tasks"]}
        />
      ),
      onAdd: () => {
        dispatch(
          addContactActionItem({
            name: "",
            description: "",
            created_on_meeting: null,
            references: [{ id: Number(contactId), type: "contact" }],
          })
        );
      },
    },
    { identifier: "notes", name: "Notes", icon: NoteIcon, panel: <ContactNotes contact={contact} /> },
  ];

  const DetailsTabs = [
    { identifier: "details", name: "Details", icon: PersonIcon, panel: <ContactDetails contact={contact} /> },
    // { identifier: "comments", name: "Comments", icon: CommentIcon, panel: <ContactComments /> },
  ];

  useEffect(() => {
    let currContact = contactState.contacts?.find((c) => c.id === Number(contactId));
    if (currContact) {
      setContact(currContact);
    } else dispatch(getContacts());
  }, [contactState.contacts]);

  useEffect(() => {
    if (contactId) {
      dispatch(getContactMeetings(Number(contactId)));
      dispatch(getMeetingTemplates());
      dispatch(getTags());
    }
  }, [contactId]);

  return (
    <Flex w="100%" direction={"column"}>
      <Box className="header" p={4}>
        <HStack gap={3} alignItems={"center"}>
          <Link to="/people">
            <IconButton icon={ArrowLeftIcon} color={"gray.500"} />
          </Link>
          <Breadcrumb sx={{ "& ol": { mb: 0, pl: 0 } }} fontWeight={500} color={"gray.500"} fontSize={"sm"}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="/people">
                People
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage color={"gray.800"}>
              <BreadcrumbLink>
                <Center gap={2}>
                  <Avatar name={contact?.name} size={"xs"} />
                  <Text>{contact?.name}</Text>
                </Center>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </HStack>
      </Box>
      <Divider my={0} />
      <HStack gap={0} flex={1} alignItems={"stretch"}>
        <Box minW="75%">
          <Tabs
            index={activeMainTabIndex}
            variant="unstyled"
            isLazy={true}
            color={"gray.400"}
            onChange={(index) => setActiveMainTabIndex(index)}
          >
            <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
              {MainTabs.map((tab) => {
                return (
                  <Tab _selected={tabSelectedStyle} py={3} fontSize={"sm"} key={tab.identifier} borderRadius={0}>
                    <Icon as={tab.icon} mr={1} />
                    {tab.name}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels>
              {MainTabs.map((tab, index) => {
                return (
                  <Box key={tab.identifier}>
                    {(tab.onAdd || searchTerms[tab.identifier] !== null) && (
                      <TabHeader
                        tab={tab.identifier}
                        show={activeMainTabIndex === index}
                        {...(tab.onAdd ? { onAdd: tab.onAdd } : {})}
                        searchTerm={searchTerms[tab.identifier]}
                        setSearchTerm={(newValue) =>
                          setSearchTerms((prev) => {
                            return { ...prev, [tab.identifier]: newValue };
                          })
                        }
                      />
                    )}
                    <TabPanel className={tab.identifier} p={0}>
                      {tab.panel}
                    </TabPanel>
                  </Box>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
        <Box borderLeft={"1px"} borderColor={"gray.100"} maxW={"25%"}>
          <Tabs
            index={activeDetailsTabIndex}
            variant="unstyled"
            isLazy={true}
            color={"gray.400"}
            onChange={(index) => setActiveDetailsTabIndex(index)}
            display={"flex"}
            flexDir={"column"}
          >
            <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
              {DetailsTabs.map((tab) => {
                return (
                  <Tab _selected={tabSelectedStyle} py={3} fontSize={"sm"} key={tab.identifier} borderRadius={0}>
                    <Icon as={tab.icon} mr={1} />
                    {tab.name}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels flex={1} overflowX={"auto"}>
              {DetailsTabs.map((tab) => {
                return (
                  <TabPanel key={tab.identifier} p={0}>
                    {tab.panel}
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </HStack>
    </Flex>
  );
};

export default ContactPage;
