import { Box, BoxProps } from "@chakra-ui/react";
import { Meeting } from "./types";
import Meetings from "./Meetings";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getMeetings } from "reducers/meeting/meetingReducer";
import { hasCommonElement, hasCommonElementInObjects } from "utils/common";
import { BackendMeeting } from "reducers/meeting/meetingTypes";

interface MeetingHistoryProps extends BoxProps {
  meeting: Meeting;
  cache: { [key: string]: boolean };
  setCache: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  searchTerm: string | null;
}

const MeetingHistory: React.FC<MeetingHistoryProps> = ({ cache, setCache, meeting, searchTerm }) => {
  const dispatch = useAppDispatch();

  const meetingState = useAppSelector((state) => state.meeting);

  useEffect(() => {
    setMeetings(filterMeetings());
  }, [meetingState.meetings, meeting, searchTerm]);

  const filterMeetings = () => {
    return meetingState.meetings.filter((curr) => {
      return (
        (hasCommonElement(curr.participants.contacts, meeting.participants.contacts) ||
          hasCommonElement(curr.participants.outsiders, meeting.participants.outsiders, "email")) &&
        curr.id !== meeting.id &&
        (!searchTerm || (searchTerm && curr.name.toLowerCase().includes(searchTerm.toLowerCase())))
      );
    }) as BackendMeeting[];
  };
  const [meetings, setMeetings] = useState(filterMeetings());

  return <Meetings type="past" meetings={meetings} />;
};

export default MeetingHistory;
