import { Box, BoxProps, Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure } from "@chakra-ui/react";
import IconButton from "./IconButton";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import OptionMenu from "components/contacts/table/OptionMenu";
import { useRef, useState } from "react";
import { AvailableColors } from "types/types";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateTag } from "reducers/tags/tagsReducer";

interface ComboItemOptionsProps extends BoxProps {
  item: { value: string; id: number; [key: string]: any };
  onDelete?: () => void;
  onNameChange?: (name: string) => void;
}

const ComboItemOptions: React.FC<ComboItemOptionsProps> = ({ item, onDelete, onNameChange }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const dispatch = useAppDispatch();

  const [value, setValue] = useState(item.value);

  return (
    <Popover gutter={4} placement="bottom-end" onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Box>
          <IconButton icon={ChevronRightIcon} fontSize={"sm"} onClick={onToggle} iconClass="icon-button" />
        </Box>
      </PopoverTrigger>
      <PopoverContent w={"fit-content"} className={`combo-item-options__popover-content--${item.id}`}>
        <PopoverBody>
          <OptionMenu
            name={value}
            onDelete={() => {
              if (onDelete) {
                onDelete();
              }
              onClose();
            }}
            onNameChange={(e) => {
              setValue(e.target.value);
            }}
            onNameBlur={(e) => {
              if (onNameChange) {
                onNameChange(e.target.value);
              }
            }}
            selected={item.meta?.color}
            options={AvailableColors}
            onOptionSelect={(color) => {
              dispatch(updateTag({ id: item.id, meta: { ...item.meta, color } }));
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ComboItemOptions;
