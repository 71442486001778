import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import InvisibleInput from "components/base/InvisibleInput";
import { formatMinutes } from "utils/common";
import DynamicInput from "components/base/DynamicInput";
import Icon from "components/base/Icon";

interface EstimatedTimeProps extends BoxProps {
  initialValue?: number | "";
  onValueChange: (value: number | "") => void;
  onValueBlur?: (value: number) => void;
}

const EstimatedTime: React.FC<EstimatedTimeProps> = ({ initialValue, onValueChange, onValueBlur }) => {
  const [value, setValue] = useState<number | undefined | "">(initialValue);
  const [enableInput, setEnableInput] = useState(value === "");
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRendered(true);
    }, 1);
  }, []);

  return (
    <Box className="estimated-time">
      {!enableInput && value ? (
        <>
          <HStack gap={1} onClick={() => setEnableInput(true)}>
            <Icon as={ClockIcon} fontSize={"sm"} />
            <Text color={"gray.500"} fontSize={"sm"}>
              {formatMinutes(value)}
            </Text>
          </HStack>
        </>
      ) : (
        <HStack gap={1}>
          <Icon as={AddIcon} fontSize={"sm"} />
          <DynamicInput
            value={value}
            onChange={(e) => {
              const newValue = e.target.value.length === 0 ? "" : Number(e.target.value);
              setValue(newValue);
              onValueChange(newValue);
            }}
            onBlur={(e) => {
              setEnableInput(e.target.value.length === 0);
              // setRendered(e.target.value.length > 0);
              onValueBlur && onValueBlur(Number(e.target.value));
            }}
            type="number"
            placeholder="Estimated"
            color={"gray.500"}
            autoFocus={rendered}
            fontSize={"sm"}
          />
        </HStack>
      )}
    </Box>
  );
};

export default EstimatedTime;
