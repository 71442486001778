import { AvatarProps, Box, BoxProps, Avatar as ChakraAvatar } from "@chakra-ui/react";
import { AvailableColors } from "types/types";

function djb2HashToArray<T>(str: string | undefined, array: T[]): T {
  if (!str) {
    return array[0];
  }
  let hash: number = 5381;
  for (let i = 0; i < str.length; i++) {
    const char: number = str.charCodeAt(i);
    hash = ((hash << 5) + hash + char) ^ (char << 7);
  }
  hash ^= (hash >> 11) ^ (hash << 17);

  const index: number = Math.abs(hash % array.length);
  return array[index];
}

interface CustomAvatarProps extends AvatarProps {}

const Avatar: React.FC<CustomAvatarProps> = ({ name, ...rest }) => {
  const color = djb2HashToArray(name, AvailableColors);

  return <ChakraAvatar name={name} bg={`${color}.100`} color={`${color}.500`} fontWeight={600} {...rest} />;
};

export default Avatar;
