import { Box, Text, Divider, HStack, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ContactTable from "./table/ContactTable";
import {
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Contact } from "./types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getContactTable, updateContactTable } from "reducers/contact-table/contactTableReducer";
import { BackendColumnDef, SortableCellTypes } from "reducers/contact-table/contactTableTypes";
import { addContact, deleteContact, getContacts, resetStatus } from "reducers/contact/contactReducer";
import { generateCell, generateFilterFn, generateSortingFn } from "./table/ColumnGenerator";
import Badge from "../base/Badge";
import Sort from "./Sort";
import { DndContext, DragEndEvent, UniqueIdentifier, closestCenter } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import Filters from "./Filters";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import Icon from "components/base/Icon";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

const Contacts = () => {
  const dispatch = useAppDispatch();
  const contactTableState = useAppSelector((state) => state.contactTable);
  const contactState = useAppSelector((state) => state.contact);
  const [data, setData] = useState<Contact[]>([]);
  const [columns, setColumns] = useState<ColumnDef<Contact, any>[]>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<string[]>([]);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState<Contact[]>([]);

  useEffect(() => {
    setFilteredData(
      data.filter((contact) => contact.name.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()))
    );
  }, [data, searchTerm]);

  useEffect(() => {
    if (contactTableState.status["primary"] !== "fulfilled") {
      dispatch(getContacts());
      dispatch(getContactTable());
    }
    return () => {
      dispatch(getContacts());
      dispatch(getContactTable());
    };
  }, []);

  useEffect(() => {
    if (columnOrder.length > 0) {
      if (!isFirstTime) {
        dispatch(updateContactTable({ ...contactTableState.contactTable.meta, columnOrder }));
      } else {
        setIsFirstTime(false);
      }
    }
  }, [columnOrder]);

  useEffect(() => {
    if (contactTableState.status["primary"] === "fulfilled") {
      const convertedColumns = convertColumns(contactTableState.contactTable.columns);
      setColumns(convertedColumns);
      if (contactTableState.contactTable.meta.columnOrder?.length > 0) {
        setColumnOrder(contactTableState.contactTable.meta.columnOrder);
      } else {
        setColumnOrder(convertedColumns.map((col) => col.id!));
      }
      if (contactTableState.contactTable.meta.primaryColumnsHiddenState) {
        const hiddenColumns: any = {};
        contactTableState.contactTable.meta.primaryColumnsHiddenState.forEach((id) => (hiddenColumns[id] = false));
        setColumnVisibility((prev) => ({ ...prev, ...hiddenColumns }));
      }
    }
  }, [contactTableState.status["primary"]]);

  useEffect(() => {
    if (contactState.status["primary"] === "fulfilled") {
      setData([...contactState.contacts].sort((a, b) => a.id - b.id));
    }
  }, [contactState.status["primary"]]);

  useEffect(() => {
    if (contactState.status["add"] === "fulfilled" && contactState.currentContact) {
      setData((prev) => [...prev, contactState.currentContact!]);
      dispatch(resetStatus("add"));
      setTimeout(() => {
        const mainColumnInput: HTMLInputElement | null | undefined = document
          .getElementById(contactState.currentContact!.id.toString())
          ?.querySelector(`input.main-column`);
        if (mainColumnInput) {
          mainColumnInput.scrollIntoView();
          mainColumnInput.focus();
        }
      }, 200);
    }
  }, [contactState.status["add"]]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const contactTable = document.querySelector(".contact-table > table");
      if (contactTable && !contactTable.contains(event.target as Node)) {
        if (table.getSelectedRowModel().rows.length > 0) {
          table.resetRowSelection();
        }
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const convertColumns = (columns: BackendColumnDef[]): ColumnDef<Contact>[] => {
    const filteredSortedColumns = columns.filter((col) => !col.archived).sort((colA, colB) => colA.order - colB.order);
    const hiddenColumns: any = {};
    filteredSortedColumns.forEach((col) => {
      if (col.hidden) {
        hiddenColumns[col.accessor_key] = false;
      }
    });
    setColumnVisibility(hiddenColumns);
    const convertedColumns: ColumnDef<Contact>[] = [];
    convertedColumns.push({
      id: "name",
      accessorKey: "name",
      header: "Contact name",
      enableColumnFilter: false,
      filterFn: generateFilterFn("text"),
      cell: generateCell("text", true),
      footer: "+ New",
      meta: { type: "text" },
      sortingFn: "alphanumeric",
    } as ColumnDef<Contact>);

    convertedColumns.push({
      id: "company",
      accessorKey: "company",
      header: "Company",
      enableColumnFilter: true,
      filterFn: generateFilterFn("text"),
      cell: generateCell("text", false),
      meta: { type: "text" },
      sortingFn: "alphanumeric",
    } as ColumnDef<Contact>);

    convertedColumns.push({
      id: "email",
      accessorKey: "email",
      header: "Email",
      enableColumnFilter: true,
      filterFn: generateFilterFn("text"),
      cell: generateCell("text", false),
      meta: { type: "text" },
      sortingFn: "alphanumeric",
    } as ColumnDef<Contact>);

    convertedColumns.push(
      ...filteredSortedColumns.map((col) => {
        return {
          accessorFn: (row) => row.columns[col.accessor_key],
          id: col.accessor_key,
          header: col.header,
          enableColumnFilter: col.type !== "date",
          filterFn: generateFilterFn(col.type),
          enableSorting: SortableCellTypes.includes(col.type),
          sortingFn: generateSortingFn(col.type, col.options),
          cell: generateCell(col.type),
          meta: { type: col.type, options: col.options || { values: [] }, archived: col.archived },
        } as ColumnDef<Contact>;
      })
    );
    convertedColumns.push({
      id: "_table-options_",
      accessorKey: "_table-options_",
      header: "+",
      enableColumnFilter: false,
      cell: () => <></>,
      size: 30,
      minSize: 30,
      maxSize: 30,
      enableResizing: false,
    });
    return convertedColumns;
  };

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    enableHiding: true,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnOrderChange: setColumnOrder,
    columnResizeMode: "onChange",
    state: {
      columnFilters: columns.length > 0 ? contactTableState.filterState : [],
      rowSelection,
      columnVisibility,
      columnOrder,
      sorting: columns.length > 0 ? contactTableState.sortState : [],
      columnPinning: { left: ["name"], right: ["_table-options_"] },
    },
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any) => {
        setData((prev) => {
          return prev.map((row, index) => {
            return index === rowIndex
              ? columnId === "name"
                ? {
                    ...prev[rowIndex],
                    [columnId]: value,
                  }
                : {
                    ...prev[rowIndex],
                    columns: { ...row.columns, [columnId]: value },
                  }
              : row;
          });
        });
      },
      updateColumn: (newColumn: ColumnDef<Contact, any>, sort: boolean = false) => {
        if (sort && newColumn.meta?.type) {
          newColumn.sortingFn = generateSortingFn(newColumn.meta?.type, newColumn.meta.options);
          const col = table.getAllLeafColumns().find((col) => col.id === newColumn.id);
          const sortedDirection = col?.getIsSorted();
          if (sortedDirection) {
            col?.toggleSorting(sortedDirection === "desc");
          }
        }
        setColumns((prev) => {
          return prev.map((col) => (col.id === newColumn.id ? newColumn : col)).filter((col) => !col.meta?.archived);
        });
      },
      createColumn: (column: ColumnDef<Contact, any>) => {
        setColumns((prev) => {
          const newColumns = [...prev];
          newColumns.splice(newColumns.length - 1, 0, column);
          return newColumns;
        });
      },
      createFilter: (id: string) => {
        const input = document.querySelector(`#filter-${id}`) as HTMLInputElement;
        if (input) input.focus();
        // else {
        //   setColumnFilters((prev) => prev.concat({ id, value: "" }));
        // }
      },
      addNewRow: () => {
        dispatch(addContact({ name: "" }));
      },
      setColumnOrder: (columns: string[]) => {
        setColumnOrder(columns);
      },
      removeFromColumnOrder: (id: string) => {
        setColumnOrder((prev) => prev.filter((colId) => colId !== id));
      },
    },
  });

  const getColumnPos = (id: UniqueIdentifier) => {
    return columnOrder.findIndex((columnId) => columnId === id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = getColumnPos(active.id);
        const newIndex = getColumnPos(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  };

  return (
    <Box className="contacts" pos={"relative"}>
      <HStack flex={0} gap={2} p=".9rem" borderBottom={"1px"} borderColor={"gray.100"}>
        <Text fontSize={18} fontWeight={500}>
          People
        </Text>
        <Badge>{table.getRowModel().rows.length}</Badge>
      </HStack>
      <HStack flex={0} gap={2} p=".9rem" borderBottom={"1px"} borderColor={"gray.100"}>
        <Badge
          input
          inputValue={searchTerm}
          onInputChange={(e) => setSearchTerm(e.target.value)}
          placeholder={`Search contact name`}
          icon={SearchIcon}
          onClick={() => {}}
          py={3}
        />
        <Divider orientation="vertical" mx={1} borderColor={"gray.200"} />
        <Sort table={table} />
        <Divider orientation="vertical" mx={0.25} borderColor={"transparent"} />
        <Filters table={table} flex={1} />
        <Badge
          icon={AddIcon}
          iconColor="echoBlue.500"
          onClick={() => {
            if (table.options.meta?.addNewRow) {
              table.options.meta.addNewRow();
            }
          }}
          mr={4}
        >
          <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
            New
          </Text>
        </Badge>
      </HStack>
      <HStack
        className="selected-menu"
        bgColor={"white"}
        pos={"absolute"}
        transform={"auto"}
        translateY={"-110%"}
        border={"1px"}
        borderRadius={"10px"}
        borderColor={"gray.100"}
        boxShadow={"0px 4px 20px 0px #B7BFCA69"}
        fontSize={"sm"}
        gap={0}
        left={2}
        top={113}
        zIndex={100}
        sx={
          table.getSelectedRowModel().rows.length > 0
            ? {}
            : { display: "none", top: document.querySelector(".contact-table")?.getBoundingClientRect().y }
        }
      >
        <Text
          fontWeight={500}
          color={"echoBlue.500"}
          px={"0.5rem"}
          py={"0.2rem"}
          borderRight={"1px"}
          borderColor={"gray.100"}
        >
          {table.getSelectedRowModel().rows.length} selected
        </Text>
        <HStack
          gap={0}
          cursor={"pointer"}
          _hover={{ color: "red.500", "& path": { fill: "red.500" } }}
          onClick={() => {
            const ids = table.getSelectedRowModel().rows.map((row) => row.original.id);
            table.resetRowSelection();
            setData((prev) => prev.filter((contact) => !ids.includes(contact.id)));
            ids.forEach((id) => {
              dispatch(deleteContact(id));
            });
          }}
        >
          <Icon as={DeleteIcon} fontSize={16} mx={"0.2rem"} />
          <Text fontWeight={500} pr={"0.5rem"} py={"0.2rem"}>
            Delete
          </Text>
        </HStack>
      </HStack>
      <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter} modifiers={[restrictToHorizontalAxis]}>
        <ContactTable table={table} />
      </DndContext>
    </Box>
  );
};

export default Contacts;
