import { Icon } from "@chakra-ui/react";

interface ListItemProps {
  text: string;
  active?: boolean;
  icon?: any;
  soon?: boolean;
  iconOnly?: boolean;
}

const ListItem = ({ text, active, soon, icon, iconOnly }: ListItemProps) => {
  return (
    <li
      className={`list-item${active ? " list-item--active" : ""}${soon ? " soon" : ""}`}
      style={iconOnly ? { gap: "0px", justifyContent: "center" } : { gap: "0.4rem" }}
    >
      {icon && <Icon fontSize={"md"}>{icon}</Icon>}
      <span className="list-item__name">{text}</span>
      {/* {soon && <span className="list-item__soon">coming soon!</span>} */}
    </li>
  );
};

export default ListItem;
