import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from "@chakra-ui/react";
import { MeetingsByDate } from "components/meetings/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { getContactMeetings } from "reducers/meeting/meetingReducer";
import { getTags } from "reducers/tags/tagsReducer";
import { formatMeetingTitle, sortMeetingKeys, splitMeetings } from "utils/common";
import MeetingHeader from "components/meetings/MeetingHeader";
import { BackendMeeting } from "reducers/meeting/meetingTypes";
import Meetings from "components/meetings/Meetings";

interface ContactMeetingsProps {
  contactId: number;
  cache: { [key: string]: boolean };
  setCache: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  searchTerm: string;
}

const ContactMeetings: React.FC<ContactMeetingsProps> = ({ contactId, cache, setCache, searchTerm }) => {
  // const dispatch = useAppDispatch();
  const meetingState = useAppSelector((state) => state.meeting);

  // const [meetings, setMeetings] = useState<MeetingsByDate>({ past: [], today: [], tomorrow: [] });

  // useEffect(() => {
  //   if (!cache["meetings"] && contactId) {
  //     dispatch(getContactMeetings(contactId));
  //     dispatch(getMeetingPlans());
  //     dispatch(getTags());
  //     setCache((prev) => {
  //       return { ...prev, meetings: true };
  //     });
  //   }
  // }, [contactId, meetingState.contactMeetings]);

  // const filterMeetings = (meetings: BackendMeeting[]): BackendMeeting[] => {
  //   return meetings.filter((meeting) => meeting.name.toLowerCase().includes(searchTerm.toLowerCase()));
  // };

  // useEffect(() => {
  //   if (meetingState.status["get_contact"] === "fulfilled" || meetingState.status["add_tag"] === "fulfilled") {
  //     if (meetingState.status["add_tag"] === "fulfilled") {
  //       dispatch(getTags());
  //     }
  //     setMeetings(splitMeetings(filterMeetings(meetingState.contactMeetings)));
  //   }
  // }, [meetingState.status["get_contact"], meetingState.status["add_tag"], searchTerm]);

  // const renderItems = () => {
  //   const keys = Object.keys(meetings);
  //   return sortMeetingKeys(keys).map((key) => {
  //     if (meetings[key].length > 0) {
  //       let header = formatMeetingTitle(key);
  //       return (
  //         <AccordionItem key={key}>
  //           <AccordionButton py={1} color={"gray.400"} bgColor={"gray.50"} _hover={{ bgColor: "gray.100" }}>
  //             <AccordionIcon fontSize={"sm"} mt={0.5} mr={2} />
  //             <Box as="span" flex="1" textAlign="left" fontSize={"sm"} fontWeight={500}>
  //               {header} ({meetings[key].length})
  //             </Box>
  //           </AccordionButton>
  //           <AccordionPanel p={0} color={"gray.800"}>
  //             {meetings[key].map((meeting) => {
  //               return <MeetingHeader key={meeting.id} meeting={meeting} type={key === "past" ? "past" : "future"} />;
  //             })}
  //           </AccordionPanel>
  //         </AccordionItem>
  //       );
  //     }
  //   });
  // };

  return (
    // <Accordion allowMultiple defaultIndex={[0]} sx={{ "& .chakra-collapse": { overflowX: "auto !important" } }}>
    //   {renderItems()}
    // </Accordion>
    <Meetings
      type="all"
      meetings={meetingState.contactMeetings.filter((meeting) =>
        meeting.name.toLowerCase().includes(searchTerm.toLowerCase())
      )}
    />
  );
};

export default ContactMeetings;
