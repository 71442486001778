import { Box, Tooltip } from "@chakra-ui/react";
import Avatar from "./Avatar";

const TooltipAvatar: typeof Avatar = ({ name, ...rest }) => (
  <Tooltip label={name}>
    <Box>
      <Avatar name={name} {...rest} />
    </Box>
  </Tooltip>
);

export default TooltipAvatar;
