import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { LoginCred, RegisterUser, RegisterUserOptional, User, UserState } from "./userTypes";

export const login = createAsyncThunk("user/login", Login);
export const getUserInfo = createAsyncThunk("user/getUserInfo", GetUserInfo);
export const updateUserInfo = createAsyncThunk("user/updateUserInfo", async (body: RegisterUserOptional) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/user/me`;
  return await put(url, body);
});
export const logout = createAsyncThunk("user/Logout", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/auth/token-cookie`;
  return await http_delete(url);
});

export const loginAndGetInfo = createAsyncThunk("user/loginAndGetInfo", async (cred: LoginCred) => {
  await Login(cred);
  return await GetUserInfo();
});

export const registerUser = createAsyncThunk("user/registerUser", async (body: RegisterUser) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/user/`;

  await post(url, body);

  await Login({
    email: body.email,
    password: body.password,
  });

  return await GetUserInfo();
});

async function Login(body: LoginCred) {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/auth/token-cookie`;
  return await post(url, body);
}

async function GetUserInfo() {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/user/me`;
  return await get(url);
}

//PENDING

const isFetching = (key: string) => (state: UserState) => {
  state.status[key] = "fetching";
};

//FULFILLED

const userConnected = (key: string) => (state: UserState) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];
};

const gotUserInfo = (key: string) => (state: UserState, action: PayloadAction<User>) => {
  state.status[key] = "fulfilled";
  delete state.status["disconnect"];
  delete state.errors[key];

  state.user = action.payload;
};

const userLoggedout = (key: string) => (state: UserState) => {
  state.status[key] = "fulfilled";
  delete state.status["connect"];
  delete state.status["info"];
  delete state.errors[key];

  state.user = null;
};

//REJECTED

//TODO: change action type
const requestRejected = (key: string, disconnect: boolean) => (state: UserState, action: any) => {
  state.status[key] = "rejected";
  state.errors[key] = action.error.message;
  if (disconnect) {
    state.user = null;
  }
};

const initialState: UserState = {
  user: null,
  errors: {},
  status: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, isFetching("connect"))
      .addCase(login.fulfilled, userConnected("connect"))
      .addCase(login.rejected, requestRejected("connect", true))

      .addCase(loginAndGetInfo.pending, isFetching("connect"))
      .addCase(loginAndGetInfo.fulfilled, gotUserInfo("connect"))
      .addCase(loginAndGetInfo.rejected, requestRejected("connect", true))

      .addCase(getUserInfo.pending, isFetching("info"))
      .addCase(getUserInfo.fulfilled, gotUserInfo("info"))
      .addCase(getUserInfo.rejected, requestRejected("info", false))

      .addCase(updateUserInfo.pending, isFetching("update"))
      .addCase(updateUserInfo.fulfilled, gotUserInfo("update"))
      .addCase(updateUserInfo.rejected, requestRejected("update", false))

      .addCase(logout.pending, isFetching("disconnect"))
      .addCase(logout.fulfilled, userLoggedout("disconnect"))
      .addCase(logout.rejected, requestRejected("disconnect", true))

      .addCase(registerUser.pending, isFetching("register"))
      .addCase(registerUser.fulfilled, gotUserInfo("register"))
      .addCase(registerUser.rejected, requestRejected("register", true));
  },
});

export const { resetError, resetStatus } = userSlice.actions;
export default userSlice.reducer;
