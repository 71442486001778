import { Box, BoxProps, Divider, HStack, Text } from "@chakra-ui/react";
import Badge from "./Badge";
import { ReactComponent as FilterIcon } from "assets/icons/filter.svg";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

interface TabHeaderProps extends BoxProps {
  tab: string;
  show: boolean;
  onAdd?: () => void;
  searchTerm: string | null;
  setSearchTerm: (value: string) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({ tab, show, onAdd, searchTerm, setSearchTerm }) => {
  if (show) {
    return (
      <HStack gap={1} px={4} py={1.5}>
        <HStack flex={1}>
          {searchTerm !== null && (
            <Badge
              input
              inputValue={searchTerm}
              onInputChange={(e) => setSearchTerm(e.target.value)}
              placeholder={`Search ${tab}...`}
              icon={SearchIcon}
              onClick={() => {}}
            />
          )}
          {/* <Badge icon={FilterIcon} onClick={() => {}}>
            <Text color="gray.800" fontSize={"sm"} py={0.5}>
              Filter
            </Text>
          </Badge> */}
        </HStack>
        <HStack>
          {onAdd && (
            <Badge icon={AddIcon} iconColor="echoBlue.500" onClick={onAdd}>
              <Text color="echoBlue.500" fontSize={"sm"} py={0.5}>
                Add {tab.slice(0, -1)}
              </Text>
            </Badge>
          )}
        </HStack>
      </HStack>
    );
  } else {
    return <></>;
  }
};

export default TabHeader;
