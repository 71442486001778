import { Box, BoxProps, Center } from "@chakra-ui/react";
import { StatusOption } from "components/contacts/table/Meta";
import { Status as StatusType } from "components/contacts/types";

interface StatusProps extends BoxProps {
  selected: StatusType | null;
  options: StatusOption[];
}

const Status: React.FC<StatusProps> = ({ selected, options, ...rest }) => {
  return (
    <Center
      w="100%"
      // p="0.5rem 2.5rem"
      h="2.5rem"
      color={"gray.600"}
      fontWeight={500}
      cursor={"pointer"}
      bg={selected ? `var(--chakra-colors-${options.find((option) => option.id === selected.value)?.color}-200)` : ""}
      {...rest}
    >
      {selected ? options.find((option) => option.id === selected.value)?.value : <Box h="23px"></Box>}
    </Center>
  );
};

export default Status;
