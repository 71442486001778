import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import Menu from "components/layout/Menu";
import { sections_flat } from "utils/menuSections";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import routes from "utils/appRoutes";
import Contacts from "components/contacts/Contacts";
import Login from "components/auth/Login";
import { getUserInfo, updateUserInfo } from "reducers/user/userReducer";
import { Box, Button, Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";
import ContactPage from "components/contact/ContactPage";
import { getWorkspaceUsers } from "reducers/workspace/workspaceReducer";
import MeetingPage from "components/meetings/MeetingPage";
import { getMeetings } from "reducers/meeting/meetingReducer";
import { getContacts } from "reducers/contact/contactReducer";
import { getContactTable } from "reducers/contact-table/contactTableReducer";
import ChangePassword from "components/auth/ChangePassword";
import { connectToRecall, getGoogleOAuth2, getGoogleRedirectLink } from "reducers/google/googleReducer";

export default function App() {
  const dispatch = useAppDispatch();

  const userState = useAppSelector((state) => state.user);
  const workspaceState = useAppSelector((state) => state.workspace);
  const googleState = useAppSelector((state) => state.google);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (workspaceState.status["primary"] === "fulfilled") {
      dispatch(getContactTable());
      dispatch(getContacts());
    }
  }, [workspaceState.status["primary"]]);

  useEffect(() => {
    if (userState.status["info"] === "fulfilled") {
      dispatch(getWorkspaceUsers());
      dispatch(getMeetings());
      switch (userState.user?.meta.onboardingStage) {
        case undefined:
          dispatch(updateUserInfo({ meta: { ...userState.user?.meta, onboardingStage: "change_password" } }));
          break;

        case "connect_calendar":
          dispatch(getGoogleOAuth2());
          break;

        case "connect_recall":
          dispatch(connectToRecall());
          break;

        default:
          break;
      }
    }
  }, [userState.status["info"]]);

  useEffect(() => {
    if (userState.status["update"] === "fulfilled") {
      if (userState.user?.meta.onboardingStage === "connect_calendar") {
        dispatch(getGoogleOAuth2());
      } else if (userState.user?.meta.onboardingStage === "connect_recall") {
        dispatch(connectToRecall());
      }
    }
  }, [userState.status["update"]]);

  useEffect(() => {
    if (googleState.status["primary"] === "fulfilled") {
      if (googleState.oAuth2.connected) {
        if (userState.user?.meta.onboardingStage === "connect_calendar") {
          dispatch(updateUserInfo({ meta: { ...userState.user?.meta, onboardingStage: "connect_recall" } }));
        }
      } else {
        dispatch(getGoogleRedirectLink());
      }
    }
  }, [googleState.status["primary"]]);

  useEffect(() => {
    if (googleState.status["get_redirect"] === "fulfilled") {
      window.open(googleState.oAuth2.redirect_link!, "_self");
    }
  }, [googleState.status["get_redirect"]]);

  useEffect(() => {
    if (googleState.status["connect_recall"] === "fulfilled") {
      dispatch(updateUserInfo({ meta: { ...userState.user?.meta, onboardingStage: "completed" } }));
    }
  }, [googleState.status["connect_recall"]]);

  const renderMenuRoutes = () => {
    let sections = sections_flat;
    return sections.map((item) => {
      return <Route key={item.path} path={item.path} element={item.component} />;
    });
  };

  const renderRoutes = () => {
    return routes.map((item) => {
      return <Route key={item.path} path={item.path} element={item.component} />;
    });
  };

  const renderApp = () => {
    switch (userState.user?.meta.onboardingStage) {
      case "change_password":
        return <ChangePassword isOpen={true} onClose={() => {}} isCentered={true} />;

      case "connect_recall":
      case "connect_calendar":
        return <></>;

      case "completed":
        return (
          <BrowserRouter>
            <Routes>
              <Route path={"/"} element={<Menu />}>
                <Route index element={<Contacts />} />
                {renderMenuRoutes()}
                {renderRoutes()}
                <Route path="/people/:contactId" element={<ContactPage />} />
                <Route path="/meetings/:meetingId" element={<MeetingPage />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        );

      default:
        return <>Not found</>;
    }
  };

  return (
    <Box className="App">
      {userState.status["info"] === "fulfilled" ? (
        renderApp()
      ) : (
        <Login
          isOpen={userState.status["info"] === "rejected" || userState.status["disconnect"] === "fulfilled"}
          onClose={() => {}}
          isCentered={true}
        />
      )}
    </Box>
  );
}
