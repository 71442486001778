import { ReactNode } from "react";
import NotFound from "pages/NotFound";
import Contacts from "components/contacts/Contacts";
import Meetings from "components/meetings/Meetings";
import ActionItems from "components/action-items/ActionItems";
import Workflows from "components/workflows/Workflows";
import Templates from "components/templates/Templates";
import Integrations from "components/integrations/Integrations";
import Settings from "components/settings/Settings";
import { ReactComponent as PeopleIcon } from "assets/icons/people-call.svg";
import { ReactComponent as MeetingsIcon } from "assets/icons/call.svg";
import { ReactComponent as TasksIcon } from "assets/icons/task-list.svg";
import MeetingsPage from "components/meetings/MeetingsPage";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ConnectedIcon } from "assets/icons/connected.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";

export interface MenuItem {
  text: string;
  path: string;
  component?: ReactNode;
  icon?: any;
  external?: boolean;
  soon?: boolean;
  home?: boolean;
}

export interface MenuSection {
  position: string;
  items: MenuItem[];
}

const section1: MenuSection = {
  position: "top",
  items: [
    {
      text: "People",
      component: <Contacts />,
      path: "people",
      icon: <PeopleIcon />,
      home: true,
    },
    {
      text: "Meetings",
      component: <MeetingsPage />,
      path: "meetings",
      icon: <CalendarIcon />,
    },
    {
      text: "Templates",
      component: <Templates />,
      path: "templates",
      icon: <BookIcon />,
    },
    // {
    //   text: "Tasks",
    //   component: <ActionItems />,
    //   path: "tasks",
    //   icon: <TasksIcon />,
    // },
    // {
    //   text: "Workflows",
    //   component: <Workflows />,
    //   path: "workflows",
    //   icon: <AccountTreeOutlinedIcon />,
    // },
    // {
    //   text: "Not Found",
    //   component: <NotFound />,
    //   path: "404",
    // },
  ],
};
const section2: MenuSection = {
  position: "top",
  items: [],
};
const section3: MenuSection = {
  position: "top",
  items: [],
};
const section4: MenuSection = {
  position: "bottom",
  items: [
    // {
    //   external: true,
    //   text: "Docs",
    //   path: "https://docs.tailorr.io/",
    // },
    // {
    //   text: "Integrations",
    //   component: <Integrations />,
    //   path: "integrations",
    //   icon: <ConnectedIcon />,
    // },
    {
      text: "Settings",
      component: <Settings />,
      path: "settings",
      icon: <SettingsIcon />,
    },
  ],
};

export const sections = [section1, section2, section3, section4];
export const sections_flat = [...section1.items, ...section2.items, ...section3.items, ...section4.items];
