import { Box, BoxProps, Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import Badge from "./Badge";
import { ReactComponent as EnterIcon } from "assets/icons/enter.svg";
import { useEffect, useRef, useState } from "react";
import Icon from "./Icon";

interface EnterInputProps extends Omit<InputProps, "onBlur"> {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>, isEnterKey: boolean, isEscPressed: boolean) => void;
  autoFocus?: boolean;
  type?: string;
  noBorder?: boolean;
  color?: string;
  size?: string;
}

const EnterInput: React.FC<EnterInputProps> = ({
  value,
  onChange,
  onBlur,
  autoFocus,
  noBorder,
  placeholder,
  type,
  color,
  size,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // const [isEnterPressed, setIsEnterPressed] = useState(false);
  let isEnterPressed = false;
  let isEscPressed = false;

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [inputRef.current]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      isEnterPressed = true;
      e.currentTarget.blur();
    } else if (e.key === "Escape") {
      isEscPressed = true;
      e.stopPropagation();
      e.currentTarget.blur();
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur(e, isEnterPressed, isEscPressed);
    isEscPressed = false;
    isEnterPressed = false;
  };

  return (
    <InputGroup mb={"0.5rem"} {...rest}>
      <Input
        ref={inputRef}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className="enter-input"
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={handleBlur}
        fontSize={size ? size : "sm"}
        size={size ? size : "sm"}
        color={color ? color : "gray.800"}
        borderRadius={4}
        outline={"1px"}
        borderColor={"gray.200"}
        minW={20}
        {...(noBorder ? { border: "none", pl: 2 } : {})}
        _focusVisible={{ boxShadow: "unset", border: "1px", borderColor: "echoBlue.500", bg: "white", pl: 3 }}
        type={type ? type : "text"}
        {...rest}
      />
      <InputRightElement
        height={"-webkit-fill-available"}
        visibility={"hidden"}
        mt={"1px"}
        sx={{ ".enter-input:focus-visible + &": { visibility: "visible" } }}
      >
        <Badge className="">
          <Icon as={EnterIcon} fontSize={10} />
        </Badge>
      </InputRightElement>
    </InputGroup>
  );
};

export default EnterInput;
