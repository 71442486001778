import { HTMLProps } from "react";

interface PaperProps extends HTMLProps<HTMLDivElement> {}

const Paper: React.FC<PaperProps> = (props) => {
  let style = props.style ? props.style : {};

  let className = props.className ? "t-paper " + props.className : "t-paper";
  if (props.width) {
    style["width"] = props.width;
  }
  if (props.height) {
    style["height"] = props.height;
  }

  return (
    <div {...props} className={className} style={style}>
      {props.children}
    </div>
  );
};

export default Paper;
