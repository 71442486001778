import {
  Box,
  BoxProps,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import { SelectOption, StatusOption } from "./Meta";

interface DeleteOptionModalProps {
  option: SelectOption | StatusOption | undefined;
  isOpen: boolean;
  onClose: (shouldDelete: boolean) => void;
}

const DeleteOptionModal: React.FC<DeleteOptionModalProps> = ({ isOpen, option, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete option</ModalHeader>
        <ModalBody>Are you sure you want to delete option '{option?.value}'?</ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={() => onClose(false)}>
            Cancel
          </Button>
          <Button onClick={() => onClose(true)} variant="outline" colorScheme="red">
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteOptionModal;
