import {
  AbsoluteCenter,
  Box,
  BoxProps,
  Checkbox,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import IconButton from "components/base/IconButton";
import { TalkingPoint as TalkingPointType } from "components/meetings/types";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import DynamicTextarea from "components/base/DynamicTextarea";
import TalkingPointMenu from "./TalkingPointMenu";
import { ReactComponent as SparkleIcon } from "assets/icons/sparkle.svg";
import Icon from "components/base/Icon";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { post } from "api/requests";

const API_BASE = process.env.REACT_APP_TAILORR_API_ADDRESS;

interface TalkingPointProps extends Omit<BoxProps, "pos"> {
  tp: TalkingPointType;
  index: number;
  onValueChange: (tp: TalkingPointType | null) => void;
  autoFocus?: boolean;
  onEnter?: (tp: TalkingPointType) => void;
  initialChecked: boolean;
  onCheckedChange: (newValue: string) => void;
  isTemplate?: boolean;
  pos: "sole" | "top" | "bottom" | "middle";
  onOrderChange: (direction: "up" | "down") => void;
}

const TalkingPoint: React.FC<TalkingPointProps> = ({
  tp,
  index,
  onValueChange,
  pos,
  initialChecked,
  onCheckedChange,
  autoFocus,
  onEnter,
  isTemplate,
  onOrderChange,
}) => {
  const [name, setName] = useState(tp.name);
  const [items, setItems] = useState(tp.items);
  const [isChecked, setIsChecked] = useState(initialChecked);
  const [prompt, setPrompt] = useState<string>(tp.prompt || "");
  const [showPrompt, setShowPrompt] = useState(false);
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [promptKey, setPromptKey] = useState(0);

  const generatePrompt = async () => {
    setIsPromptLoading(true);
    const url = `${API_BASE}/entity-extraction/generate-talking-point-prompt`;
    const response: { prompt: string | null } = await post(url, { talking_point: name });
    setPrompt(response.prompt || "");
    setShowPrompt(true);
    setIsPromptLoading(false);
    onValueChange({ id: tp.id, items, name, prompt: response.prompt });
    setPromptKey((prev) => prev + 1);
  };

  // useEffect(() => {
  //   if (entityExtractionState.payload.talking_point_id === tp.id) {
  //     //
  //   }
  // }, [entityExtractionState.payload]);

  return (
    <Stack key={tp.id} gap={0} pb={0} mb={2}>
      <HStack
        gap={1}
        borderColor={"gray.100"}
        borderRadius={4}
        w={"100%"}
        px={2}
        py={1}
        _hover={{
          "& .talking-point-menu-icon": { visibility: "visible", opacity: 1 },
          "& .prompt-icon": prompt || showPrompt ? { visibility: "visible", opacity: 1 } : {},
          bgColor: "gray.50",
        }}
        alignItems={"start"}
      >
        {isTemplate ? (
          <Box as={"span"} color={"gray.700"}>
            •
          </Box>
        ) : (
          <Checkbox
            isChecked={isChecked}
            onChange={() => {
              onCheckedChange(tp.id);
              setIsChecked((prev) => !prev);
            }}
            size={"md"}
            colorScheme=""
            sx={{
              "& span[data-checked]": { bgColor: "gray.500", borderColor: "gray.500" },
              "& span": { borderColor: "gray.400" },
            }}
            my={0.5}
          />
        )}
        <Box w={"100%"}>
          <DynamicTextarea
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onBlur={() => onValueChange({ id: tp.id, items, name, prompt: prompt.length > 0 ? prompt : null })}
            color={!isTemplate && isChecked ? "gray.500" : "gray.700"}
            placeholder="Talking point"
            fontSize={"sm"}
            fontWeight={500}
            autoFocus={autoFocus}
            onEnter={() => onEnter && onEnter({ id: tp.id, items, name, prompt: prompt.length > 0 ? prompt : null })}
            border={0}
            resize={"none"}
            py={0}
            px={1}
            _focusVisible={{ outline: "none" }}
            textDecor={!isTemplate && isChecked ? "line-through" : undefined}
            fullWidth={true}
          />
          {isPromptLoading && <Spinner size={"sm"} color="echoBlue.500" />}
          <HStack className="prompt" fontWeight={500} fontSize={"sm"} p={1} display={showPrompt ? "block" : "none"}>
            {/* <Text as={"span"} color={"echoBlue.500"}>
              Retrieval prompt:{" "}
            </Text> */}
            <DynamicTextarea
              key={promptKey}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onBlur={(e) => {
                const newPrompt = e.target.value.length > 0 ? e.target.value : null;
                onValueChange({ id: tp.id, items, name, prompt: newPrompt });
                if (e.target.value.length === 0) setShowPrompt(false);
              }}
              size={"sm"}
              variant={"unstyled"}
              color={"echoBlue.500"}
              // fontWeight={500}
              // onKeyDown={(e) => {
              //   if (e.key === "Enter") {
              //     e.currentTarget.blur();
              //   } else if (e.key === "Escape") {
              //     e.stopPropagation();
              //     e.currentTarget.blur();
              //   }
              // }}
              fullWidth={true}
            />
          </HStack>
        </Box>
        <HStack gap={0} transform={"translateY(-2px)"}>
          <IconButton
            className="prompt-icon"
            icon={SparkleIcon}
            visibility={prompt ? "visible" : "hidden"}
            opacity={prompt ? 1 : 0}
            color={showPrompt ? "echoBlue.500" : "gray.700"}
            onClick={() => {
              setShowPrompt((prev) => !prev);
            }}
            mt={0}
          />
          <TalkingPointMenu
            ml={2}
            pos={pos}
            onDelete={() => onValueChange(null)}
            onOrderChange={onOrderChange}
            onPromptToggle={() => {
              if (prompt.length == 0) {
                generatePrompt();
              } else {
                setPrompt("");
                onValueChange({ id: tp.id, items, name, prompt: null });
                setShowPrompt(false);
              }
            }}
            hasPrompt={prompt.length > 0}
          />
        </HStack>
      </HStack>
    </Stack>
  );
};

export default TalkingPoint;
