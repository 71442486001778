const Format = {
  float: (value: number, num_digits: number) => {
    if (value === Math.floor(value)) {
      return value;
    } else {
      return value.toFixed(num_digits);
    }
  },
  capitalize: (str: string | undefined) => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  },
  snake_case: (str: string) => {
    return str.toLowerCase().replace(/\s/g, "_");
  },
  date: (date: Date, showWeekday = false, locale = "en-US") => {
    let options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (showWeekday) {
      options.weekday = "long";
    }
    return date.toLocaleDateString(locale, options);
  },
  http: (value: string) => {
    let formatted = value.trim().toLowerCase();
    if (!formatted.startsWith("http")) {
      return "https://" + formatted;
    } else {
      return formatted;
    }
  },
  link: (value: string) => {
    if (value.length === 0) {
      return value;
    }
    let formatted = value.replace(/(https?:\/\/)?(www\.)?/i, "");
    const index = formatted.indexOf("/");
    if (index !== -1 && formatted.length - index >= 12) {
      const followers = formatted.substring(index + 1);
      const transformedFollowers = `${followers.substring(0, 3)}...${followers.substring(followers.length - 6)}`;
      return formatted.substring(0, index + 1) + transformedFollowers;
    }
    return formatted;
  },
};

export default Format;
