import { CellContext } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Contact } from "../types";
import { TextCellType } from "reducers/contact-table/contactTableTypes";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { addContact, updateContact } from "reducers/contact/contactReducer";
import { Meeting } from "../../meetings/types";
import { Flex, HStack } from "@chakra-ui/react";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import Badge from "../../base/Badge";
import { ReactComponent as EnterIcon } from "assets/icons/enter.svg";
import Icon from "components/base/Icon";

interface EditableCellProps extends CellContext<Contact, TextCellType> {
  type: string;
  mainColumn?: boolean;
}

const TextCell: React.FC<EditableCellProps> = ({ getValue, row, column, table, type, mainColumn }) => {
  const dispatch = useAppDispatch();
  const initialValue: TextCellType = getValue() || "";
  const [value, setValue] = useState<TextCellType>(initialValue);

  const contactState = useAppSelector((state) => state.contact);

  const onBlur = () => {
    table.options.meta?.updateData(row.index, column.id, value);

    const contact = contactState.contacts.find((contact) => contact.id === row.original.id);
    if (["name", "company", "email"].includes(column.id)) {
      if (contact) {
        if (contact[column.id as "name" | "company" | "email"] === value) return;
        if (value === "" && contact[column.id as "name" | "company" | "email"] === null) return;
      }
      if (column.id === "email" && !value) {
        dispatch(updateContact({ id: row.original.id, ["email"]: null }));
      } else {
        dispatch(updateContact({ id: row.original.id, [column.id]: value }));
      }
    } else {
      if (contact && contact.columns[column.id] === value) return;
      dispatch(updateContact({ id: row.original.id, columns: { [column.id]: value } }));
    }
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  return (
    <HStack pos={"relative"} gap={1}>
      {mainColumn && (
        // <Flex justify={"center"}>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        // </Flex>
      )}
      <input
        value={value}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={(e) => setValue(e.target.value)}
        className={`editable-cell__input absolute${mainColumn ? " main-column" : ""}`}
        style={type === "number" ? { textAlign: "center" } : {}}
        type={type}
      />
      <Badge className="enter-icon" pos="absolute" right={"0.9rem"}>
        <Icon as={EnterIcon} fontSize={10} />
      </Badge>
    </HStack>
  );
};

export default TextCell;
