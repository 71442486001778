import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { getContactMeetings, getMeetings, getMyMeetings } from "reducers/meeting/meetingReducer";
import { addTag, deleteTag, getTags } from "reducers/tags/tagsReducer";
import Meetings from "./Meetings";

interface UpcomingMeetingsProps {
  cache: { [key: string]: boolean };
  setCache: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  searchTerm: string;
}

const UpcomingMeetings: React.FC<UpcomingMeetingsProps> = ({ cache, setCache, searchTerm }) => {
  const meetingState = useAppSelector((state) => state.meeting);

  return (
    <Meetings
      meetings={meetingState.myMeetings.filter((meeting) =>
        meeting.name.toLowerCase().includes(searchTerm.toLowerCase())
      )}
      type="future"
    />
  );
};

export default UpcomingMeetings;
