import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  Divider,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MeetingResults as MeetingResultsType, MeetingResultsTalkingPoint } from "../meetings/types";
import Badge from "components/base/Badge";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import MeetingResultsSectionHeader from "./MeetingResultsSectionHeader";
import MeetingResultsSectionMenu from "./MeetingResultsSectionMenu";
import MeetingResultsSection from "./MeetingResultsSection";

interface MeetingResultsProps extends BoxProps {
  name: string;
  meetingResults: MeetingResultsType | undefined;
}

const MeetingResults: React.FC<MeetingResultsProps> = ({ name, meetingResults }) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [accordionIndex, setAccordionIndex] = useState<number[]>([]);
  const [accordionHeight, setAccordionHeight] = useState("80vh");
  const [filteredMeetingResults, setFilteredMeetingResults] = useState<MeetingResultsType>([]);

  useEffect(() => {
    if (accordionRef.current) {
      setAccordionHeight(`calc(100vh - ${accordionRef.current.getBoundingClientRect().y + 10}px)`);
    }
  }, [accordionRef.current]);

  useEffect(() => {
    if (accordionIndex.length === 0 && meetingResults) {
      setAccordionIndex(meetingResults.map((_, index) => index));
    }

    if (meetingResults) {
      let filteredSections = meetingResults.filter((section) => {
        if (section.talking_points.length === 0) return false;
        if (!section.talking_points.some((tp) => tp.ai_bullets.length > 0 || tp.user_bullets.length > 0)) return false;
        return true;
      });
      let filtered: MeetingResultsType = [];
      filteredSections.forEach((section) => {
        let talking_points: MeetingResultsTalkingPoint[] = [];
        section.talking_points.forEach((tp) => {
          if (tp.ai_bullets.length > 0 || tp.user_bullets.length > 0) talking_points.push(tp);
        });
        if (talking_points.length > 0) filtered.push({ id: section.id, name: section.name, talking_points });
      });
      setFilteredMeetingResults(filtered);
    }
  }, [meetingResults]);

  const toggleAccordion = (index: number) => {
    if (accordionIndex.includes(index)) {
      setAccordionIndex((prev) => prev.filter((prevIndex) => prevIndex !== index));
    } else {
      setAccordionIndex((prev) => [...prev, index]);
    }
  };

  return (
    <Stack className="meeting-results" px={6}>
      <Text fontSize={"xl"} fontWeight={600} color={"gray.800"}>
        {name}
      </Text>
      <Divider />
      <Accordion
        ref={accordionRef}
        index={accordionIndex}
        allowMultiple
        height={accordionHeight}
        overflow={"auto"}
        sx={{
          "& .chakra-collapse": { overflow: "auto !important", opacity: "1", height: "auto", display: "block" },
          "&::-webkit-scrollbar": { width: 0 },
        }}
      >
        {filteredMeetingResults.map((section, sectionIndex) => {
          return (
            <AccordionItem key={section.id} border={0}>
              <HStack
                _hover={{
                  "& .section-menu-icon": { visibility: "visible", opacity: 1 },
                }}
              >
                <AccordionButton
                  onClick={() => toggleAccordion(sectionIndex)}
                  _hover={{
                    backgroundColor: "gray.50", // Example default hover style
                    "& .section-add": { opacity: 1, visibility: "visible" },
                    "& .accordion-icon": { opacity: 1, visibility: "visible" },
                  }}
                  w={"unset"}
                  flex={1}
                >
                  <HStack flex={1} justifyContent={"space-between"}>
                    <Box onClick={(e) => {}} flex={1}>
                      <MeetingResultsSectionHeader
                        section={section}
                        onSectionChange={(newSection) => {
                          // updateSections(newSection, sectionIndex);
                          // if (newSection === null) {
                          //   let updated = accordionIndex.filter((index) => index !== sectionIndex);
                          //   updated = updated.map((index) => {
                          //     if (index < sectionIndex) return index;
                          //     return index - 1;
                          //   });
                          //   setAccordionIndex(updated);
                          // }
                        }}
                      />
                    </Box>

                    {/* <Box onClick={(e) => e.stopPropagation()} className="section-add" opacity={0} visibility="hidden">
                      <Badge
                        icon={AddIcon}
                        onClick={() => {
                          // const newSection: MeetingPlanSectionType = {
                          //   id: generateTimeBasedHash("new_section"),
                          //   type: null,
                          //   description: null,
                          //   estimated: 10,
                          //   talking_points: [{ id: generateTimeBasedHash("new_tp"), name: "", items: [] }],
                          // };
                          // const updatedSections = [...sections];
                          // updatedSections.splice(sectionIndex, 0, newSection); // Insert before current
                          // setSections(updatedSections);
                          // setNeedToSave(true);
                          // setNewSectionIndex(sectionIndex);
                          // setAccordionIndex((prev) => {
                          //   const newIndices = prev.map((index) => (index >= sectionIndex ? index + 1 : index));
                          //   // Insert the new section's index at its position and open it
                          //   newIndices.splice(sectionIndex, 0, sectionIndex);
                          //   return newIndices;
                          // });
                        }}
                      >
                        <Text fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                          Section above
                        </Text>
                      </Badge>
                    </Box> */}
                  </HStack>
                  {/* <MeetingResultsSectionMenu
                    ml={2}
                    pos={
                      filteredMeetingResults.length === 1
                        ? "sole"
                        : sectionIndex === 0
                        ? "top"
                        : sectionIndex === filteredMeetingResults.length - 1
                        ? "bottom"
                        : "middle"
                    }
                    onDelete={() => {
                      // updateSections(null, sectionIndex);
                      // let updated = accordionIndex.filter((index) => index !== sectionIndex);
                      // updated = updated.map((index) => {
                      //   if (index < sectionIndex) return index;
                      //   return index - 1;
                      // });
                      // setAccordionIndex(updated);
                    }}
                    onOrderChange={(direction) => {
                      // const updatedSections = bubbleElement(sections, sectionIndex, direction);
                      // const updatedIndexes = adjustIndexArrayAfterReorder(accordionIndex, sectionIndex, direction);
                      // setSections(updatedSections);
                      // setAccordionIndex(updatedIndexes);
                      // setNeedToSave(true);
                    }}
                  /> */}
                </AccordionButton>
              </HStack>

              <AccordionPanel pb={2} pt={0}>
                <MeetingResultsSection section={section} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
        {/* <Badge
          icon={AddIcon}
          mt={2}
          onClick={() => {
            // const newSection: MeetingPlanSectionType = {
            //   id: generateTimeBasedHash("new_section"),
            //   type: null,
            //   description: null,
            //   estimated: 10,
            //   talking_points: [],
            // };
            // setSections([...sections, sections.length === 0 ? default_section : newSection]);
            // setNeedToSave(true);
            // setNewSectionIndex(sections.length);
            // setAccordionIndex((prev) => [...prev, sections.length]);
          }}
        >
          <Text fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
            Section
          </Text>
        </Badge> */}
      </Accordion>
    </Stack>
  );
};

export default MeetingResults;
