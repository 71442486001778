import { Box, BoxProps, Button, Td } from "@chakra-ui/react";
import { Cell, Row, flexRender } from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { COL_SIZE_PAD } from "./ContactTable";
import { Contact } from "../types";
import { useSortable } from "@dnd-kit/sortable";
import { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";

interface DragAlongTdProps extends BoxProps {
  row: Row<Contact>;
  cell: Cell<Contact, unknown>;
  index: number;
  resizingIndex: number;
}

const DragAlongTd: React.FC<DragAlongTdProps> = ({ row, cell, index, resizingIndex }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.3 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize() + COL_SIZE_PAD,
    minWidth: cell.column.getSize() + COL_SIZE_PAD,
    // zIndex: isDragging ? 1 : 1,
  };

  return (
    <Td
      style={style}
      ref={setNodeRef}
      color={"gray.700"}
      fontSize={"sm"}
      p={0}
      className={` col-${index} ${index === 0 ? "contact" : ""} ${resizingIndex === index ? "resizing" : ""}`}
      sx={row.getIsSelected() ? { bgColor: "gray.50" } : {}}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
      {index === 0 && (
        <Link to={`/people/${row.original.id}`}>
          <Button variant="outline" size="xs" className="contact__open-button">
            open
          </Button>
        </Link>
      )}
    </Td>
  );
};

export default DragAlongTd;
