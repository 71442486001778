import { CellContext } from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { Contact } from "../types";
import { UrlCellType } from "reducers/contact-table/contactTableTypes";
import Format from "utils/format";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContact } from "reducers/contact/contactReducer";
import { Meeting } from "components/meetings/types";
import { Box } from "@chakra-ui/react";

const UrlCell: React.FC<CellContext<Contact, UrlCellType>> = ({ getValue, row, column, table }) => {
  const dispatch = useAppDispatch();

  const initialValue = getValue() || "";
  const [value, setValue] = useState<UrlCellType>(initialValue);
  const [isActive, setIsActive] = useState(false);

  const onBlur = () => {
    setIsActive(false);
    table.options.meta?.updateData(row.index, column.id, value);
    dispatch(updateContact({ id: row.original.id, columns: { [column.id]: value } }));
  };

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsActive(true);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  return (
    <Box pos={"relative"}>
      <input
        value={isActive ? value : Format.link(value)}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={(e) => setValue(e.target.value)}
        className="editable-cell__input"
        type="text"
      />
    </Box>
  );
};

export default UrlCell;
