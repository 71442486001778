import {
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Select,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import EnterInput from "components/base/EnterInput";
import { ReactComponent as SortIcon } from "assets/icons/arrow-sort.svg";
import { ReactNode, useEffect, useState } from "react";
import { Column, Table } from "@tanstack/react-table";
import { Contact } from "./types";
import { renderIcon } from "./table/ThMenu";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { setSortState, updateContactTable } from "reducers/contact-table/contactTableReducer";

interface SortProps {
  table: Table<Contact>;
}

const Sort: React.FC<SortProps> = ({ table }) => {
  const dispatch = useAppDispatch();

  const meta = useAppSelector((state) => state.contactTable.contactTable.meta);
  const sortState = useAppSelector((state) => state.contactTable.sortState);

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [chosenCol, setChosenCol] = useState<string>("");
  const [isDesc, setIsDesc] = useState<boolean>(false);
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    if (sortState && sortState.length > 0) {
      setChosenCol(sortState[0].id);
      setIsDesc(sortState[0].desc);
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [sortState]);

  const renderButton = () => {
    if (!enable) {
      return (
        <Badge icon={SortIcon}>
          <Text color="gray.800" fontSize={"sm"} py={0.5}>
            Sort
          </Text>
        </Badge>
      );
    } else {
      return (
        <Badge
          icon={isDesc ? ArrowDownIcon : ArrowUpIcon}
          iconColor={"echoBlue.500"}
          bgColor={"echoBlueAlpha.100"}
          borderColor={"echoBlueAlpha.300"}
        >
          <Text color="gray.800" fontSize={"sm"} py={0.5}>
            <Box as="span" color={"gray.500"}>
              Sorted by
            </Box>{" "}
            {table.getAllLeafColumns().find((col) => col.id === chosenCol)?.columnDef.header as string}
          </Text>
        </Badge>
      );
    }
  };

  return (
    <Box pos={"relative"}>
      <Menu gutter={4} isLazy={true} autoSelect={false}>
        <MenuButton
          borderRadius={6}
          _hover={{ bgColor: "gray.50" }}
          _active={{ bgColor: "gray.100" }}
          onClick={(e) => {
            if (enable) {
              e.preventDefault();
              onToggle();
            }
          }}
        >
          {renderButton()}
        </MenuButton>
        <MenuList p={2} fontSize={"sm"}>
          {table.getAllLeafColumns().map((col) => {
            if (col.getCanSort() && col.getIsVisible() && col.id !== "_table-options_") {
              return (
                <MenuItem
                  display={"flex"}
                  gap={2}
                  key={col.id}
                  onClick={() => {
                    setChosenCol(col.id);
                    dispatch(setSortState([{ id: col.id, desc: isDesc }]));
                    dispatch(updateContactTable({ ...meta, sortState: [{ id: col.id, desc: isDesc }] }));
                    setEnable(true);
                    onToggle();
                  }}
                >
                  {renderIcon(col.columnDef.meta?.type, col.id)}
                  {col.columnDef.header as ReactNode}
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Menu>
      <Popover gutter={4} isOpen={isOpen} onClose={onClose} placement="bottom">
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>
        <PopoverContent w={"fit-content"} left={"0.8rem"}>
          <PopoverBody>
            <HStack minW={300}>
              <Select
                fontSize={"sm"}
                size={"sm"}
                color={"gray.800"}
                borderRadius={4}
                outline={"1px"}
                minW={"fit-content"}
                _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                value={chosenCol}
                onChange={(e) => {
                  setChosenCol(e.target.value);
                  dispatch(setSortState([{ id: e.target.value, desc: isDesc }]));
                  dispatch(updateContactTable({ ...meta, sortState: [{ id: e.target.value, desc: isDesc }] }));
                }}
              >
                {table.getAllLeafColumns().map((col) => {
                  if (col.getCanSort() && col.getIsVisible() && col.id !== "_table-options_") {
                    return (
                      <option value={col.id} key={col.id}>
                        {col.columnDef.header as ReactNode}
                      </option>
                    );
                  }
                })}
              </Select>
              <Select
                fontSize={"sm"}
                size={"sm"}
                color={"gray.800"}
                borderRadius={4}
                outline={"1px"}
                minW={"fit-content"}
                _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                value={isDesc ? "desc" : "asc"}
                onChange={(e) => {
                  setIsDesc(e.target.value === "desc");
                  dispatch(setSortState([{ id: chosenCol, desc: e.target.value === "desc" }]));
                  dispatch(
                    updateContactTable({ ...meta, sortState: [{ id: chosenCol, desc: e.target.value === "desc" }] })
                  );
                }}
              >
                <option value={"asc"}>Ascending</option>
                <option value={"desc"}>Descending</option>
              </Select>
              <IconButton
                icon={CloseIcon}
                onClick={() => {
                  setEnable(false);
                  dispatch(setSortState([]));
                  dispatch(updateContactTable({ ...meta, sortState: [] }));

                  onClose();
                }}
              />
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default Sort;
