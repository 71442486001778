import { useState, useEffect } from 'react';

export const useIsOverflow = (ref: React.RefObject<HTMLElement>): boolean => {
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (ref.current) {
        setIsOverflow(ref.current.scrollWidth > ref.current.clientWidth);
      }
    };

    const resizeObserver = new ResizeObserver(checkOverflow);

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [ref]);

  return isOverflow;
};
