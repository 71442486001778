import { Box, BoxProps, Center, Divider, HStack, Stack, Text } from "@chakra-ui/react";
import Tag from "components/base/Tag";
import { renderIcon } from "components/contacts/table/ThMenu";
import { Meeting, MeetingTemplate } from "components/meetings/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useRef, useState } from "react";
import { getContactTable } from "reducers/contact-table/contactTableReducer";
import { formatDate, formatMeetingTime } from "utils/common";
import { ReactComponent as MeetingPlanIcon } from "assets/icons/meeting-plan.svg";
import { ReactComponent as PeopleTeamIcon } from "assets/icons/people-team.svg";
import { ReactComponent as TagIcon } from "assets/icons/tag.svg";
import ContactDetails from "components/contact/ContactDetails";
import Icon from "components/base/Icon";

interface MeetingDetailsProps extends BoxProps {
  meeting: Meeting | undefined;
}

const MeetingDetails: React.FC<MeetingDetailsProps> = ({ meeting }) => {
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const workspaceState = useAppSelector((state) => state.workspace);
  const contactState = useAppSelector((state) => state.contact);
  const contactTableState = useAppSelector((state) => state.contactTable);
  const meetingPlanState = useAppSelector((state) => state.meetingTemplate);
  const tagState = useAppSelector((state) => state.tag);

  const [maxHeight, setMaxHeight] = useState("80vh");

  useEffect(() => {
    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);
    return () => window.removeEventListener("resize", updateMaxHeight);
  }, []);

  useEffect(() => {
    updateMaxHeight();
  }, [containerRef.current]);

  useEffect(() => {
    if (!contactTableState.status["primary"]) {
      dispatch(getContactTable());
    }
  }, [contactTableState]);

  const updateMaxHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current?.getBoundingClientRect().y || 0;
      const newMaxHeight = `calc(100vh - ${containerHeight}px - 20px)`;
      setMaxHeight(newMaxHeight);
    }
  };

  const renderParticipants = () => {
    return (
      <>
        {meeting?.participants.workspace.map((participant) => {
          const user = workspaceState.users.find((user) => user.id === participant);
          if (user) return <Tag key={user.id} label={user.first_name + " " + user.last_name} disableHover />;
        })}
        {meeting?.participants.contacts.map((contactId) => {
          const contact = contactState.contacts.find((contact) => Number(contact.id) === contactId);
          if (contact) return <Tag key={contact.id} label={contact.name} disableHover />;
        })}
        {meeting?.participants.outsiders.map((outsider) => (
          <Tag
            key={outsider.name + outsider.email}
            label={outsider.name ? outsider.name : outsider.email}
            disableHover
          />
        ))}
      </>
    );
  };

  const renderTags = () => {
    return meeting?.tags.map((tagId) => {
      const tag = tagState.payload.find((t) => t.id === tagId);
      if (tag) return <Tag key={tagId} label={tag.value} color={tag.meta.color} />;
    });
  };

  if (meeting) {
    return (
      <Stack
        ref={containerRef}
        className="meeting-details"
        fontWeight={500}
        color={"gray.500"}
        sx={{ "& svg path": { fill: "gray.500" } }}
        whiteSpace={"nowrap"}
        gap={4}
        p={4}
        fontSize={"sm"}
        overflowY={"auto"}
        maxH={maxHeight}
      >
        <Box mb={2}>
          <Text color={"gray.800"}>
            {formatDate(meeting.start_time)} • {formatMeetingTime(meeting.start_time, meeting.end_time)}
          </Text>
        </Box>
        <HStack>
          <Icon as={MeetingPlanIcon} />
          <Text>Meeting plan:</Text>
          <Text color={"gray.800"}>{meeting.meeting_plan?.name}</Text>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <HStack>
            <Icon as={PeopleTeamIcon} />
            <Text>participants:</Text>
          </HStack>
          <Stack>{renderParticipants()}</Stack>
        </HStack>
        <HStack>
          <Icon as={TagIcon} />
          <Text>Tags:</Text>
          {renderTags()}
        </HStack>
        {meeting.participants.contacts.map((contactId) => {
          const contact = contactState.contacts.find((contact) => Number(contact.id) === contactId);
          if (contact)
            return (
              <Box key={contact.id}>
                <Divider />
                <ContactDetails contact={contact} />
              </Box>
            );
        })}
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default MeetingDetails;
