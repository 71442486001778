import { useEffect, useRef, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { MenuItem, MenuSection, sections } from "utils/menuSections";
import ListItem from "../ListItem";
import { Box, HStack, Heading, Input, InputGroup, InputLeftElement, InputRightElement, Text } from "@chakra-ui/react";
import { ReactComponent as KeyCommandIcon } from "assets/icons/key-command.svg";
import LetterSquare from "../base/LetterSquare";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import Icon from "components/base/Icon";
import IconButton from "components/base/IconButton";
import { ReactComponent as PanelLeftIcon } from "assets/icons/panel-left.svg";
import { updateUserInfo } from "reducers/user/userReducer";

const isMacOs = /Mac|iPod|iPhone|iPad/.test(navigator.userAgent);

const Menu = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const userState = useAppSelector((state) => state.user);
  const inputRef = useRef<HTMLInputElement>(null);

  const [collapsed, setCollapsed] = useState(userState.user?.meta.collapsed ? true : false);

  useEffect(() => {
    if (userState.status["primary"] === "fulfilled") {
      if (userState.user?.meta) {
        setCollapsed(userState.user.meta.collapsed as boolean);
      }
    }
  }, [userState.status["primary"]]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (((isMacOs && event.metaKey) || (!isMacOs && event.ctrlKey)) && event.key === "k") {
        event.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    //navigate to homepage after login
    if (userState.status["connect"] === "fulfilled") navigate("/");
  }, []);

  const renderMenuLink = (item: MenuItem) => {
    if (item.external) {
      return (
        <a href={item.path} target="_blank" key={item.text} className="menu__link">
          <ListItem text={item.text} icon={item.icon} soon={item.soon} active={false} />
        </a>
      );
    }
    if (!item.soon) {
      return (
        <NavLink to={item.path} key={item.text} className="menu__link">
          {({ isActive }) => (
            <ListItem
              text={collapsed ? "" : item.text}
              icon={item.icon}
              soon={item.soon}
              active={isActive || (item.home && location.pathname === "/")}
              iconOnly={collapsed}
            />
          )}
        </NavLink>
      );
    } else {
      return <ListItem text={item.text} icon={item.icon} soon={item.soon} active={false} key={item.text} />;
    }
  };

  const renderSections = (bottom: boolean) => {
    return sections.map((section: MenuSection) => {
      if (section.items.length > 0) {
        if (bottom && section.position === "bottom") {
          return (
            <div className="menu-section" key={section.items[0].text}>
              {section.items.map((item) => {
                return renderMenuLink(item);
              })}
            </div>
          );
        } else if (!bottom && section.position === "top") {
          return (
            <div className="menu-section" key={section.items[0].text}>
              {section.items.map((item) => {
                return renderMenuLink(item);
              })}
            </div>
          );
        }
      }
    });
  };

  return (
    <>
      <Box className={`menu${collapsed ? " collapsed" : ""}`}>
        <HStack flexDir={collapsed ? "column" : "row"} mb={4}>
          <LetterSquare letter="E" size={5} />
          {collapsed ? (
            <></>
          ) : (
            <Text size="md" fontWeight={500} flex={1}>
              Echo
            </Text>
          )}
          <IconButton
            icon={PanelLeftIcon}
            color={"gray.300"}
            iconSize={"lg"}
            onClick={() => {
              dispatch(updateUserInfo({ meta: { ...userState.user?.meta, collapsed: !collapsed } }));
              setCollapsed((prev) => !prev);
            }}
          />
        </HStack>
        {/* <Box mt="6" mb="2">
          {collapsed ? (
            <></>
          ) : (
            <InputGroup size={"sm"} fontWeight={700}>
              <InputLeftElement>
                <Icon as={SearchIcon} fontSize={"md"} />
              </InputLeftElement>
              <Input
                ref={inputRef}
                _hover={{ bg: "gray.50" }}
                _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                borderColor={"gray.100"}
                borderRadius={4}
                placeholder="Search..."
                color={"gray.900"}
                outline={"1px"}
              />
              <InputRightElement
                className="hide-on-focus"
                // pr={isMacOs ? 3 : 7}
                fontSize={"xs"}
                px={1}
                color="gray.500"
                h={"fit-content"}
                top={"6px"}
                right={"8px"}
                borderRadius={3}
                border={"1px"}
                borderColor={"gray.100"}
                w={"fit-content"}
                gap={1}
                boxShadow={"0px 0.5px 0px 0px #EEF2F8"}
                bgColor={"white"}
              >
                {isMacOs ? <Icon as={KeyCommandIcon} fontSize={"sm"} color={"gray.500"} /> : "Ctrl+"}K
              </InputRightElement>
            </InputGroup>
          )}
        </Box> */}
        <div className="menu-sections top">{renderSections(false)}</div>
        <div className="menu-sections bottom">{renderSections(true)}</div>
      </Box>
      <Outlet />
    </>
  );
};

export default Menu;
