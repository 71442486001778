import { Box, Input, InputProps, ResponsiveValue, Tooltip } from "@chakra-ui/react";
import { setIn } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as CSS from "csstype";

interface DynamicInputProps extends InputProps {
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  placeholderColor?: ResponsiveValue<CSS.Property.Color>;
  autoFocus?: boolean;
  onEnter?: () => void;
  maxWidthInPx?: number;
}

const DynamicInput: React.FC<DynamicInputProps> = ({
  value,
  placeholder,
  placeholderColor,
  onChange,
  autoFocus,
  onEnter,
  maxWidthInPx,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const spanRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (spanRef.current && maxWidthInPx) {
      const spanWidth = spanRef.current.offsetWidth;
      setIsOverflowing(spanWidth > maxWidthInPx);
    }
  }, [value, maxWidthInPx]);

  useEffect(() => {
    if (inputRef.current && spanRef.current) {
      const computedStyle = window.getComputedStyle(inputRef.current);

      // Font styles
      spanRef.current.style.fontSize = computedStyle.fontSize;
      spanRef.current.style.fontFamily = computedStyle.fontFamily;
      spanRef.current.style.fontWeight = computedStyle.fontWeight;
      spanRef.current.style.fontStyle = computedStyle.fontStyle;
      spanRef.current.style.lineHeight = computedStyle.lineHeight;

      // Textarea styles
      spanRef.current.style.paddingTop = computedStyle.paddingTop;
      spanRef.current.style.paddingRight = computedStyle.paddingRight;
      spanRef.current.style.paddingBottom = computedStyle.paddingBottom;
      spanRef.current.style.paddingLeft = computedStyle.paddingLeft;
      spanRef.current.style.borderWidth = computedStyle.borderWidth;
      spanRef.current.style.borderStyle = computedStyle.borderStyle;
    }
  }, [inputRef.current, spanRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current && spanRef.current) {
        const width = spanRef.current.getBoundingClientRect().width;
        const height = spanRef.current.getBoundingClientRect().height;
        inputRef.current.style.width = width + 12 + "px";
        inputRef.current.style.height = height + 4 + "px";

        if (inputRef.current.parentElement) {
          const parentWidth = inputRef.current.parentElement.getBoundingClientRect().width;

          if (parentWidth > 0 && width > 0) {
            const maxWidth = Math.min(parentWidth, maxWidthInPx ? maxWidthInPx : 99999999999);
            inputRef.current.style.maxWidth = maxWidth + "px";
          }
        }
      }
    }, 1);
  }, [value, inputRef.current, spanRef.current]);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [inputRef.current]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      inputRef.current?.blur();
      onEnter && onEnter();
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Tooltip hasArrow label={value} isDisabled={!isOverflowing}>
        <Input
          className="dynamic-input"
          ref={inputRef}
          value={value}
          onChange={onChange}
          border={0}
          borderRadius={0}
          _focusVisible={{ border: 0 }}
          placeholder={placeholder}
          px={0}
          textOverflow={"ellipsis"}
          {...(maxWidthInPx ? { maxW: maxWidthInPx + "px" } : {})}
          {...rest}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          sx={{
            ...rest.sx,
            ...{ "&::placeholder": placeholderColor ? { color: placeholderColor } : { color: "gray.300" } },
          }}
        />
      </Tooltip>
      <Box
        as="span"
        ref={spanRef}
        w={"fit-content"}
        whiteSpace={"nowrap"}
        opacity={0}
        visibility={"hidden"}
        pos={"absolute"}
        top={0}
        right={0}
        minW={10}
      >
        {value ? value : placeholder ? placeholder : "---"}
      </Box>
    </>
  );
};

export default DynamicInput;
