import {
  Box,
  Divider,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import { Contact } from "../types";
import { ColumnDef, Header, Table as ReactTable } from "@tanstack/react-table";
import { MouseTouchEvent } from "types/types";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as ArrowDownIcon } from "assets/icons/arrow-down.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { Meeting } from "../../meetings/types";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as DateIcon } from "assets/icons/calendar-date.svg";
import { ReactComponent as MultiPersonIcon } from "assets/icons/people-team.svg";
import { ReactComponent as NumberIcon } from "assets/icons/number-symbol.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as MultiSelectIcon } from "assets/icons/text-bullet-list.svg";
import { ReactComponent as SelectIcon } from "assets/icons/arrow-circle-down.svg";
import { ReactComponent as StatusIcon } from "assets/icons/status.svg";
import { ReactComponent as UrlIcon } from "assets/icons/link.svg";
import { ReactComponent as HideIcon } from "assets/icons/hide.svg";
import { ReactComponent as DuplicateIcon } from "assets/icons/duplicate.svg";
import { ReactComponent as MailIcon } from "assets/icons/mail.svg";
import { ReactComponent as BuildingIcon } from "assets/icons/building.svg";
import { ReactComponent as TextUppercaseIcon } from "assets/icons/text-uppercase.svg";
import { COL_SIZE_PAD } from "./ContactTable";
import EnterInput from "../../base/EnterInput";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { setSortState, updateContactTable, updateContactTableColumn } from "reducers/contact-table/contactTableReducer";
import { ColumnType } from "reducers/contact-table/contactTableTypes";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import Icon from "components/base/Icon";

export function toggleStringInArray(inputArray: string[], item: string) {
  let array = inputArray ? [...inputArray] : [];
  const index = array.indexOf(item);
  if (index === -1) {
    array.push(item);
  } else {
    array.splice(index, 1);
  }
  return array;
}

export const renderIcon = (type: ColumnType | undefined, primaryCol: string) => {
  if (["name", "email", "company"].includes(primaryCol)) {
    switch (primaryCol) {
      case "name":
        return <Icon as={TextUppercaseIcon} />;
      case "email":
        return <Icon as={MailIcon} />;
      case "company":
        return <Icon as={BuildingIcon} />;
      default:
        break;
    }
  }

  switch (type) {
    case "text":
      return <Icon as={TextIcon} />;
    case "date":
      return <Icon as={DateIcon} />;
    case "multi-person":
      return <Icon as={MultiPersonIcon} />;
    case "multi-select":
      return <Icon as={MultiSelectIcon} />;
    case "number":
      return <Icon as={NumberIcon} />;
    case "person":
      return <Icon as={PersonIcon} />;
    case "select":
      return <Icon as={SelectIcon} />;
    case "status":
      return <Icon as={StatusIcon} />;
    case "url":
      return <Icon as={UrlIcon} />;

    default:
      return <></>;
  }
};

interface ThMenuProps {
  table: ReactTable<Contact>;
  header: Header<Contact, unknown>;
  index: number;
  handleColResize: (event: MouseTouchEvent, header: Header<Contact, unknown>, index: number) => void;
  onEdit: () => void;
  onDelete: () => void;
}

const ThMenu: React.FC<ThMenuProps> = ({ header, handleColResize, index, table, onEdit, onDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: header.column.id,
  });

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: "relative",
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: transition,
    whiteSpace: "nowrap",
    // width: header.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  };

  const allowFiltering = header.column.columnDef.meta?.type !== "date";
  const canDelete = header.column.columnDef.id
    ? !["name", "company", "email"].includes(header.column.columnDef.id)
    : true;
  const canHide = header.column.columnDef.id !== "name";
  const canEditName = header.column.columnDef.id
    ? !["name", "company", "email"].includes(header.column.columnDef.id)
    : true;

  const dispatch = useAppDispatch();

  const meta = useAppSelector((state) => state.contactTable.contactTable.meta);

  const [headerValue, setHeaderValue] = useState(header.column.columnDef.header);
  const [menuListWidth, setMenuListWidth] = useState<number>(header.getSize() + COL_SIZE_PAD + 1);
  const menuListRef = useRef<HTMLDivElement>(null);

  const createFilter = () => {
    if (allowFiltering && table.options.meta?.createFilter) table.options.meta.createFilter(header.id);
  };

  useEffect(() => {
    setHeaderValue(header.column.columnDef.header);
  }, [header.column.columnDef.header]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setMenuListWidth(Math.max(header.getSize() + COL_SIZE_PAD + 1, 150));
    });

    if (menuListRef.current?.parentElement) {
      resizeObserver.observe(menuListRef.current.parentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const [dragStartTimer, setDragStartTimer] = useState<number | null>(null);
  const dragThreshold = 100;

  const onMouseDown = (event: React.MouseEvent, listeners: SyntheticListenerMap | undefined) => {
    const timer = window.setTimeout(() => {
      setDragStartTimer(null);
      listeners?.onPointerDown(event);
    }, dragThreshold);
    setDragStartTimer(timer);
  };

  const onMouseUp = () => {
    if (dragStartTimer !== null) {
      clearTimeout(dragStartTimer);
      setDragStartTimer(null);
    }
  };

  return (
    <>
      <Menu gutter={0} isLazy={true} autoSelect={false}>
        <MenuButton
          as="button"
          className="th-menu__button"
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          onPointerDown={(event) => {
            onMouseDown(event, listeners);
          }}
          onPointerUp={(event) => {
            onMouseUp();
          }}
          style={style}
        >
          <HStack>
            {renderIcon(header.column.columnDef.meta?.type, header.column.id)}
            <Box as="span">{headerValue as ReactNode}</Box>
          </HStack>
          {header.column.getIsSorted() === "asc" ? (
            <Icon as={ArrowUpIcon} fontSize={"sm"} ml="2" display={"flex"} />
          ) : header.column.getIsSorted() === "desc" ? (
            <Icon as={ArrowDownIcon} fontSize={"sm"} ml="2" display={"flex"} />
          ) : (
            ""
          )}
        </MenuButton>
        <MenuList
          ref={menuListRef}
          px={"0.5rem"}
          borderColor={"gray.100"}
          borderTopRadius={0}
          w={menuListWidth}
          minW={menuListWidth}
          zIndex={30}
        >
          {canEditName && (
            <EnterInput
              value={headerValue}
              onChange={(e) => setHeaderValue(e.target.value)}
              onBlur={(e, isEnterKey) => {
                if (isEnterKey) {
                  if (header.column.columnDef && header.column.columnDef.header !== e.target.value)
                    if (table.options.meta?.updateColumn)
                      table.options.meta.updateColumn({ ...header.column.columnDef, header: e.target.value });
                  dispatch(updateContactTableColumn({ accessor_key: header.id, header: e.target.value }));
                }
              }}
            />
          )}
          {canEditName && (
            <>
              <MenuItem onClick={onEdit}>
                <Icon as={EditIcon} fontSize={"md"} mr="2" />
                Edit property
              </MenuItem>
              <MenuDivider />
            </>
          )}
          {header.column.getCanSort() && (
            <>
              <MenuItem
                onClick={() => {
                  dispatch(setSortState([{ id: header.column.id, desc: false }]));
                  dispatch(
                    updateContactTable({
                      ...meta,
                      sortState: [{ id: header.column.id, desc: false }],
                    })
                  );
                }}
              >
                <Icon as={ArrowUpIcon} fontSize={"md"} mr="2" />
                Sort ascending
              </MenuItem>
              <MenuItem
                onClick={() => {
                  dispatch(setSortState([{ id: header.column.id, desc: true }]));
                  dispatch(
                    updateContactTable({
                      ...meta,
                      sortState: [{ id: header.column.id, desc: true }],
                    })
                  );
                }}
              >
                <Icon as={ArrowDownIcon} fontSize={"md"} mr="2" />
                Sort descending
              </MenuItem>
            </>
          )}
          {/* {allowFiltering && (
            <MenuItem onClick={createFilter}>
              <Icon as={FilterList} fontSize={"md"} mr="2" />
              Filter
            </MenuItem>
          )} */}
          {canHide && (
            <>
              <MenuDivider />
              {/* <MenuItem color={"gray.600"} fontWeight={500}>
                <Icon as={DuplicateIcon} fontSize={"md"} mr="2" color={"gray.600"} />
                Duplicate property
              </MenuItem> */}
              <MenuItem
                color={"gray.600"}
                fontWeight={500}
                onClick={(e) => {
                  if (header.id === "email" || header.id === "company") {
                    dispatch(
                      updateContactTable({
                        ...meta,
                        primaryColumnsHiddenState: toggleStringInArray(meta.primaryColumnsHiddenState, header.id),
                      })
                    );
                  } else {
                    dispatch(updateContactTableColumn({ accessor_key: header.id, hidden: true }));
                  }
                  header.column.getToggleVisibilityHandler()(e);
                }}
              >
                <Icon as={HideIcon} fontSize={"md"} mr="2" color={"gray.600"} />
                Hide in view
              </MenuItem>
            </>
          )}
          {canDelete && (
            <>
              <MenuItem onClick={onDelete} color={"gray.600"} fontWeight={500}>
                <Icon as={DeleteIcon} fontSize={"md"} mr="2" color={"gray.600"} />
                Delete property
              </MenuItem>
            </>
          )}
        </MenuList>
      </Menu>
      <Box
        onMouseDown={(event) => {
          handleColResize(event, header, index);
        }}
        onTouchStart={(event) => {
          handleColResize(event, header, index);
        }}
        className={`${
          header.column.getCanResize() ? `t-resizer ${header.column.getIsResizing() ? "resizing" : ""}` : ""
        }`}
      />
    </>
  );
};

export default ThMenu;
