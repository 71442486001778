import { Header, Table as ReactTable, flexRender } from "@tanstack/react-table";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { MouseTouchEvent } from "types/types";
import { Contact } from "../types";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  MenuDivider,
  Icon,
  BoxProps,
  Button,
} from "@chakra-ui/react";
import ThMenu, { renderIcon, toggleStringInArray } from "./ThMenu";
import NewColDrawer from "./drawer/NewColDrawer";
import EditColDrawer from "./drawer/EditColDrawer";
import { ColumnType, CellTypesWithOptions } from "reducers/contact-table/contactTableTypes";
import DeleteColModal from "./drawer/DeleteColModal";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more-horizontal.svg";
import { ReactComponent as TextIcon } from "assets/icons/text.svg";
import { ReactComponent as DateIcon } from "assets/icons/calendar-date.svg";
import { ReactComponent as MultiPersonIcon } from "assets/icons/people-team.svg";
import { ReactComponent as NumberIcon } from "assets/icons/number-symbol.svg";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { ReactComponent as MultiSelectIcon } from "assets/icons/text-bullet-list.svg";
import { ReactComponent as SelectIcon } from "assets/icons/arrow-circle-down.svg";
import { ReactComponent as StatusIcon } from "assets/icons/status.svg";
import { ReactComponent as UrlIcon } from "assets/icons/link.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { updateContactTable, updateContactTableColumn } from "reducers/contact-table/contactTableReducer";
import { Link } from "react-router-dom";
import { SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import DragAlongTd from "./DragAlongTd";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";

export const valueTextMap = {
  text: "Text",
  number: "Number",
  status: "Status",
  select: "Select",
  "multi-select": "Multi-select",
  person: "Person",
  "multi-person": "Multi-person",
  date: "Date",
  url: "URL",
};

export const COL_SIZE_PAD = 30;

interface ContactTableProps extends BoxProps {
  table: ReactTable<Contact>;
}

const ContactTable: React.FC<ContactTableProps> = ({ table }) => {
  const dispatch = useAppDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const meta = useAppSelector((state) => state.contactTable.contactTable.meta);

  const [resizingIndex, setResizingIndex] = useState(-1);
  const [newColOpen, setNewColOpen] = useState(false);
  const [editColOpen, setEditColOpen] = useState(false);
  const [colInEdit, setColInEdit] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newColType, setNewColType] = useState<ColumnType>("text");
  const [maxHeight, setMaxHeight] = useState("80vh");
  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);
    return () => window.removeEventListener("resize", updateMaxHeight);
  }, []);

  const toggleBackToTopVisibility = () => {
    if (containerRef.current!.scrollTop > 20) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    updateMaxHeight();
    if (containerRef.current) {
      containerRef.current.onscroll = () => {
        toggleBackToTopVisibility();
      };
    }
  }, [containerRef.current]);

  const updateMaxHeight = () => {
    if (containerRef.current) {
      const containerHeight = containerRef.current?.getBoundingClientRect().y || 0;
      const newMaxHeight = `calc(100vh - ${containerHeight}px)`;
      setMaxHeight(newMaxHeight);
    }
  };

  useEffect(() => {
    const handleMouseUp = () => {
      setResizingIndex(-1);
    };

    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("touchup", handleMouseUp);
    };
  }, []);

  const addRow = () => {
    if (table.options.meta?.addNewRow) {
      table.options.meta.addNewRow();
    }
  };

  const handleColResize = (event: MouseTouchEvent, header: Header<Contact, unknown>, index: number) => {
    setResizingIndex(index);
    const handler = header.getResizeHandler();
    handler(event);
  };

  const renderTh = (header: Header<Contact, unknown>, index: number) => {
    switch (header.id) {
      case "_table-options_":
        return (
          <HStack cursor={"default"} gap={0}>
            <Menu size={"sm"} isLazy={true} gutter={0}>
              <MenuButton
                borderRight={"1px"}
                borderColor={"gray.100"}
                type="button"
                w={"2.5rem"}
                _hover={{ bgColor: "gray.50" }}
                sx={{ "& path": { fill: "gray.400" }, "&:hover path": { fill: "gray.700" } }}
              >
                <Icon as={AddIcon} p={3} fontSize={"40px"} />
              </MenuButton>
              <MenuList
                minW={48}
                borderTopEndRadius={0}
                p={3}
                sx={{
                  "& button": { borderRadius: 8, gap: 2, fontSize: "sm", fontWeight: 500, color: "gray.600" },
                  "& button svg": { fontSize: "md" },
                  "& button svg path": { fill: "gray.600" },
                }}
              >
                <Text fontSize="sm" fontWeight={500} color="gray.800" ml={2}>
                  Add a new column
                </Text>
                <MenuDivider />
                <MenuItem
                  onClick={() => {
                    setNewColType("text");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={TextIcon} />
                  Text
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("number");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={NumberIcon} />
                  Number
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("select");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={SelectIcon} />
                  Select
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("multi-select");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={MultiSelectIcon} />
                  Multi-select
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("status");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={StatusIcon} />
                  Status
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("date");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={DateIcon} />
                  Date
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("person");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={PersonIcon} />
                  Person
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("multi-person");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={MultiPersonIcon} />
                  Multi-person
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setNewColType("url");
                    setNewColOpen(true);
                  }}
                >
                  <Icon as={UrlIcon} />
                  URL
                </MenuItem>
              </MenuList>
            </Menu>
            <Menu size={"sm"} isLazy={true} gutter={0}>
              <MenuButton
                type="button"
                w={"2.5rem"}
                _hover={{ bgColor: "gray.50" }}
                sx={{ "& path": { fill: "gray.400" }, "&:hover path": { fill: "gray.700" } }}
              >
                <Icon as={MoreIcon} p={3} fontSize={"40px"} />
              </MenuButton>
              <MenuList
                minW={48}
                borderTopEndRadius={0}
                p={3}
                sx={{
                  "& button": { borderRadius: 8, gap: 2, fontSize: "sm", fontWeight: 500, color: "gray.600" },
                  "& button svg": { fontSize: "md" },
                  "& button svg path": { fill: "gray.600" },
                }}
              >
                <Text fontSize="sm" fontWeight={500} color="gray.800" ml={2}>
                  show hidden
                </Text>
                <MenuDivider />
                {table.getAllLeafColumns().map((col) => {
                  if (!col.getIsVisible()) {
                    return (
                      <MenuItem
                        key={col.id}
                        onClick={() => {
                          if (col.id === "email" || col.id === "company") {
                            dispatch(
                              updateContactTable({
                                ...meta,
                                primaryColumnsHiddenState: toggleStringInArray(meta.primaryColumnsHiddenState, col.id),
                              })
                            );
                          } else {
                            dispatch(updateContactTableColumn({ accessor_key: col.id, hidden: false }));
                          }
                          col.toggleVisibility();
                        }}
                      >
                        {renderIcon(col.columnDef.meta?.type, col.id)}
                        {col.columnDef.header as ReactNode}
                      </MenuItem>
                    );
                  }
                })}
              </MenuList>
            </Menu>
          </HStack>
        );

      case "_select_":
        return flexRender(header.column.columnDef.header, header.getContext());

      default:
        return (
          <ThMenu
            handleColResize={(event) => handleColResize(event, header, index)}
            header={header}
            index={index}
            table={table}
            onEdit={() => {
              setColInEdit(header.id);
              setEditColOpen(true);
            }}
            onDelete={() => {
              setColInEdit(header.id);
              setDeleteModalOpen(true);
            }}
          />
        );
    }
  };

  return (
    <>
      <TableContainer
        pos={"relative"}
        bg="white"
        className="contact-table"
        ref={containerRef}
        overflowY={"auto"}
        overflowX={"auto"}
        maxH={maxHeight}
        scrollBehavior={"smooth"}
      >
        <Table size={"sm"} className="" style={{ width: table.getTotalSize() }}>
          <Thead className="">
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr className="" key={headerGroup.id}>
                <SortableContext items={table.getState().columnOrder} strategy={horizontalListSortingStrategy}>
                  {headerGroup.headers.map((header, index) => (
                    <Th
                      p={0}
                      _hover={header.id === "_table-options_" ? {} : { bgColor: "gray.50" }}
                      color={"gray.800"}
                      fontSize={"xs"}
                      className={`col-${index} ${header.id}`}
                      style={{ width: header.getSize() + COL_SIZE_PAD, minWidth: header.getSize() + COL_SIZE_PAD }}
                      key={header.id}
                    >
                      {renderTh(header, index)}
                    </Th>
                  ))}
                </SortableContext>
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                borderBottom={"1px"}
                borderColor={"gray.100"}
                className=""
                key={row.original.id}
                id={row.original.id.toString()}
              >
                {row.getVisibleCells().map((cell, index) => (
                  <SortableContext
                    key={cell.id}
                    items={table.getState().columnOrder}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DragAlongTd row={row} cell={cell} index={index} resizingIndex={resizingIndex} />
                  </SortableContext>
                ))}
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id} onClick={addRow}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id} fontSize={"sm"}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </TableContainer>
      <Button
        id="back-to-top-btn"
        display={showBackToTop ? "flex" : "none"}
        pos={"fixed"}
        bottom={12}
        right={12}
        w={10}
        h={10}
        p={0}
        borderRadius={"50%"}
        bgColor={"echoBlue"}
        color={"white"}
        boxShadow={"0 2px 5px rgba(0,0,0,0.3)"}
        onClick={() => {
          if (containerRef.current) containerRef.current.scrollTo({ top: 0 });
        }}
      >
        <Icon as={ArrowUpIcon} fontSize={"lg"} sx={{ "& path": { fill: "gray.700" } }} />
      </Button>

      <NewColDrawer
        key={newColOpen ? "newColDrawerOpen" : "newColDrawerClosed"} //re-render each time
        table={table}
        isOpen={newColOpen}
        defaultType={newColType}
        onClose={(colId?: string, type?: ColumnType) => {
          // if (colId && type && CellTypesWithOptions.includes(type)) {
          //   setColInEdit(colId);
          //   setEditColOpen(true);
          // }
          setNewColOpen(false);
        }}
      />
      <EditColDrawer
        key={editColOpen ? "editColDrawerOpen" : "editColDrawerClosed"} //re-render each time
        colId={colInEdit}
        table={table}
        isOpen={editColOpen}
        onClose={() => {
          setColInEdit("");
          setEditColOpen(false);
        }}
      />
      <DeleteColModal
        table={table}
        onClose={() => {
          setColInEdit("");
          setDeleteModalOpen(false);
        }}
        colId={colInEdit}
        isOpen={deleteModalOpen}
      />
    </>
  );
};

export default ContactTable;
