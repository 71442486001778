import { Box, BoxProps, HStack } from "@chakra-ui/react";
import Tag from "./Tag";
import { ComboOption } from "components/contacts/table/Meta";
import { ComboCellType } from "reducers/contact-table/contactTableTypes";

interface ComboTagsProps extends BoxProps {
  options: ComboOption[];
  selected: ComboCellType[];
  onClose?: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>, option: ComboOption) => void) | undefined;
  disableHover?: boolean;
}

const ComboTags: React.FC<ComboTagsProps> = ({ options, selected, onClose, disableHover }) => {
  return (
    <HStack gap={1} ml={1}>
      {selected?.map((sel) => {
        const option = options.find((option) => option.id === sel.value)!;
        if (!option) return;
        return (
          <Tag
            className="combo-cell__tag"
            cursor={"default"}
            key={option.value}
            label={option.value}
            disableHover={disableHover}
            {...(option.type === "select" || option.type === "multi-select" ? { color: option.color } : {})}
            {...(option.type === "person" || option.type === "multi-person" ? { avatar: option.avatar } : {})}
            {...(onClose ? { onClose: (e) => onClose(e, option) } : {})}
          />
        );
      })}
    </HStack>
  );
};

export default ComboTags;
