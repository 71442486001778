export interface DisplayLink {
  display_name: string;
  link: string;
}

export type Nullable<T> = T | null;

export type MouseTouchEvent = React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>;

export type AvailableColors =
  | "gray"
  | "red"
  | "orange"
  | "green"
  | "teal"
  | "blue"
  | "cyan"
  | "purple"
  | "pink"
  | "darkgray"
  | "brown"
  | "jasmine"
  | "lime";

export const AvailableColors: AvailableColors[] = [
  "gray",
  "darkgray",
  "jasmine",
  "orange",
  "lime",
  "green",
  "teal",
  "blue",
  "cyan",
  "purple",
  "pink",
  "red",
  "brown",
];
