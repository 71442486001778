import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
  HStack,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import Avatar from "components/base/Avatar";
import ComboTags from "components/base/ComboTags";
import Status from "components/base/Status";
import Tag from "components/base/Tag";
import { renderIcon } from "components/contacts/table/ThMenu";
import { Contact } from "components/contacts/types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getContactTable } from "reducers/contact-table/contactTableReducer";
import { BackendColumnDef, ColumnType } from "reducers/contact-table/contactTableTypes";
import { formatDate } from "utils/common";

interface ContactDetailsProps extends BoxProps {
  contact: Contact | undefined;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({ contact }) => {
  const dispatch = useAppDispatch();

  const workspaceState = useAppSelector((state) => state.workspace);
  const contactTableState = useAppSelector((state) => state.contactTable);

  useEffect(() => {
    if (!contactTableState.status["primary"]) {
      dispatch(getContactTable());
    }
  }, [contactTableState]);

  const renderColumnValue = (col: BackendColumnDef, value: any) => {
    switch (col.type) {
      case "number":
      case "text":
      case "url":
        return <Text color={"gray.800"}>{value}</Text>;

      case "status":
        return (
          <Box>
            <Status
              options={col.options.values}
              selected={value}
              h={"initial"}
              px={2}
              borderRadius={4}
              fontSize={"sm"}
              cursor={"default"}
            />
          </Box>
        );
      case "select":
      case "multi-select":
        return (
          <Box>
            <ComboTags options={col.options.values} selected={value} />
          </Box>
        );
      case "person":
      case "multi-person":
        return (
          <Box>
            <ComboTags options={workspaceState.usersOption} selected={value} disableHover />
          </Box>
        );

      case "date":
        return <Text color={"gray.800"}>{formatDate(value)}</Text>;

      default:
        break;
    }
  };

  const renderDynamicColumns = () => {
    const columns = contactTableState.contactTable.columns;
    if (contact && columns) {
      return Object.entries(contact.columns).map(([key, value]: [string, string | string[] | null]) => {
        if ((!Array.isArray(value) && value) || (Array.isArray(value) && value.length > 0)) {
          const col = columns.find((col) => col.accessor_key === key);
          if (col) {
            return (
              <HStack key={col.accessor_key}>
                {renderIcon(col.type, "")}
                <Text>{col.header}:</Text>
                {renderColumnValue(col, value)}
              </HStack>
            );
          }
        }
      });
    }
    return <></>;
  };

  return (
    <Stack
      className="contact-details"
      fontWeight={500}
      color={"gray.500"}
      sx={{ "& svg path": { fill: "gray.500" } }}
      whiteSpace={"nowrap"}
      gap={2}
      p={4}
      fontSize={"sm"}
    >
      <Accordion defaultIndex={[0]} allowToggle sx={{ "& .chakra-collapse": { overflow: "auto !important" } }}>
        <AccordionItem border={0}>
          <AccordionButton>
            <AccordionIcon />
            {contact && (
              <Link to={`/people/${contact.id}`}>
                <Tag label={contact.name} avatar={contact.name} />
              </Link>
            )}
          </AccordionButton>
          <AccordionPanel>
            <Stack gap={1}>
              <HStack>
                {renderIcon("text", "company")}
                <Text>Company:</Text>
                <Text color={"gray.800"}>{contact?.company}</Text>
              </HStack>
              <HStack>
                {renderIcon("text", "email")}
                <Text>Email:</Text>
                <Text color={"gray.800"}>{contact?.email}</Text>
              </HStack>
              {renderDynamicColumns()}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Stack>
  );
};

export default ContactDetails;
