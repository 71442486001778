import {
  Box,
  BoxProps,
  Center,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { ReactComponent as MoreVerticalIcon } from "assets/icons/more-vertical.svg";

interface MeetingPlanSectionMenuProps extends Omit<BoxProps, "pos"> {
  pos: "sole" | "top" | "bottom" | "middle";
  onOrderChange: (direction: "up" | "down") => void;
  onDelete: () => void;
}

const MeetingPlanSectionMenu: React.FC<MeetingPlanSectionMenuProps> = ({ pos, onOrderChange, onDelete, ...rest }) => {
  const { onToggle, isOpen, onClose } = useDisclosure();

  return (
    <Box
      className="section-menu"
      display={"flex"}
      p={0.5}
      borderRadius="50%"
      _hover={{ bgColor: "gray.100" }}
      {...rest}
    >
      <Menu isOpen={isOpen} onClose={onClose} gutter={4}>
        <MenuButton
          as={"div"}
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
        >
          <Center>
            <Icon
              className="section-menu-icon"
              h={6}
              w={6}
              as={MoreVerticalIcon}
              opacity={0}
              visibility="hidden"
              p={0.5}
              sx={{ "& path": { fill: "gray.500" }, ...(isOpen ? { opacity: 1, visibility: "visible" } : {}) }}
            />
          </Center>
        </MenuButton>
        <Portal>
          <MenuList fontSize={"sm"} minW={36} py={1}>
            <MenuItem
              isDisabled={pos === "top" || pos === "sole"}
              color={"gray.600"}
              fontWeight={500}
              onClick={(e) => {
                e.stopPropagation();
                onOrderChange("up");
              }}
            >
              Move up
            </MenuItem>

            <MenuItem
              isDisabled={pos === "bottom" || pos === "sole"}
              color={"gray.600"}
              fontWeight={500}
              onClick={(e) => {
                e.stopPropagation();
                onOrderChange("down");
              }}
            >
              Move down
            </MenuItem>

            <MenuDivider my={1} />
            <MenuItem
              py={0.5}
              color={"gray.600"}
              fontWeight={500}
              _hover={{ color: "red.500" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default MeetingPlanSectionMenu;
