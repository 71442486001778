import {
  Avatar,
  Box,
  BoxProps,
  Button,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { tabSelectedStyle } from "components/meetings/MeetingPage";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ReactComponent as PersonIcon } from "assets/icons/person.svg";
import { logout } from "reducers/user/userReducer";

interface SettingsProps extends BoxProps {}

const Settings: React.FC<SettingsProps> = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);

  return (
    <Stack className="settings" w="100%" gap={0}>
      <Box p=".9rem" borderBottom={"1px"} borderColor={"gray.100"}>
        <Text fontSize={18} fontWeight={500}>
          Settings
        </Text>
      </Box>
      <Tabs index={0} variant="unstyled" isLazy={true} color={"gray.400"} flex={1}>
        <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
          <Tab _selected={tabSelectedStyle} py={3} fontSize={"sm"} borderRadius={0}>
            <Icon as={PersonIcon} mr={1} />
            Account
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel py={6} px={4}>
            <Text as={"div"} color={"gray.800"} mb={6} fontWeight={500} fontSize={"lg"}>
              Account
            </Text>
            <HStack gap={4}>
              <Avatar name={userState.user?.first_name + " " + userState.user?.last_name} size={"lg"} />
              <Stack gap={1}>
                <Text color={"gray.800"} fontWeight={500} fontSize={"sm"}>
                  {userState.user?.first_name + " " + userState.user?.last_name}
                </Text>
                <Text color={"gray.400"} fontWeight={500} fontSize={"xs"}>
                  {userState.user?.email}
                </Text>
                <Button
                  color={"gray.500"}
                  variant={"outline"}
                  size={"xs"}
                  mt={1}
                  onClick={() => {
                    dispatch(logout());
                  }}
                >
                  Logout
                </Button>
              </Stack>
            </HStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
};

export default Settings;
