import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/inter";

const theme = extendTheme({
  fonts: {
    heading: `'Inter Variable', sans-serif`,
    body: `'Inter Variable', sans-serif`,
  },
  colors: {
    echoBlue: {
      50: "#E7F0FD",
      100: "#CFE1FC",
      200: "#9FC3F9",
      300: "#6FA5F6",
      400: "#3F87F3",
      500: "#0F67EF",
      600: "#0C54C0",
      700: "#093F90",
      800: "#062A60",
      900: "#031530",
    },
    echoBlueAlpha: {
      50: "rgba(15, 103, 239,0.04)",
      100: "rgba(15, 103, 239,0.06)",
      200: "rgba(15, 103, 239,0.08)",
      300: "rgba(15, 103, 239,0.16)",
      400: "rgba(15, 103, 239,0.24)",
      500: "rgba(15, 103, 239,0.36)",
      600: "rgba(15, 103, 239,0.48)",
      700: "rgba(15, 103, 239,0.64)",
      800: "rgba(15, 103, 239,0.80)",
      900: "rgba(15, 103, 239,0.92)",
    },
    darkgray: {
      50: "#E7ECEF",
      100: "#CED8DE",
      200: "#B6C5CE",
      300: "#9DB2BE",
      400: "#7895a5",
      500: "#5A7687",
      600: "#394B56",
      700: "#29363D",
      800: "#182025",
      900: "#080B0C",
    },
    jasmine: {
      50: "#FEF1BE",
      100: "#FCE173",
      200: "#FACC15",
      300: "#EBBD05",
      400: "#D7AD05",
      500: "#C39D04",
      600: "#AA8904",
      700: "#917403",
      800: "#695402",
      900: "#4B3C02",
    },
    lime: {
      50: "#ecfccb",
      100: "#d9f99d",
      200: "#bef264",
      300: "#a3e635",
      400: "#84cc16",
      500: "#65a30d",
      600: "#4d7c0f",
      700: "#3f6212",
      800: "#365314",
      900: "#1a2e05",
    },
    brown: {
      50: "#F1EEE9",
      100: "#E1DBD1",
      200: "#D1C8B8",
      300: "#BBAD96",
      400: "#A39171",
      500: "#958160",
      600: "#857456",
      700: "#6C5E46",
      800: "#4D4332",
      900: "#383124",
    },
  },
});

export default theme;
