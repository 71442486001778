import { HStack, Tag as ChakraTag, TagCloseButton, TagLabel, BoxProps } from "@chakra-ui/react";
import React, { HTMLProps, ReactNode } from "react";
import Avatar from "./Avatar";

interface TagProps extends BoxProps {
  label: string;
  color?: string;
  avatar?: string;
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  showClose?: boolean;
  disableHover?: boolean;
}

const Tag: React.FC<TagProps> = ({ label, color, avatar, onClose, showClose, disableHover, ...rest }) => {
  return (
    <HStack spacing={4} display={"inline-block"} mx={0.5} {...rest}>
      {color ? (
        <ChakraTag
          className="tag"
          size="sm"
          borderRadius={8}
          variant="unstyled"
          bgColor={`${color}.50`}
          border={"1px"}
          borderColor={`${color}.100`}
          color={`${color}.700`}
          sx={{ "&:hover .tag-close": { display: "flex" } }}
          py="2px"
          h={6}
          lineHeight={"inherit"}
        >
          <TagLabel lineHeight={"inherit"}>{label}</TagLabel>
          {onClose && (
            <TagCloseButton
              className="tag-close"
              ml={0}
              mr={-2}
              display={showClose ? "flex" : "none"}
              fontSize={"sm"}
              as="span"
              onClick={onClose}
              cursor={"pointer"}
            />
          )}
        </ChakraTag>
      ) : (
        <ChakraTag
          className="tag"
          bgColor={"transparent"}
          _hover={disableHover ? {} : { bgColor: "gray.200" }}
          transition={".1s all linear"}
          sx={{ "&:hover .tag-close": { display: "flex" } }}
          size="md"
          borderRadius="full"
          {...(disableHover ? { px: 0 } : {})}
        >
          <Avatar src={avatar} variant={"ghost"} size="xs" name={label} ml={-1} mr={2} />
          <TagLabel>{label}</TagLabel>
          {onClose && (
            <TagCloseButton
              className="tag-close"
              display={showClose ? "flex" : "none"}
              ml={0}
              as="span"
              onClick={onClose}
              cursor={"pointer"}
            />
          )}
        </ChakraTag>
      )}
    </HStack>
  );
};

export default Tag;
