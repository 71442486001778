import { Icon as ChakraIcon, IconProps as ChakraIconProps, ResponsiveValue } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import * as CSS from "csstype";

interface IconProps extends ChakraIconProps {
  as: FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  color?: ResponsiveValue<CSS.Property.Color>;
  transition?: string;
}

const Icon: React.FC<IconProps> = ({ color, as, transition, ...rest }) => {
  return (
    <ChakraIcon
      className="icon"
      as={as}
      mt={"1px"}
      {...rest}
      sx={{
        ...rest.sx,
        ...(color
          ? { "& path": { fill: color, transition: transition } }
          : { "& path": { fill: "gray.400", transition: transition } }),
      }}
    />
  );
};

export default Icon;
