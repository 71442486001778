import {
  AvatarGroup,
  Box,
  BoxProps,
  Center,
  Checkbox,
  HStack,
  SimpleGrid,
  Switch,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Meeting, MeetingTemplate } from "./types";
import NewTag from "./NewTag";
import Badge from "components/base/Badge";
import { formatMeetingDate, formatMeetingTime, subtractDatesStrInMinutes } from "utils/common";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ReactComponent as VideoIcon } from "assets/icons/video.svg";
import Tag from "components/base/Tag";
import TooltipAvatar from "components/base/TooltipAvatar";
import { Link } from "react-router-dom";
import { ReactComponent as VideoLinesIcon } from "assets/icons/video-lines.svg";
import { useRef } from "react";
import { useIsOverflow } from "hooks/hooks";
import { ReactComponent as CalendarCheckmarkIcon } from "assets/icons/calendar-checkmark.svg";
import { updateMeeting } from "reducers/meeting/meetingReducer";

interface MeetingHeaderProps extends BoxProps {
  meeting: Meeting;
  type: "past" | "future";
  checked?: boolean;
  onCheckedChange?: () => void;
}

const MeetingHeader: React.FC<MeetingHeaderProps> = ({ meeting, type, checked, onCheckedChange }) => {
  const dispatch = useAppDispatch();

  const tagState = useAppSelector((state) => state.tag);
  const workspaceState = useAppSelector((state) => state.workspace);
  const contactState = useAppSelector((state) => state.contact);

  const textRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(textRef);

  const hasRecordingOrUserBullets =
    meeting.meeting_plan?.sections.some((section) => {
      return section.talking_points.some((tp) => {
        return tp.prompt || tp.items.length > 0;
      });
    }) || meeting.meta.recording?.recording_exists;

  const renderMeetingParticipants = (meeting: Meeting) => {
    return (
      <AvatarGroup size={"xs"} max={3} spacing={"-0.5rem"}>
        {meeting.participants.workspace.map((participant) => {
          const user = workspaceState.users.find((user) => user.id === participant);
          if (user) return <TooltipAvatar key={user.id} name={user.first_name + " " + user.last_name} />;
        })}
        {meeting.participants.contacts.map((contactId) => {
          const contact = contactState.contacts.find((contact) => Number(contact.id) === contactId);
          if (contact) return <TooltipAvatar key={contact.id} name={contact.name} />;
        })}
        {meeting.participants.outsiders.map((outsider) => (
          <TooltipAvatar key={outsider.name + outsider.email} name={outsider.name ? outsider.name : outsider.email} />
        ))}
      </AvatarGroup>
    );
  };

  return (
    <SimpleGrid
      columns={type === "past" ? 6 : 7}
      key={meeting.id}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      fontSize={"sm"}
      fontWeight={500}
      color={"gray.800"}
      px={4}
      py={1}
      pos={"relative"}
      _hover={{
        "& .icon-button": { display: "flex" },
        bgColor: "gray.50",
        "& .meeting-checkbox": { opacity: 1, visibility: "visible" },
      }}
    >
      <Center justifyContent={"start"}>
        <Tooltip label={meeting.name} hasArrow isDisabled={!isOverflow} openDelay={500}>
          <HStack overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} mx={2}>
            {type === "past" && (
              <Checkbox
                className="meeting-checkbox"
                checked={checked}
                onChange={onCheckedChange}
                mr={"0.8rem"}
                border={"1px"}
                borderRadius={4}
                color={checked ? "echoBlue.500" : "gray.600"}
                _hover={checked ? { color: "echoBlue.600" } : {}}
                overflow={"hidden"}
                size={"sm"}
                colorScheme="echoBlue"
                opacity={checked ? 1 : 0}
                visibility={checked ? "visible" : "hidden"}
              />
            )}
            <Link
              to={`/meetings/${meeting.id}`}
              style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              <Text overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"} ref={textRef}>
                {meeting.name}
              </Text>
            </Link>
          </HStack>
        </Tooltip>
      </Center>
      <Center justifyContent={"start"}>
        {type === "future" ? (
          <Link to={`/meetings/${meeting.id}`}>
            {meeting.meeting_plan ? (
              <Text>{meeting.meeting_plan.name}</Text>
            ) : (
              <Text color={"gray.300"}>Set meeting plan</Text>
            )}
          </Link>
        ) : (
          <>
            {meeting.meeting_plan ? (
              <Text color={"gray.300"}>{meeting.meeting_plan.name}</Text>
            ) : (
              <Text color={"gray.300"}>-</Text>
            )}
          </>
        )}
      </Center>
      <Center>
        <Text>
          {type === "future"
            ? formatMeetingTime(meeting.start_time, meeting.end_time)
            : formatMeetingDate(meeting.start_time)}
        </Text>
      </Center>
      <Center>{renderMeetingParticipants(meeting)}</Center>
      <Center justifyContent={"left"} w={"fit-content"} zIndex={100}>
        <HStack whiteSpace={"nowrap"} align={"center"} overflow={"hidden"} ml={4}>
          {meeting.tags.map((tagId) => {
            const tag = tagState.payload.find((t) => t.id === tagId);
            if (tag?.type === "meeting") {
              return <Tag key={tagId} label={tag.value} color={tag.meta.color} />;
            }
          })}
          <NewTag
            ellipsis={meeting.tags.length > 0}
            meeting={meeting}
            selected={meeting.tags.map((tagId) => {
              return { value: tagId };
            })}
          />
        </HStack>
      </Center>
      <HStack align={"center"} justify={"right"}>
        {meeting.meeting_url && (
          <Badge
            icon={VideoIcon}
            onClick={() => {
              window.open(meeting.meeting_url!, "_blank");
            }}
            darkHover
          >
            <Text color="gray.800" fontSize={"sm"} py={0.5}>
              Join
            </Text>
          </Badge>
        )}
        {type === "past" && (
          <Badge icon={VideoLinesIcon} iconColor="echoBlue.500" onClick={() => {}} darkHover>
            <Text color="gray.800" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
              Meeting results
            </Text>
          </Badge>
        )}
      </HStack>
      {type === "future" && hasRecordingOrUserBullets && (
        <Center>
          <Badge
            icon={CalendarCheckmarkIcon}
            onClick={() => {
              const now = new Date();
              const newStartTime = new Date(
                Date.UTC(
                  now.getUTCFullYear(),
                  now.getUTCMonth(),
                  now.getUTCDate(),
                  now.getUTCHours(),
                  now.getUTCMinutes(),
                  now.getUTCSeconds()
                )
              );

              const newEndTime = new Date(newStartTime);

              const meetingDuration = subtractDatesStrInMinutes(meeting.start_time, meeting.end_time);
              newStartTime.setUTCMinutes(newStartTime.getUTCMinutes() - meetingDuration - 1);
              newEndTime.setUTCMinutes(newEndTime.getUTCMinutes() - 1);
              dispatch(
                updateMeeting({
                  id: meeting.id,
                  start_time: newStartTime.toISOString().slice(0, -1),
                  end_time: newEndTime.toISOString().slice(0, -1),
                })
              );
            }}
            darkHover
          >
            <Text color="gray.800" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
              Mark as completed
            </Text>
          </Badge>
        </Center>
      )}
    </SimpleGrid>
  );
};

export default MeetingHeader;
