import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AvatarGroup,
  Box,
  Button,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import MeetingHeader from "./MeetingHeader";
import { formatMeetingTitle, sortMeetingKeys, splitMeetings } from "utils/common";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { Meeting, MeetingsByDate } from "./types";
import TooltipAvatar from "components/base/TooltipAvatar";
import { getTags } from "reducers/tags/tagsReducer";
import { includes } from "lodash";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { deleteMeeting } from "reducers/meeting/meetingReducer";

interface MeetingsProps {
  meetings: Meeting[];
  type: "past" | "future" | "all";
}

const Meetings: React.FC<MeetingsProps> = ({ meetings, type }) => {
  const dispatch = useAppDispatch();

  const [meetingsByDate, setMeetingsByDate] = useState<MeetingsByDate>({ past: [], today: [], tomorrow: [] });
  const [checked, setChecked] = useState<number[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    setMeetingsByDate(splitMeetings(meetings, type, 60));
  }, [meetings]);

  const handleCheckedChange = (id: number) => {
    if (checked.includes(id)) {
      setChecked((prev) => prev.filter((currId) => currId !== id));
    } else {
      setChecked((prev) => [...prev, id]);
    }
  };

  const renderItems = () => {
    const keys = Object.keys(meetingsByDate);
    return sortMeetingKeys(keys).map((key) => {
      if (meetingsByDate[key].length > 0) {
        let header = formatMeetingTitle(key);
        return (
          <AccordionItem key={key}>
            <AccordionButton py={1} color={"gray.400"} bgColor={"gray.50"} _hover={{ bgColor: "gray.100" }}>
              <AccordionIcon fontSize={"sm"} mt={0.5} mr={2} />
              <Box as="span" flex="1" textAlign="left" fontSize={"sm"} fontWeight={500}>
                {header} ({meetingsByDate[key].length})
              </Box>
            </AccordionButton>
            <AccordionPanel p={0} color={"gray.800"}>
              {meetingsByDate[key].map((meeting) => {
                return (
                  <MeetingHeader
                    meeting={meeting}
                    key={meeting.id}
                    type={key === "past" ? "past" : "future"}
                    checked={checked.includes(meeting.id)}
                    onCheckedChange={() => handleCheckedChange(meeting.id)}
                  />
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        );
      }
    });
  };

  return (
    <>
      <HStack
        className="selected-menu"
        bgColor={"white"}
        pos={"absolute"}
        transform={"auto"}
        translateY={"-110%"}
        border={"1px"}
        borderRadius={"10px"}
        borderColor={"gray.100"}
        boxShadow={"0px 4px 20px 0px #B7BFCA69"}
        fontSize={"sm"}
        gap={0}
        left={2}
        top={113}
        zIndex={100}
        sx={checked.length > 0 ? {} : { display: "none" }}
      >
        <Text
          fontWeight={500}
          color={"echoBlue.500"}
          px={"0.5rem"}
          py={"0.2rem"}
          borderRight={"1px"}
          borderColor={"gray.100"}
        >
          {checked.length} selected
        </Text>
        <HStack
          gap={0}
          cursor={"pointer"}
          _hover={{ color: "red.500", "& path": { fill: "red.500" } }}
          onClick={() => {
            setIsDeleteModalOpen(true);
          }}
        >
          <Icon as={DeleteIcon} fontSize={16} mx={"0.2rem"} />
          <Text fontWeight={500} pr={"0.5rem"} py={"0.2rem"}>
            Delete
          </Text>
        </HStack>
      </HStack>
      <Accordion allowMultiple defaultIndex={[0]} sx={{ "& .chakra-collapse": { overflow: "auto !important" } }}>
        {renderItems()}
      </Accordion>
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Delete {checked.length > 1 ? "meetings" : "meeting"}</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete {checked.length > 1 ? `${checked.length} meetings?` : "this meeting?"}
          </ModalBody>
          <ModalFooter>
            <Button
              color={"gray.500"}
              mr={4}
              variant={"outline"}
              onClick={() => {
                setIsDeleteModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                checked.forEach((id) => {
                  dispatch(deleteMeeting(id));
                });
                setChecked([]);
                setIsDeleteModalOpen(false);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Meetings;
