import { ReactNode, useState } from "react";
// import EditIcon from "@mui/icons-material/Edit";
// import CloseIcon from "@mui/icons-material/Close";
// import { Modal, Snackbar, Alert } from "@mui/material";
import ChangePassword from "./ChangePassword";
import { useAppSelector } from "hooks/reduxHooks";
import Button from "../base/Button";
import Workspace from "../layout/Workspace";
import StaticInput from "../base/StaticInput";
import EditProfile from "./EditProfile";

interface ProfileProps {
  renderTitles: () => ReactNode;
}

const Profile = ({ renderTitles }: ProfileProps) => {
  const user = useAppSelector((state) => state.user);

  const [editProfileOpen, setEditProfileOpen] = useState(false);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState("");

  const renderButtons = () => {
    return (
      <Button
        className="workspace__button"
        onClick={() => {
          setEditProfileOpen(true);
        }}
        white
      >
        <span>Edit</span>
        {/* <EditIcon /> */}
      </Button>
    );
  };

  return (
    <>
      <Workspace.Menu>{renderTitles()}</Workspace.Menu>
      <Workspace.Paper style={{ textAlign: "left" }}>
        {user.user && (
          <>
            <div className="flex jc-sb ai-end">
              <h3>General information</h3>
              <Button
                className="my-account__button"
                onClick={() => {
                  setEditProfileOpen(true);
                }}
                small
              >
                <span>Edit</span>
                {/* <EditIcon /> */}
              </Button>
            </div>
            <hr />
            <StaticInput className="mb-3">
              <StaticInput.Label>Email</StaticInput.Label>
              <StaticInput.Value>{user.user.email}</StaticInput.Value>
            </StaticInput>
            <StaticInput className="mb-3">
              <StaticInput.Label>Name</StaticInput.Label>
              <StaticInput.Value>{user.user.first_name + " " + user.user.last_name}</StaticInput.Value>
            </StaticInput>
            {/* <StaticInput className="mb-5">
              <StaticInput.Label>Company</StaticInput.Label>
              <StaticInput.Value>{user.details.company}</StaticInput.Value>
            </StaticInput> */}
            <h3>Authentication</h3>
            <hr />
            <StaticInput>
              <StaticInput.Label>Password</StaticInput.Label>
              <StaticInput.Value>
                <Button className="button--link" onClick={() => setChangePasswordOpen(true)}>
                  Change password
                </Button>
              </StaticInput.Value>
            </StaticInput>
          </>
        )}
      </Workspace.Paper>
      {/* <Modal open={editProfileOpen} onClose={() => setEditProfileOpen(false)}>
        <div className="edit-profile">
          <EditProfile
            onClose={() => setEditProfileOpen(false)}
            onSuccess={() => {
              setEditProfileOpen(false);
              setSnackbarMsg("Details changed successfully");
              setSnackbarOpen(true);
            }}
          />
        </div>
      </Modal> */}
      {/* <Modal open={changePasswordOpen} onClose={() => setChangePasswordOpen(false)}>
        <div className="change-password">
          <ChangePassword
            onClose={() => setChangePasswordOpen(false)}
            onSuccess={() => {
              setChangePasswordOpen(false);
              setSnackbarMsg("Password changed successfully");
              setSnackbarOpen(true);
            }}
          />
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        action={<CloseIcon onClick={() => setSnackbarOpen(false)} />}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: "100%", fontWeight: 700 }}>
          {snackbarMsg}
        </Alert>
      </Snackbar> */}
    </>
  );
};

export default Profile;
