import { BaseState } from "../baseTypes";

export interface UIConfig {
  version: number;
}

export interface UIConfigState extends BaseState {
  payload: UIConfig;
}

export const defaultUiConfig: UIConfig = {
  version: 3,
};

export const TAB_CACHE_TIMEOUT: number = 1000 * 60 * 60 * 24 * 3; //Three days
