import { HTMLProps, useEffect, useRef } from "react";

const IndeterminateCheckbox = ({
  indeterminate,
  className = "indeterminate-checkbox",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className}
      {...rest}
      style={rest.checked ? { visibility: "visible", opacity: 1 } : {}}
    />
  );
};

export default IndeterminateCheckbox;
