import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import { useEffect, useRef, useState } from "react";
import { renderIcon } from "./table/ThMenu";
import EnterInput from "components/base/EnterInput";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { PersonOption, SelectOption, StatusOption } from "./table/Meta";
import { shallowCopy } from "utils/common";
import Tag from "components/base/Tag";
import { ColumnType, Filter as FilterType } from "reducers/contact-table/contactTableTypes";
import { useAppSelector } from "hooks/reduxHooks";

interface ActiveFilterProps extends Omit<BoxProps, "filter"> {
  onFilterChange: (value: string | string[]) => void;
  onFilterDelete: () => void;
  filter: FilterType;
  options: StatusOption[] | SelectOption[] | PersonOption[] | undefined;
  defaultOpen: boolean;
}

const ActiveFilter: React.FC<ActiveFilterProps> = ({
  filter,
  options,
  defaultOpen,
  onFilterChange,
  onFilterDelete,
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const workspaceState = useAppSelector((state) => state.workspace);

  const popContentRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState(filter.value);

  useEffect(() => {
    if (isOpen && popContentRef.current) {
      const enterInput = popContentRef.current.querySelector(".enter-input") as HTMLInputElement;
      setTimeout(() => {
        enterInput?.focus();
      }, 10);
    }
  }, [isOpen, popContentRef.current]);

  useEffect(() => {
    if (defaultOpen) {
      setTimeout(() => {
        onToggle();
      }, 200);
    }
  }, []);

  const renderFilterInput = () => {
    switch (filter.colType) {
      case "status":
        return (
          <Stack gap={2}>
            <HStack mb={0.5} gap={1}>
              <Text fontSize={"xs"} color={"gray.500"}>
                {filter.colName}
              </Text>
              <Text flex={1} fontSize={"xs"} color={"gray.800"}>
                is
              </Text>
              <IconButton
                icon={CloseIcon}
                onClick={() => {
                  // dispatch(setFilteringState(null));
                  onFilterDelete();
                  onClose();
                }}
              />
            </HStack>
            {options?.map((genericOption) => {
              const option = genericOption as StatusOption;
              return (
                <HStack key={option.id}>
                  <Checkbox
                    id={`checkbox-${option.id}`}
                    defaultChecked={filter.value.includes(option.id)}
                    onChange={(e) => {
                      let typedValue = shallowCopy(value) as string[];
                      if (e.target.checked && !typedValue.includes(option.id)) {
                        typedValue.push(option.id);
                      } else if (!e.target.checked && typedValue.includes(option.id)) {
                        typedValue = typedValue.filter((val) => val !== option.id);
                      }
                      setValue(typedValue);
                      onFilterChange(typedValue);
                    }}
                  />
                  <Center
                    className="menu-item"
                    cursor={"pointer"}
                    // eventKey={option.id}
                    justifyContent={"center"}
                    p="0rem 1rem"
                    w={"100%"}
                    // w="fit-content"
                    borderRadius={2}
                    color={"gray.600"}
                    fontWeight={500}
                    h="1.5rem"
                    onClick={() => (document.querySelector(`#checkbox-${option.id}`) as HTMLInputElement)?.click()}
                    backgroundColor={`var(--chakra-colors-${option.color}-200)`}
                  >
                    <Text>{option.value}</Text>
                  </Center>
                </HStack>
              );
            })}
          </Stack>
        );

      case "person":
      case "multi-person":
      case "select":
      case "multi-select":
        const relevantOptions = ["select", "multi-select"].includes(filter.colType)
          ? options
          : workspaceState.usersOption;
        return (
          <Stack gap={2}>
            <HStack mb={0.5} gap={1}>
              <Text fontSize={"xs"} color={"gray.500"}>
                {filter.colName}
              </Text>
              <Text flex={1} fontSize={"xs"} color={"gray.800"}>
                is
              </Text>
              <IconButton
                icon={CloseIcon}
                onClick={() => {
                  onFilterDelete();
                  onClose();
                }}
              />
            </HStack>
            {relevantOptions?.map((genericOption) => {
              const option = ["select", "multi-select"].includes(filter.colType)
                ? (genericOption as SelectOption)
                : (genericOption as PersonOption);
              return (
                <HStack key={option.id}>
                  <Checkbox
                    id={`checkbox-${option.id}`}
                    defaultChecked={filter.value.includes(option.id)}
                    onChange={(e) => {
                      let typedValue = shallowCopy(value) as string[];
                      if (e.target.checked && !typedValue.includes(option.id)) {
                        typedValue.push(option.id);
                      } else if (!e.target.checked && typedValue.includes(option.id)) {
                        typedValue = typedValue.filter((val) => val !== option.id);
                      }
                      setValue(typedValue);
                      onFilterChange(typedValue);
                    }}
                  />
                  <Tag
                    className="menu-item"
                    key={option.value}
                    label={option.value}
                    cursor={"pointer"}
                    onClick={() => (document.querySelector(`#checkbox-${option.id}`) as HTMLInputElement)?.click()}
                    {...(option.type === "select" || option.type === "multi-select" ? { color: option.color } : {})}
                    {...(option.type === "person" || option.type === "multi-person" ? { avatar: option.avatar } : {})}
                  />
                </HStack>
              );
            })}
          </Stack>
        );

      case "url":
      case "number":
      case "text":
        return (
          <Box>
            <HStack mb={0.5} gap={1}>
              <Text fontSize={"xs"} color={"gray.500"}>
                {filter.colName}:
              </Text>
              <Text fontSize={"xs"} color={"gray.800"}>
                includes
              </Text>
            </HStack>
            <HStack>
              <EnterInput
                type={filter.colType == "number" ? "number" : "text"}
                mb={0}
                value={value as string}
                onChange={(e) => setValue(e.target.value)}
                onBlur={(e, isEnterKey) => {
                  if (isEnterKey) {
                    onFilterChange(value as string);
                  }
                }}
              />
              <IconButton
                icon={CloseIcon}
                onClick={() => {
                  onFilterDelete();
                  onClose();
                }}
              />
            </HStack>
          </Box>
        );

      default:
        return <></>;
    }
  };

  const renderFilterName = () => {
    switch (filter.colType) {
      case "status":
      case "select":
      case "multi-select":
        return getStatusFilterName();

      case "person":
      case "multi-person":
        return getPersonFilterName();

      default:
        return filter.value;
    }
  };

  const getPersonFilterName = () => {
    let str = "";
    (filter.value as string[]).forEach((userId, index) => {
      const option = workspaceState.usersOption.find((option) => option.id === userId);
      if (index < filter.value.length - 1) {
        str += option!.value + ", ";
      } else {
        str += option!.value;
      }
    });
    return str;
  };

  const getStatusFilterName = () => {
    const typedOptions = options as StatusOption[];
    let str = "";
    (filter.value as string[]).forEach((statusId, index) => {
      const option = typedOptions.find((option) => option.id === statusId);
      if (index < filter.value.length - 1) {
        str += option!.value + ", ";
      } else {
        str += option!.value;
      }
    });
    return str;
  };

  return (
    <Box className={`active-filter`}>
      <Badge
        key={filter.identifier}
        iconColor={"echoBlue.500"}
        bgColor={"echoBlueAlpha.100"}
        borderColor={"echoBlueAlpha.300"}
        display={"flex"}
        alignItems={"center"}
        gap={1}
        _hover={{ bgColor: "echoBlueAlpha.200" }}
        _active={{ bgColor: "echoBlueAlpha.300" }}
        onClick={onToggle}
      >
        <Box as="span" fontSize={"sm"} display={"flex"} sx={{ "& svg path": { fill: "echoBlue.500" } }}>
          {renderIcon(filter.colType, filter.identifier)}
        </Box>
        <Text
          color="gray.800"
          fontSize={"sm"}
          py={0.5}
          maxW={"10rem"}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          <Box as="span" color={"gray.500"}>
            {filter.colName}:
          </Box>{" "}
          {renderFilterName()}
        </Text>
      </Badge>
      <Popover
        gutter={4}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>
        <PopoverContent
          ref={popContentRef}
          w={"fit-content"}
          className="filter__popover-content"
          _focusVisible={{ outline: "unset", boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
        >
          <PopoverBody>{renderFilterInput()}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default ActiveFilter;
