import { Box, BoxProps } from "@chakra-ui/react";
import DynamicTextarea from "components/base/DynamicTextarea";
import { useState } from "react";

interface MeetingResultsTalkingPointProps extends BoxProps {
  name: string;
}

const MeetingResultsTalkingPoint: React.FC<MeetingResultsTalkingPointProps> = ({ name }) => {
  const [value, setValue] = useState(name);

  return (
    <DynamicTextarea
      value={value}
      onChange={(e) => {}}
      onBlur={() => {}}
      onEnter={() => {}}
      color={"gray.700"}
      py={1}
      px={0}
      fontSize={"md"}
      fontWeight={500}
      resize={"none"}
      fullWidth={true}
      flex={1}
      onFocus={(e) => e.target.blur()}
    />
  );
};

export default MeetingResultsTalkingPoint;
