import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";
import { MeetingPlanSection as MeetingPlanSectionType } from "components/meetings/types";
import EstimatedTime from "./EstimatedTime";
import { ReactComponent as MeetingSectionIcon } from "assets/icons/meeting-section.svg";
import DynamicInput from "components/base/DynamicInput";
import Icon from "components/base/Icon";
import { useState } from "react";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface MeetingPlanSectionHeaderProps extends BoxProps {
  section: MeetingPlanSectionType;
  onSectionChange: (section: MeetingPlanSectionType | null) => void;
  viewOnly?: boolean;
  autoFocus?: boolean;
}

const MeetingPlanSectionHeader: React.FC<MeetingPlanSectionHeaderProps> = ({
  section,
  onSectionChange,
  viewOnly,
  autoFocus,
  ...rest
}) => {
  const [description, setDescription] = useState(section.description ? section.description : "");
  const [estimated, setEstimated] = useState<number | "">(section.estimated ? section.estimated : "");

  return (
    <Box position="relative" {...rest}>
      <HStack className="meeting-plan-section-header" gap={3}>
        <DynamicInput
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          onBlur={(e) => {
            onSectionChange({ ...section, description: e.target.value });
          }}
          color={"gray.800"}
          fontWeight={500}
          fontSize={"lg"}
          placeholder="Section name"
          maxWidthInPx={480}
          autoFocus={autoFocus}
        />
        <Box as={"span"}>•</Box>
        <HStack gap={1}>
          <Icon as={MeetingSectionIcon} mt={"1px"} fontSize={"sm"} />
          <Text color={"gray.500"} fontSize={"sm"} whiteSpace={"nowrap"}>
            Meeting section
          </Text>
        </HStack>
        <Box as={"span"}>•</Box>
        <EstimatedTime
          initialValue={estimated}
          onValueChange={(value) => setEstimated(value)}
          onValueBlur={(value) => onSectionChange({ ...section, description, estimated: value })}
        />
      </HStack>
      {viewOnly && <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="transparent" cursor="default" />}
    </Box>
  );
};

export default MeetingPlanSectionHeader;
