import { Box, BoxProps } from "@chakra-ui/react";
import { Contact } from "components/contacts/types";
import QuillEditor from "components/quill/QuillEditor";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContact } from "reducers/contact/contactReducer";

interface ContactNotesProps extends BoxProps {
  contact: Contact | undefined;
}

const ContactNotes: React.FC<ContactNotesProps> = ({ contact }) => {
  const dispatch = useAppDispatch();

  return (
    <QuillEditor
      defaultEditor={contact?.notes}
      saveEditor={(notes) => {
        if (contact) dispatch(updateContact({ id: contact.id, notes }));
      }}
    />
  );
};

export default ContactNotes;
