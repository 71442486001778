import { Box, BoxProps } from "@chakra-ui/react";
import DynamicTextarea from "components/base/DynamicTextarea";
import { useState } from "react";

interface MeetingResultsBulletProps extends BoxProps {
  bullet: { id: string; value: string };
  type: "user" | "ai";
}

const MeetingResultsBullet: React.FC<MeetingResultsBulletProps> = ({ bullet, type }) => {
  const [value, setValue] = useState(bullet.value);

  return (
    <DynamicTextarea
      value={value}
      onChange={(e) => {
        // setValue(e.target.value);
      }}
      onBlur={() => {}}
      onEnter={() => {}}
      className="meeting-results-bullet"
      color={type === "ai" ? "echoBlue.500" : "gray.700"}
      fontSize={"sm"}
      py={0.5}
      resize={"none"}
      fullWidth={true}
      flex={1}
      onFocus={(e) => e.target.blur()}
    />
  );
};

export default MeetingResultsBullet;
