import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Icon,
  Divider,
  MenuDivider,
  Text,
  HStack,
} from "@chakra-ui/react";
import { ColumnDef, ColumnMeta, Table } from "@tanstack/react-table";
import { ErrorMessage, useFormik } from "formik";
import { Contact } from "../../types";
import IconButton from "components/base/IconButton";
import { SelectOption, StatusOption } from "../Meta";
import { AvailableColors } from "types/types";
import * as Yup from "yup";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContactTableColumn } from "reducers/contact-table/contactTableReducer";
import { CellTypesWithOptions, ColumnType } from "reducers/contact-table/contactTableTypes";
import { pickRandomItem, shallowCopy, subtractArrays } from "utils/common";
import { valueTextMap } from "../ContactTable";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import EnterInput from "components/base/EnterInput";
import Badge from "components/base/Badge";
import { ReactComponent as ReOrderDotsVerticalIcon } from "assets/icons/re-order-dots-vertical.svg";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { useEffect, useRef, useState } from "react";
import DrawerOptions from "./DrawerOptions";

const generateOptionId = (type: string) => {
  const hash = (+new Date()).toString(36);
  return `_${type}_option-${hash}`;
};

interface EditColDrawerProps {
  table: Table<Contact>;
  isOpen: boolean;
  onClose: () => void;
  colId: string;
}

const EditColDrawer: React.FC<EditColDrawerProps> = ({ isOpen, onClose, table, colId }) => {
  const dispatch = useAppDispatch();

  const [isAddingOption, setIsAddingOption] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        const drawer = document.getElementById("chakra-modal-edit-col");
        const table = document.querySelector(".contact-table") as HTMLDivElement;
        const thead = table.querySelector("thead") as HTMLTableSectionElement;
        if (drawer && table && thead) {
          drawer.style.top = table.getBoundingClientRect().y + thead.getBoundingClientRect().height + 1 + "px";
          drawer.style.height = "fit-content";
          drawer.style.maxHeight =
            window.innerHeight - (table.getBoundingClientRect().y + thead.getBoundingClientRect().height + 20) + "px";
          drawer.style.borderTopLeftRadius = "4px";
          drawer.style.borderBottomLeftRadius = "4px";
          clearInterval(intervalId); // Stop the interval once successful
        }
      } catch (error) {
        console.error("Error while trying to assign a value:", error);
      }
    }, 10);

    if (!isOpen) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [isOpen]);

  const columnDef = colId ? table.getColumn(colId)?.columnDef : null;

  const optionSchema = Yup.object().shape({
    value: Yup.string().required("Value is required"),
    color: Yup.string().required("Color is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: (columnDef?.header as string) || "",
      type: (columnDef?.meta?.type as string) || "text",
      options: (columnDef?.meta?.options?.values as SelectOption[] | StatusOption[]) || [],
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      options: Yup.array().of(optionSchema),
    }),
    onSubmit: (values) => {
      let newColumn = table.getColumn(colId)?.columnDef;
      if (newColumn) {
        newColumn.header = values.name;
        newColumn.meta!.options = { values: values.options };
        if (table.options.meta?.updateColumn) table.options.meta.updateColumn(newColumn, true);
        dispatch(
          updateContactTableColumn({
            accessor_key: newColumn.id,
            header: newColumn.header,
            options: newColumn.meta?.options!,
          })
        );
      }
      onClose();
    },
  });

  const addOption = (e: React.FocusEvent<HTMLInputElement>) => {
    if (newOptionValue.length === 0) return;
    const currOptions = shallowCopy(formik.values.options);
    if (
      formik.values.type === "status" &&
      (currOptions as StatusOption[]).find((option) => option.value === newOptionValue) !== undefined
    )
      return;
    else if ((currOptions as SelectOption[]).find((option) => option.value === newOptionValue) !== undefined) return;
    const optionId = generateOptionId(columnDef!.meta!.type);
    const takenColors = formik.values.options.map((option) => option.color);
    const colorsLeft = subtractArrays(AvailableColors, takenColors);
    const newColor = colorsLeft.length > 0 ? pickRandomItem(colorsLeft) : pickRandomItem(AvailableColors);
    formik.setFieldValue("options", [
      ...formik.values.options,
      { type: columnDef?.meta?.type, value: newOptionValue, color: newColor, id: optionId },
    ]);
    setNewOptionValue("");
    setTimeout(() => {
      // document.getElementById(optionId)?.focus();
      e.target.focus();
    }, 100);
  };

  const handleColorChange = (idx: number, color: AvailableColors) => {
    let mutableOptions = shallowCopy(formik.values.options);
    mutableOptions[idx].color = color;
    formik.setFieldValue("options", mutableOptions);
  };

  const handleDeleteOption = (option: SelectOption | StatusOption) => {
    let prevOptions = [...formik.values.options];
    formik.setFieldValue(
      "options",
      prevOptions.filter((op) => op.id !== option.id)
    );
  };

  return (
    <Drawer id="edit-col" isFullHeight={false} size={"xs"} isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay bgColor={"blackAlpha.50"} />
      <form onSubmit={formik.handleSubmit}>
        <DrawerContent>
          <DrawerHeader
            pt={3}
            pb={2}
            px={2}
            gap={2}
            borderBottomWidth="0px"
            fontSize={"sm"}
            fontWeight={500}
            color={"gray.800"}
            display={"flex"}
            alignItems={"center"}
          >
            <IconButton icon={ArrowLeftIcon} color={"gray.500"} onClick={() => onClose()} />
            <Box as="span">{valueTextMap[formik.values.type as ColumnType]} column</Box>
          </DrawerHeader>
          <DrawerBody>
            <Stack gap={2}>
              <Box>
                <Input
                  autoComplete="off"
                  autoFocus
                  id="name"
                  name="name"
                  placeholder="Column name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  fontSize={"sm"}
                  size={"sm"}
                  color={"gray.800"}
                  borderRadius={4}
                  outline={"1px"}
                  _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                />
              </Box>
              {CellTypesWithOptions.includes(formik.values.type) && (
                <>
                  <Divider my={1} />
                  <Flex justify={"space-between"} align={"center"}>
                    <Text m={0} fontSize={"sm"} fontWeight={500} color={"gray.800"}>
                      Options
                    </Text>
                    <IconButton icon={AddIcon} color={"gray.500"} onClick={() => setIsAddingOption(true)} />
                  </Flex>
                  {isAddingOption && (
                    <EnterInput
                      mt={2}
                      autoFocus={true}
                      value={newOptionValue}
                      onChange={(e) => setNewOptionValue(e.target.value)}
                      onBlur={(e, isEnterKey) => {
                        if (isEnterKey) addOption(e);
                        if (e.target.value.length === 0) setIsAddingOption(false);
                      }}
                    />
                  )}
                  <DrawerOptions options={formik.values.options} setFieldValue={formik.setFieldValue} />
                </>
              )}
            </Stack>
          </DrawerBody>
          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" size={"sm"} mr={3} onClick={() => onClose()}>
              Cancel
            </Button>
            <Button type="submit" variant={"outline"} size={"sm"} colorScheme="blue">
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};

export default EditColDrawer;
