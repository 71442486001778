import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { UIConfig, UIConfigState, defaultUiConfig } from "./uiConfigTypes";
import { get, put } from "api/requests";

export const getUiConfig = createAsyncThunk("uiconfig/getUiConfig", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/uiconfig/`;
  return await get(url);
});

export const updateUiConfig = createAsyncThunk("uiconfig/updateUiConfig", async (body: UIConfig) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/uiconfig/`;
  await put(url, body);
  return await get(url);
});

//PENDING

const isFetching = (key: string) => (state: UIConfigState) => {
  state.status[key] = "fetching";
};

//FULFILLED

const gotUiConfig = (key: string) => (state: UIConfigState, action: PayloadAction<UIConfig>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];
  if (action.payload?.version >= defaultUiConfig.version) {
    state.payload = action.payload;
  }
};

//REJECTED

const notGotUiConfig = (key: string) => (state: UIConfigState, action: any) => {
  state.status[key] = "rejected";
  state.errors[key] = action.error.message;
};

const initialState: UIConfigState = {
  payload: defaultUiConfig,
  errors: {},
  status: {},
};

export const uiconfigSlice = createSlice({
  name: "uiconfig",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUiConfig.pending, () => {
        isFetching("primary");
      })
      .addCase(getUiConfig.fulfilled, () => {
        gotUiConfig("primary");
      })
      .addCase(getUiConfig.rejected, () => {
        notGotUiConfig("primary");
      })
      .addCase(updateUiConfig.pending, () => {
        isFetching("update");
      })
      .addCase(updateUiConfig.fulfilled, () => {
        gotUiConfig("update");
      })
      .addCase(updateUiConfig.rejected, () => {
        notGotUiConfig("update");
      });
  },
});

export const { resetStatus, resetError } = uiconfigSlice.actions;
export default uiconfigSlice.reducer;
