import { Box, Flex, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ReactComponent as CallIcon } from "assets/icons/call.svg";
import { ReactComponent as CallEndIcon } from "assets/icons/call-end.svg";
import TabHeader from "components/base/TabHeader";
import { tabSelectedStyle } from "components/contact/ContactPage";
import UpcomingMeetings from "./UpcomingMeetings";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getMeetingTemplates } from "reducers/meeting-template/meetingTemplateReducer";
import { getTags } from "reducers/tags/tagsReducer";
import PastMeetings from "./PastMeetings";
import { getMyMeetings } from "reducers/meeting/meetingReducer";
import Icon from "components/base/Icon";
import { ReactComponent as CalendarArrowRightIcon } from "assets/icons/calendar-arrow-right.svg";
import { ReactComponent as CalendarCheckmarkIcon } from "assets/icons/calendar-checkmark.svg";

interface MeetingsPageProps {}

const MeetingsPage: React.FC<MeetingsPageProps> = () => {
  const dispatch = useAppDispatch();

  const [activeMainTabIndex, setActiveMainTabIndex] = useState(0);
  const [cache, setCache] = useState<{ [key: string]: boolean }>({});
  const [searchTerms, setSearchTerms] = useState({ upcoming: "", past: "" });

  useEffect(() => {
    dispatch(getMyMeetings());
    dispatch(getMeetingTemplates());
    dispatch(getTags());
  }, []);

  const MainTabs: { identifier: "upcoming" | "past"; [key: string]: any }[] = [
    {
      identifier: "upcoming",
      name: "Upcoming meetings",
      icon: CalendarArrowRightIcon,
      panel: <UpcomingMeetings cache={cache} setCache={setCache} searchTerm={searchTerms["upcoming"]} />,
    },
    {
      identifier: "past",
      name: "Past meetings",
      icon: CalendarCheckmarkIcon,
      panel: <PastMeetings cache={cache} setCache={setCache} searchTerm={searchTerms["past"]} />,
    },
  ];

  return (
    <Flex w="100%" direction={"column"}>
      <Box p=".9rem" borderBottom={"1px"} borderColor={"gray.100"}>
        <Text fontSize={18} fontWeight={500}>
          Meetings
        </Text>
      </Box>
      <HStack gap={0} flex={1} alignItems={"stretch"}>
        <Tabs
          index={activeMainTabIndex}
          variant="unstyled"
          isLazy={true}
          color={"gray.400"}
          onChange={(index) => {
            setActiveMainTabIndex(index);
          }}
          flex={1}
        >
          <TabList pl={4} borderBottom={"1px"} borderColor={"gray.100"}>
            {MainTabs.map((tab) => {
              return (
                <Tab _selected={tabSelectedStyle} py={3} fontSize={"sm"} key={tab.identifier} borderRadius={0}>
                  <Icon as={tab.icon} mr={1} />
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          <TabPanels>
            {MainTabs.map((tab, index) => {
              return (
                <Box key={tab.identifier}>
                  <TabHeader
                    tab={tab.identifier}
                    show={activeMainTabIndex === index}
                    {...(tab.onAdd ? { onAdd: tab.onAdd } : {})}
                    searchTerm={searchTerms[tab.identifier]}
                    setSearchTerm={(newValue) =>
                      setSearchTerms((prev) => {
                        return { ...prev, [tab.identifier]: newValue };
                      })
                    }
                  />
                  <TabPanel p={0}>{tab.panel}</TabPanel>
                </Box>
              );
            })}
          </TabPanels>
        </Tabs>
      </HStack>
    </Flex>
  );
};

export default MeetingsPage;
