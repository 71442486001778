import { AbsoluteCenter, Box } from "@chakra-ui/react";

interface LetterSquareProps {
  letter: string;
  size: number | string;
}

const LetterSquare: React.FC<LetterSquareProps> = ({ letter, size }) => {
  return (
    <Box h={"24px"} w={"24px"} borderRadius={3} bgColor={"gray.900"} pos={"relative"}>
      <AbsoluteCenter color={"white"} fontWeight={500}>
        {letter}
      </AbsoluteCenter>
    </Box>
  );
};

export default LetterSquare;
