import { AvatarBadge, Box, BoxProps, Stack, Text } from "@chakra-ui/react";
import { FormattedTranscription } from "components/meetings/types";
import { useEffect, useRef, useState } from "react";
import { simpleHash } from "utils/common";

interface TranscriptProps extends BoxProps {
  transcript: FormattedTranscription[] | undefined;
}

const Transcript: React.FC<TranscriptProps> = ({ transcript }) => {
  const transcriptRef = useRef<HTMLDivElement>(null);
  const [spanParagraphs, setSpanParagraphs] = useState<{ speaker: string; content: string }[]>([]);
  const [TranscriptHeight, setTranscriptHeight] = useState("80vh");

  useEffect(() => {
    if (transcript) {
      let paragraphs = [];
      let currentParagraph = { speaker: transcript[0].speaker, content: "" };

      transcript.forEach((entry) => {
        if (entry.speaker === currentParagraph.speaker) {
          currentParagraph.content += `<span start_time="${entry.start_time}" end_time="${entry.end_time}">${entry.utterance}</span> `;
        } else {
          paragraphs.push(currentParagraph);
          currentParagraph = {
            speaker: entry.speaker,
            content: `<span start_time="${entry.start_time}" end_time="${entry.end_time}">${entry.utterance}</span> `,
          };
        }
      });

      if (currentParagraph.content !== "") {
        paragraphs.push(currentParagraph);
      }

      setSpanParagraphs(paragraphs);
    }
  }, [transcript]);

  useEffect(() => {
    if (transcriptRef.current) {
      setTranscriptHeight(`calc(100vh - ${transcriptRef.current.getBoundingClientRect().y + 10}px)`);
    }
  }, [transcriptRef]);

  return (
    <Box
      className="transcript"
      py={2}
      px={4}
      ref={transcriptRef}
      height={TranscriptHeight}
      overflow={"auto"}
      sx={{
        "&::-webkit-scrollbar": { width: 0 },
      }}
    >
      {spanParagraphs.map((paragraph) => {
        return (
          <Stack key={simpleHash(paragraph.content)} gap={1} mb={6}>
            <Text color={"gray.800"} fontWeight={500} fontSize={"sm"}>
              Speaker {paragraph.speaker}:
            </Text>
            <Box color={"gray.700"} fontSize={"sm"} dangerouslySetInnerHTML={{ __html: paragraph.content }} />
          </Stack>
        );
      })}
    </Box>
  );
};

export default Transcript;
