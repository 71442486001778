import {
  Box,
  BoxProps,
  Center,
  Checkbox,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import { ReactNode, useEffect, useRef, useState } from "react";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { Column, Table } from "@tanstack/react-table";
import { Contact } from "./types";
import { renderIcon } from "./table/ThMenu";
import EnterInput from "components/base/EnterInput";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface NewFilterProps extends BoxProps {
  table: Table<Contact>;
  onNewFilter: (col: Column<Contact>, value: string | string[]) => void;
}

const NewFilter: React.FC<NewFilterProps> = ({ table, onNewFilter }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const badgeRef = useRef<HTMLDivElement>(null);
  const popContentRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState("");
  const [enable, setEnable] = useState(false);
  const [chosenCol, setChosenCol] = useState<Column<Contact>>();

  useEffect(() => {
    if (isOpen && popContentRef.current) {
      const enterInput = popContentRef.current.querySelector(".enter-input") as HTMLInputElement;
      setTimeout(() => {
        enterInput?.focus();
      }, 10);
    }
  }, [isOpen, popContentRef.current]);

  const renderFilterInput = (col: Column<Contact>) => {
    const type = col.columnDef.meta?.type;
    if (type) {
      switch (type) {
        case "person":
        case "multi-person":
        case "select":
        case "multi-select":
        case "status":
          return <></>;

        case "url":
        case "number":
        case "text":
          return (
            <Box>
              <HStack mb={0.5} gap={1}>
                <Text fontSize={"xs"} color={"gray.500"}>
                  {col.columnDef.header as ReactNode}
                </Text>
                <Text fontSize={"xs"} color={"gray.800"}>
                  includes
                </Text>
              </HStack>
              <HStack>
                <EnterInput
                  type={type == "number" ? "number" : "text"}
                  autoFocus={true}
                  mb={0}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onBlur={(e, isEnterKey) => {
                    if (isEnterKey) {
                      onNewFilter(col, value);
                    }
                    // setValue("");
                    // setEnable(false);
                  }}
                />
                <IconButton
                  icon={CloseIcon}
                  onClick={() => {
                    setEnable(false);
                    // dispatch(setFilteringState(null));
                    onClose();
                  }}
                />
              </HStack>
            </Box>
          );

        default:
          return <></>;
      }
    }
  };

  return (
    <Box className={`new-filter`}>
      <Menu gutter={4} isLazy={true} autoSelect={false}>
        <MenuButton
          borderRadius={6}
          _hover={{ bgColor: "gray.50" }}
          _active={{ bgColor: "gray.100" }}
          onClick={(e) => {
            if (enable) {
              e.preventDefault();
              onToggle();
            }
          }}
        >
          <Badge icon={AddIcon} ref={badgeRef}>
            <Text color="gray.800" fontSize={"sm"} py={0.5}>
              Filter
            </Text>
          </Badge>
        </MenuButton>
        <MenuList p={2} fontSize={"sm"}>
          {table.getAllLeafColumns().map((col) => {
            if (col.getCanFilter() && col.getIsVisible() && col.id !== "_table-options_") {
              return (
                <MenuItem
                  display={"flex"}
                  gap={2}
                  key={col.id}
                  onClick={() => {
                    // dispatch(updateContactTableColumn({ accessor_key: col.id, hidden: false }));
                    // col.toggleVisibility();
                    if (
                      ["status", "select", "multi-select", "person", "multi-person"].includes(col.columnDef.meta!.type)
                    ) {
                      onNewFilter(col, []);
                    } else {
                      setChosenCol(col);
                      setEnable(true);
                      onToggle();
                    }
                  }}
                >
                  {renderIcon(col.columnDef.meta?.type, col.id)}
                  {col.columnDef.header as ReactNode}
                </MenuItem>
              );
            }
          })}
        </MenuList>
      </Menu>
      <Popover
        gutter={4}
        isOpen={isOpen}
        onClose={() => {
          setValue("");
          setEnable(false);
          onClose();
        }}
        placement="bottom-start"
      >
        <PopoverTrigger>
          <Box />
        </PopoverTrigger>
        <PopoverContent
          w={"fit-content"}
          className="filter__popover-content"
          ref={popContentRef}
          _focusVisible={{ outline: "unset", boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
        >
          <PopoverBody>{chosenCol && renderFilterInput(chosenCol)}</PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NewFilter;
