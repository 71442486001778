import {
  Box,
  BoxProps,
  Center,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { ReactComponent as MoreVerticalIcon } from "assets/icons/more-vertical.svg";
import { useState } from "react";

interface TalkingPointMenuProps extends Omit<BoxProps, "pos"> {
  pos: "sole" | "top" | "bottom" | "middle";
  onOrderChange: (direction: "up" | "down") => void;
  onDelete: () => void;
  hasPrompt: boolean;
  onPromptToggle: () => void;
}

const TalkingPointMenu: React.FC<TalkingPointMenuProps> = ({
  pos,
  onOrderChange,
  onDelete,
  hasPrompt,
  onPromptToggle,
  ...rest
}) => {
  const { onToggle, isOpen, onClose } = useDisclosure();

  return (
    <Box
      className="talking-point-menu"
      display={"flex"}
      p={"1px"}
      borderRadius="50%"
      _hover={{ bgColor: "gray.100" }}
      {...rest}
    >
      <Menu isOpen={isOpen} onClose={onClose} gutter={4}>
        <MenuButton
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
        >
          <Center>
            <Icon
              className="talking-point-menu-icon"
              h={5}
              w={5}
              as={MoreVerticalIcon}
              opacity={0}
              visibility="hidden"
              p={"1px"}
              sx={{ "& path": { fill: "gray.500" }, ...(isOpen ? { opacity: 1, visibility: "visible" } : {}) }}
            />
          </Center>
        </MenuButton>
        <Portal>
          <MenuList fontSize={"sm"} minW={36} py={1}>
            <>
              <MenuItem
                color={"gray.600"}
                fontWeight={500}
                onClick={(e) => {
                  e.stopPropagation();
                  onPromptToggle();
                }}
              >
                {hasPrompt ? "Delete " : "Generate retrieval "} prompt
              </MenuItem>
              <MenuDivider my={1} />
            </>
            <MenuItem
              isDisabled={pos === "top" || pos === "sole"}
              color={"gray.600"}
              fontWeight={500}
              onClick={(e) => {
                e.stopPropagation();
                onOrderChange("up");
              }}
            >
              Move up
            </MenuItem>

            <MenuItem
              isDisabled={pos === "bottom" || pos === "sole"}
              color={"gray.600"}
              fontWeight={500}
              onClick={(e) => {
                e.stopPropagation();
                onOrderChange("down");
              }}
            >
              Move down
            </MenuItem>

            <MenuDivider my={1} />
            <MenuItem
              py={0.5}
              color={"gray.600"}
              fontWeight={500}
              _hover={{ color: "red.500" }}
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default TalkingPointMenu;
