import { HTMLProps } from "react";

const StaticInput = (props: HTMLProps<HTMLDivElement>) => {
  const size = props.size ? props.size : "md";

  let className = "static-input " + size;
  className += props.className ? " " + props.className : "";

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};

const Label = (props: HTMLProps<HTMLDivElement>) => {
  let className = "static-input__label";
  className += props.className ? " " + props.className : "";

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
StaticInput.Label = Label;

const Value = (props: HTMLProps<HTMLDivElement>) => {
  let className = "static-input__value";
  className += props.className ? " " + props.className : "";

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
StaticInput.Value = Value;

export default StaticInput;
