import { BoxProps, HStack, filter } from "@chakra-ui/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Column, ColumnFilter, ColumnFiltersState, Table } from "@tanstack/react-table";
import { Contact } from "./types";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ColumnType, Filter as FilterType } from "reducers/contact-table/contactTableTypes";
import NewFilter from "./NewFilter";
import ActiveFilter from "./ActiveFilter";
import { setFilterState, updateContactTable } from "reducers/contact-table/contactTableReducer";
import { shallowCopy } from "utils/common";
// import { setFilteringState } from "reducers/contact-table/contactTableReducer";

interface FiltersProps extends BoxProps {
  table: Table<Contact>;
  // columnFilters: ColumnFilter[];
  // setColumnFilters: React.Dispatch<React.SetStateAction<ColumnFilter[]>>;
}

const Filters: React.FC<FiltersProps> = ({ table, ...rest }) => {
  const dispatch = useAppDispatch();
  const meta = useAppSelector((state) => state.contactTable.contactTable.meta);
  const filterState = useAppSelector((state) => state.contactTable.filterState);

  const [activeFilterOpen, setActiveFilterOpen] = useState<string>("");
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const updateActiveFilterOpen = (id: string) => {
    setActiveFilterOpen(id);
    setForceUpdate((prev) => prev + 1); // Increment forceUpdate to force re-render
  };

  return (
    <HStack pos={"relative"} zIndex={100} {...rest}>
      {filterState?.map((filter) => {
        const col = table.getAllLeafColumns().find((col) => col.id === filter.id) as Column<Contact>;
        if (col) {
          return (
            <ActiveFilter
              key={`${col.id}-${forceUpdate}`}
              filter={{
                identifier: filter.id,
                value: filter.value as string | string[],
                colName: col.columnDef.header as string,
                colType: col.columnDef.meta!.type,
              }}
              options={col.columnDef.meta?.options ? col.columnDef.meta.options.values : undefined}
              defaultOpen={activeFilterOpen === col.id}
              onFilterChange={(newValue) => {
                let newFilters = shallowCopy(filterState).map((prevFilter) => {
                  if (prevFilter.id === filter.id) {
                    prevFilter.value = newValue;
                    return prevFilter;
                  }
                  return prevFilter;
                });
                dispatch(setFilterState(newFilters));
                dispatch(updateContactTable({ ...meta, filterState: newFilters }));
              }}
              onFilterDelete={() => {
                let newFilters = shallowCopy(filterState).filter((prevFilter) => prevFilter.id !== filter.id);
                dispatch(setFilterState(newFilters));
                dispatch(updateContactTable({ ...meta, filterState: newFilters }));
              }}
            />
          );
        }
      })}
      <NewFilter
        table={table}
        onNewFilter={(col, value) => {
          const currFilter = filterState.find((filter) => filter.id === col.id);
          if (currFilter === undefined) {
            let newFilters = [...filterState, { id: col.id, value: value }];
            dispatch(setFilterState(newFilters));
            dispatch(updateContactTable({ ...meta, filterState: newFilters }));
          } else if (["text", "url", "number"].includes(col.columnDef.meta!.type)) {
            let newFilters = shallowCopy(filterState).map((filter) => {
              if (filter.id === currFilter.id) {
                return { id: filter.id, value };
              } else {
                return filter;
              }
            });
            dispatch(setFilterState(newFilters));
            dispatch(updateContactTable({ ...meta, filterState: newFilters }));
          }
          if (["status", "select", "multi-select", "person", "multi-person"].includes(col.columnDef.meta!.type))
            updateActiveFilterOpen(col.id);
        }}
      />
    </HStack>
  );
};

export default Filters;
