import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { get, post, put } from "api/requests";
import { BackendColumnDef, BackendContactTable, ContactTableMeta, ContactTableState } from "./contactTableTypes";
import { Nullable } from "types/types";
import { ColumnFiltersState, SortingState } from "@tanstack/react-table";

export const getContactTable = createAsyncThunk("contact-table/getContactTable", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/contact-table/`;
  return await get(url);
});

export const getContactTableColumn = createAsyncThunk("contact-table/getContactTableColumn", async (colId: string) => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/contact-table/${colId}`;
  return await get(url);
});

export const updateContactTable = createAsyncThunk(
  "contact-table/updateContactTable",
  async (meta: Partial<ContactTableMeta>) => {
    const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/contact-table/`;
    await put(url, { meta });
    // return await get(url);
  }
);

export const addContactTableColumn = createAsyncThunk(
  "contact-table/addContactTableColumn",
  async (body: BackendColumnDef) => {
    const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/contact-table/`;
    await post(url, body);
    return await get(url);
  }
);

export const updateContactTableColumn = createAsyncThunk(
  "contact-table/updateContactTableColumn",
  async (body: Partial<BackendColumnDef>) => {
    const { accessor_key, ...rest } = body;
    const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/contact-table/accessor_key/${accessor_key}`;
    await put(url, rest);
    return await get(url);
  }
);

//PENDING

const isFetching = (key: string) => (state: ContactTableState) => {
  state.status[key] = "fetching";
};

//FULFILLED

const gotContactTable = (key: string) => (state: ContactTableState, action: PayloadAction<BackendContactTable>) => {
  delete state.errors[key];
  state.contactTable.columns = action.payload.columns;
  state.contactTable.meta = action.payload.meta;
  if (key === "primary") {
    if (action.payload.meta.sortState) state.sortState = action.payload.meta.sortState;
    if (action.payload.meta.filterState) state.filterState = action.payload.meta.filterState;
  }
  state.status[key] = "fulfilled";
};

const gotContactTableColumn = (key: string) => (state: ContactTableState, action: PayloadAction<BackendColumnDef>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];
  state.currentColumn = action.payload;
};

//REJECTED

const notGotContactTable = (key: string) => (state: ContactTableState, action: any) => {
  state.status[key] = "rejected";
  state.errors[key] = action.error.message;
};

const initialState: ContactTableState = {
  contactTable: {
    columns: [],
    meta: { sortState: [], filterState: [], columnOrder: [], primaryColumnsHiddenState: [] },
  },
  currentColumn: null,
  sortState: [],
  filterState: [],
  errors: {},
  status: {},
};

export const contactTableSlice = createSlice({
  name: "contact-table",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
    setSortState: (state, action: PayloadAction<SortingState>) => {
      state.sortState = action.payload;
    },
    setFilterState: (state, action: PayloadAction<ColumnFiltersState>) => {
      state.filterState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContactTable.pending, isFetching("primary"))
      .addCase(getContactTable.fulfilled, gotContactTable("primary"))
      .addCase(getContactTable.rejected, notGotContactTable("primary"))

      .addCase(getContactTableColumn.pending, isFetching("get-column"))
      .addCase(getContactTableColumn.fulfilled, gotContactTableColumn("get-column"))
      .addCase(getContactTableColumn.rejected, notGotContactTable("get-column"))

      // .addCase(updateContactTable.pending, isFetching("update"))
      // .addCase(updateContactTable.fulfilled, gotContactTable("update"))
      // .addCase(updateContactTable.rejected, notGotContactTable("update"))

      .addCase(addContactTableColumn.pending, isFetching("add"))
      .addCase(addContactTableColumn.fulfilled, gotContactTable("add"))
      .addCase(addContactTableColumn.rejected, notGotContactTable("add"));
  },
});

export const { resetStatus, resetError, setSortState, setFilterState } = contactTableSlice.actions;
export default contactTableSlice.reducer;
