import { Box, BoxProps, HStack, Stack, Text } from "@chakra-ui/react";
import { MeetingResultsSection as MeetingResultsSectionType } from "components/meetings/types";
import MeetingResultsBullet from "./MeetingResultsBullet";
import IconButton from "components/base/IconButton";
import { ReactComponent as SparkleIcon } from "assets/icons/sparkle.svg";
import { useState } from "react";
import MeetingResultsAIBullets from "./MeetingResultsAIBullets";
import MeetingResultsTalkingPoint from "./MeetingResultsTalkingPoint";

interface MeetingResultsSectionProps extends BoxProps {
  section: MeetingResultsSectionType;
}

const MeetingResultsSection: React.FC<MeetingResultsSectionProps> = ({ section }) => {
  return (
    <Box className="meeting-results-section">
      {section.talking_points.map((tp) => {
        return (
          <Stack key={tp.id} gap={0}>
            <MeetingResultsTalkingPoint name={tp.name} />
            <Box>
              {tp.user_bullets.map((ub) => {
                return (
                  <HStack
                    key={ub.id}
                    color={"gray.700"}
                    fontSize={"xs"}
                    px={3}
                    py={0.5}
                    my={0.5}
                    alignItems={"start"}
                    _hover={{ bgColor: "gray.50" }}
                    borderRadius={4}
                  >
                    <Box as={"span"}>•</Box>
                    <MeetingResultsBullet bullet={ub} type={"user"} />
                  </HStack>
                );
              })}
            </Box>
            <MeetingResultsAIBullets tp={tp} />
          </Stack>
        );
      })}
    </Box>
  );
};

export default MeetingResultsSection;
