import { Box, BoxProps } from "@chakra-ui/react";
import EnterInput from "components/base/EnterInput";
import Tag from "components/base/Tag";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { addMeetingTag, updateMeeting } from "reducers/meeting/meetingReducer";
import { addTag, deleteTag, getTags, updateTag } from "reducers/tags/tagsReducer";
import { AvailableColors } from "types/types";
import { pickRandomItem, shallowCopy } from "utils/common";
import { Meeting } from "./types";
import ComboPopover from "components/base/ComboPopover";
import { BackendTag } from "reducers/tags/tagsTypes";

interface NewTagProps extends BoxProps {
  meeting: Meeting;
  selected: any[];
  ellipsis: boolean;
}

const NewTag: React.FC<NewTagProps> = ({ meeting, selected, ellipsis }) => {
  const dispatch = useAppDispatch();

  const tagState = useAppSelector((state) => state.tag);
  const meetingState = useAppSelector((state) => state.meeting);

  useEffect(() => {
    if (meetingState.status["add_tag"] === "fulfilled") {
      dispatch(getTags());
    }
  }, [meetingState.status["add_tag"]]);

  return (
    <ComboPopover
      options={tagState.payload
        .map((tag) => {
          if (tag.type === "meeting") {
            return { ...tag, type: "multi-select", color: tag.meta.color };
          }
        })
        .sort((a, b) => a!.value.localeCompare(b!.value))}
      selected={selected}
      onItemClick={(item: BackendTag) => {
        dispatch(updateMeeting({ id: meeting.id, tags: [...meeting.tags, item.id] }));
      }}
      type="multi-select"
      onAdd={(value: string) => {
        let tagFound = false;
        for (const tag of tagState.payload) {
          if (tag.value === value) {
            dispatch(updateMeeting({ id: meeting.id, tags: [...meeting.tags, tag.id] }));
            tagFound = true;
            break;
          }
        }
        if (tagFound) return;
        dispatch(
          addMeetingTag({
            id: meeting.id,
            value: value,
            type: "meeting",
            meta: { color: pickRandomItem(AvailableColors) },
          })
        );
      }}
      onRemove={(id: number) => {
        dispatch(updateMeeting({ id: meeting.id, tags: meeting.tags.filter((tagId) => tagId !== id) }));
      }}
    >
      <Tag
        label={ellipsis ? "..." : "# add tag"}
        color="gray"
        cursor={"pointer"}
        onClick={() => {}}
        _hover={{ "& > *": { bgColor: "gray.100" } }}
      />
    </ComboPopover>
  );
};

export default NewTag;
