import { CellContext } from "@tanstack/react-table";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Contact } from "../types";
import Tag from "components/base/Tag";
import { ComboOption, PersonOption, SelectOption } from "./Meta";
import { ComboCellType } from "reducers/contact-table/contactTableTypes";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { updateContact } from "reducers/contact/contactReducer";
import { Meeting } from "components/meetings/types";
import { Flex } from "@chakra-ui/react";
import ComboTags from "components/base/ComboTags";
import ComboPopover from "components/base/ComboPopover";

interface ComboCellProps extends CellContext<Contact, ComboOption[]> {
  multiple: boolean;
}

const ComboCell: React.FC<ComboCellProps> = ({ getValue, row, column, table, multiple }) => {
  const dispatch = useAppDispatch();

  const workspaceState = useAppSelector((state) => state.workspace);
  const meta = column.columnDef.meta;
  const setInitialOptions = () => {
    if (workspaceState.status["primary"] === "fulfilled") {
      if (meta?.type) {
        let tempOptions: ComboOption[] = [];
        if (["person", "multi-person"].includes(meta.type)) {
          tempOptions = workspaceState.usersOption;
        } else if (meta.options) {
          tempOptions = meta.options.values as SelectOption[];
        }

        return tempOptions;
      } else return [];
    } else return [];
  };
  const initialValue = getValue();
  const [selected, setSelected] = useState<ComboCellType[]>(initialValue);
  const [options, setOptions] = useState<ComboOption[]>(setInitialOptions());

  const comboCellRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const comboCell = comboCellRef.current;
    if (!comboCell) return;
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          calcMinWidth();
        }
      }
    });
    observer.observe(comboCell, { childList: true, subtree: true });
    calcMinWidth();
    return () => observer.disconnect();
  }, [comboCellRef.current]);

  const calcMinWidth = () => {
    if (comboCellRef.current) {
      const comboCellTags = comboCellRef.current.querySelectorAll(".combo-cell__tag") as NodeListOf<HTMLDivElement>;
      if (comboCellTags.length > 0) {
        let totalWidth = Array.from(comboCellTags).reduce((acc, tag) => {
          const rect = tag.getBoundingClientRect();
          return acc + rect.width;
        }, 0);
        comboCellRef.current.style.minWidth = `${totalWidth + 50}px`;
      }
    }
  };

  const onItemClick = (newValue: ComboOption) => {
    if (multiple) {
      let prev = selected ? [...selected] : [];
      setSelected([...prev, { value: newValue.id }]);
      table.options.meta?.updateData(row.index, column.id, [...prev, { value: newValue.id }]);
      dispatch(updateContact({ id: row.original.id, columns: { [column.id]: [...prev, { value: newValue.id }] } }));
    } else {
      setSelected([{ value: newValue.id }]);
      table.options.meta?.updateData(row.index, column.id, [{ value: newValue.id }]);
      dispatch(updateContact({ id: row.original.id, columns: { [column.id]: [{ value: newValue.id }] } }));
    }
  };

  const handleRemoveItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, valueToRemove: ComboOption) => {
    e.stopPropagation();
    const updatedSelected = selected.filter((item) => item.value !== valueToRemove.id);
    setSelected(updatedSelected);
    table.options.meta?.updateData(row.index, column.id, updatedSelected);
    dispatch(updateContact({ id: row.original.id, columns: { [column.id]: updatedSelected } }));
  };

  //todo: add onNameChange to ComboPopover
  return (
    <div className="combo-cell" ref={comboCellRef}>
      <ComboPopover options={options} onItemClick={onItemClick} selected={selected} type={meta!.type}>
        <Flex w="100%" h="2.5rem" alignItems={"center"}>
          <ComboTags options={options} selected={selected} onClose={(e, option) => handleRemoveItem(e, option)} />
        </Flex>
      </ComboPopover>
    </div>
  );
};

export default ComboCell;
