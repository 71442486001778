import { HTMLProps } from "react";
import Paper from "../base/Paper";
// import Header from "../Header";

const Workspace = (props: HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props} className="workspace">
      {/* <Header /> */}
      {props.children}
    </div>
  );
};

const Body = (props: HTMLProps<HTMLDivElement>) => {
  let className = "workspace__body";
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
Workspace.Body = Body;

const Menu = (props: HTMLProps<HTMLDivElement>) => {
  return <div className="workspace__menu">{props.children}</div>;
};
Workspace.Menu = Menu;

const Title = (props: HTMLProps<HTMLDivElement>) => {
  let className = "workspace__title";
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
Workspace.Title = Title;

const Titles = (props: HTMLProps<HTMLDivElement>) => {
  let className = "workspace__titles";
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
Workspace.Titles = Titles;

const Buttons = (props: HTMLProps<HTMLDivElement>) => {
  let className = "workspace__buttons";
  if (props.className) {
    className += " " + props.className;
  }

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
};
Workspace.Buttons = Buttons;

Workspace.Paper = Paper;

export default Workspace;
