import { CellContext, Row } from "@tanstack/react-table";
import {
  ColumnType,
  NumberCellType,
  SelectCellType,
  StatusCellType,
  TextCellType,
  UrlCellType,
} from "reducers/contact-table/contactTableTypes";
import Format from "utils/format";
import { Contact } from "../types";
import { ComboOption, SelectOption, StatusOption } from "./Meta";
import StatusCell from "./StatusCell";
import TextCell from "./TextCell";
import DateCell from "./DateCell";
import ComboCell from "./ComboCell";
import UrlCell from "./UrlCell";
import { Meeting } from "components/meetings/types";

export const generateAccessorKey = (name: string) => {
  const hash = (+new Date()).toString(36);
  return `_${Format.snake_case(name)}-${hash}`;
};

export const generateSortingFn = (cellType: ColumnType, options: any) => {
  switch (cellType) {
    case "status":
      return (rowA: Row<Contact>, rowB: Row<Contact>, colId: string) => {
        const typedOptions = options.values as StatusOption[];

        const indexA = typedOptions.findIndex(
          (option) => option.id === (rowA.getValue(colId) as StatusCellType)?.value
        );
        const indexB = typedOptions.findIndex(
          (option) => option.id === (rowB.getValue(colId) as StatusCellType)?.value
        );

        return indexA - indexB;
      };

    case "select":
      return (rowA: Row<Contact>, rowB: Row<Contact>, colId: string) => {
        const typedOptions = options.values as SelectOption[];
        const rowAValue = rowA.getValue(colId) as SelectCellType[];
        const rowBValue = rowB.getValue(colId) as SelectCellType[];

        const indexA =
          rowAValue?.length > 0 ? typedOptions.findIndex((option) => option.id === rowAValue[0].value) : -1;
        const indexB =
          rowBValue?.length > 0 ? typedOptions.findIndex((option) => option.id === rowBValue[0].value) : -1;

        return indexA - indexB;
      };

    case "date":
      return "datetime";

    case "text":
    case "number":
    case "url":
    default:
      return "alphanumeric";
  }
};

export const generateFilterFn = (cellType: ColumnType) => {
  switch (cellType) {
    case "status":
      return (row: Row<Contact>, colId: string, value: string[]) => {
        if (!value || value.length === 0) {
          return true;
        }
        const status = row.getValue(colId) as { value: string };
        return value.includes(status?.value);
      };

    case "select":
    case "multi-select":
    case "person":
    case "multi-person":
      return (row: Row<Contact>, colId: string, value: string[]) => {
        if (!value || value.length === 0) {
          return true;
        }
        const combo = row.getValue(colId) as ComboOption[];
        return combo?.find((c) => value.includes(c.value)) !== undefined;
      };

    case "text":
    case "number":
    case "url":
    default:
      return "includesString";
  }
};

export const generateCell = (cellType: ColumnType, mainColumn: boolean = false) => {
  switch (cellType) {
    case "status":
      return (props: CellContext<Contact, StatusCellType>) => <StatusCell {...props} />;

    case "number":
      return (props: CellContext<Contact, NumberCellType>) => <TextCell {...props} type="number" />;

    case "date":
      return DateCell;

    case "select":
    case "person":
      return (props: CellContext<Contact, ComboOption[]>) => <ComboCell {...props} multiple={false} />;

    case "multi-select":
    case "multi-person":
      return (props: CellContext<Contact, ComboOption[]>) => <ComboCell {...props} multiple={true} />;

    case "url":
      return (props: CellContext<Contact, UrlCellType>) => <UrlCell {...props} />;

    case "text":
    default:
      return (props: CellContext<Contact, TextCellType>) => <TextCell {...props} type="text" mainColumn={mainColumn} />;
  }
};
