import React, { forwardRef, useEffect, useRef, useState } from "react";
import { current } from "@reduxjs/toolkit";
import { Box, HStack } from "@chakra-ui/react";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import IconButton from "./IconButton";

const DateCustomInput: React.ForwardRefRenderFunction<HTMLDivElement, any> = (props, ref) => {
  const { value, onClick, clearDate, wrapperRef } = props;
  const [width, setWidth] = useState("140px");

  useEffect(() => {
    if (wrapperRef) setWidth(wrapperRef.getBoundingClientRect().width);
  }, [wrapperRef]);

  return (
    <span ref={ref} style={{ display: "flex" }} onClick={onClick} className="date-cell__picker">
      {value ? (
        <HStack _hover={{ "& .date-close": { opacity: 1, visibility: "visible" } }}>
          <Box as="span" px={3} py={1}>
            {value}
          </Box>
          <IconButton
            className="date-close"
            icon={CloseIcon}
            opacity={0}
            visibility="hidden"
            onClick={(e) => {
              e.stopPropagation();
              clearDate();
            }}
          />
        </HStack>
      ) : (
        <Box w={width} h={"35px"} />
      )}
    </span>
  );
};

export default forwardRef(DateCustomInput);
