import { Box, BoxProps, HStack, Input, Text } from "@chakra-ui/react";
import IconButton from "components/base/IconButton";
import { useState } from "react";
import { ReactComponent as SparkleIcon } from "assets/icons/sparkle.svg";
import MeetingResultsBullet from "./MeetingResultsBullet";
import DynamicTextarea from "components/base/DynamicTextarea";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";

interface MeetingResultsAIBulletsProps extends BoxProps {
  tp: {
    id: string;
    name: string;
    prompt?: string;
    user_bullets: {
      id: string;
      value: string;
    }[];
    ai_bullets: {
      id: string;
      value: string;
    }[];
  };
}

const MeetingResultsAIBullets: React.FC<MeetingResultsAIBulletsProps> = ({ tp }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [prompt, setPrompt] = useState(tp.prompt);

  if (tp.prompt) {
    return (
      <Box
        bgColor={showPrompt ? "echoBlue.50" : undefined}
        borderRadius={4}
        pr={12}
        pos={"relative"}
        _hover={{
          "& .prompt-icon": { visibility: "visible", opacity: 1 },
          "& .play-icon": {
            visibility: showPrompt && prompt !== tp.prompt ? "visible" : undefined,
            opacity: showPrompt && prompt !== tp.prompt ? 1 : undefined,
          },
          bgColor: "echoBlue.50",
        }}
      >
        <IconButton
          className="play-icon"
          icon={PlayIcon}
          pos={"absolute"}
          top={"2px"}
          right={"2px"}
          transform={"translateX(-100%)"}
          iconSize="sm"
          color={showPrompt ? "echoBlue.500" : "gray.700"}
          onClick={() => {
            // setShowPrompt((prev) => !prev);
          }}
          visibility={"hidden"}
          opacity={0}
          zIndex={100}
        />
        <IconButton
          className="prompt-icon"
          icon={SparkleIcon}
          pos={"absolute"}
          top={"2px"}
          right={"2px"}
          iconSize="sm"
          color={showPrompt ? "echoBlue.500" : "gray.700"}
          onClick={() => {
            setShowPrompt((prev) => !prev);
          }}
          visibility={"hidden"}
          opacity={0}
          zIndex={100}
        />
        <HStack
          className="prompt"
          fontSize={"xs"}
          px={2}
          transition={"opacity 0.3s ease, visibility 0.3s ease, max-height 0.3s ease"}
          overflow={"hidden"}
          pt={showPrompt ? 1 : 0}
          visibility={showPrompt ? "visible" : "hidden"}
          opacity={showPrompt ? 1 : 0}
          maxH={showPrompt ? "500px" : 0}
          alignItems={"start"}
        >
          <Text as={"span"} color={"echoBlue.500"}>
            Prompt:{" "}
          </Text>
          <DynamicTextarea
            value={prompt}
            onChange={(e) => {}}
            onBlur={(e) => {
              if (e.target.value.length === 0) setShowPrompt(false);
            }}
            size={"xs"}
            variant={"unstyled"}
            color={"gray.700"}
            fontSize={"xs"}
            py={"1px"}
            resize={"none"}
            fullWidth={true}
            flex={1}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              } else if (e.key === "Escape") {
                e.stopPropagation();
                e.currentTarget.blur();
              }
            }}
            onFocus={(e) => e.target.blur()}
          />
        </HStack>
        {tp.ai_bullets.map((ab) => {
          return (
            <HStack key={ab.id} color={"echoBlue.500"} fontSize={"xs"} px={3} py={0.5} my={0.5} alignItems={"start"}>
              <Box as={"span"}>•</Box>
              <MeetingResultsBullet bullet={ab} type="ai" />
            </HStack>
          );
        })}
      </Box>
    );
  } else {
    return <></>;
  }
};

export default MeetingResultsAIBullets;
