import { Box, BoxProps, HStack, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FunctionComponent, MouseEventHandler, ReactNode, SVGProps, forwardRef } from "react";
import Icon from "./Icon";

interface BadgeProps extends BoxProps {
  children?: ReactNode;
  icon?: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  onClick?: MouseEventHandler<HTMLDivElement>;
  iconColor?: string;
  input?: boolean;
  placeholder?: string;
  inputValue?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  darkHover?: boolean;
  borderFull?: boolean;
  disabled?: boolean;
  color?: string;
}

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      children,
      icon,
      onClick,
      input,
      placeholder,
      iconColor,
      color,
      inputValue,
      onInputChange,
      darkHover,
      borderFull,
      disabled,
      ...rest
    },
    ref
  ) => {
    if (input) {
      return (
        <InputGroup flex={0}>
          {icon && (
            <InputLeftElement h={"1.5rem"}>
              <Icon as={icon} color={iconColor} />
            </InputLeftElement>
          )}
          <Input
            ref={ref}
            size={"xs"}
            border={"1px"}
            borderColor={"gray.100"}
            fontWeight={500}
            px={1.5}
            ml={1.5}
            borderRadius={6}
            onClick={onClick}
            w={"fit-content"}
            placeholder={placeholder}
            color={"gray.800"}
            outline={"1px"}
            _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
            value={inputValue}
            onChange={onInputChange}
            {...rest}
          />
        </InputGroup>
      );
    } else {
      return (
        <Box
          ref={ref}
          fontSize={"xs"}
          border={"1px"}
          borderColor={"gray.100"}
          fontWeight={500}
          color={disabled ? "gray.400" : color ? color : "gray.500"}
          px={1.5}
          borderRadius={borderFull ? "full" : 6}
          bgColor={"white"}
          _hover={onClick && !disabled ? { color: "echoBlue.500", "& path": { fill: "echoBlue.500" } } : {}}
          _active={onClick && !disabled ? { color: "echoBlue.600", "& path": { fill: "echoBlue.600" } } : {}}
          onClick={onClick}
          cursor={onClick && !disabled ? "pointer" : "default"}
          w={"fit-content"}
          {...rest}
        >
          {icon ? (
            <HStack gap={1}>
              <Icon as={icon} fontSize={14} color={disabled ? "gray.400" : iconColor} />
              {children}
            </HStack>
          ) : (
            <>{children}</>
          )}
        </Box>
      );
    }
  }
);

Badge.displayName = "Badge";

export default Badge;
