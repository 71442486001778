import { ModalContent, ModalOverlay, Modal, ModalFooter, Button, ModalHeader, ModalBody } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContactTableColumn } from "reducers/contact-table/contactTableReducer";
import { Column, Table } from "@tanstack/react-table";
import { Contact } from "../../types";

interface DeleteModalProps {
  table: Table<Contact>;
  isOpen: boolean;
  onClose: () => void;
  colId: string;
}

const DeleteColModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, colId, table }) => {
  const dispatch = useAppDispatch();
  const [col, setCol] = useState<Column<Contact, unknown> | undefined>(undefined);

  useEffect(() => {
    if (isOpen) {
      setCol(table.getColumn(colId));
    }
  }, [isOpen]);

  const handleDelete = () => {
    if (col?.columnDef.meta) {
      col.columnDef.meta.archived = true;
      if (table.options.meta?.updateColumn) table.options.meta?.updateColumn(col.columnDef);
      dispatch(updateContactTableColumn({ accessor_key: colId, archived: true }));
      table.options.meta!.removeFromColumnOrder(colId);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}} isCentered={true}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete column</ModalHeader>
        <ModalBody>Are you sure you want to delete column '{col?.columnDef.header as ReactNode}'?</ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="outline" colorScheme="red">
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteColModal;
