import {
  AvatarGroup,
  Box,
  BoxProps,
  Button,
  Center,
  Checkbox,
  HStack,
  SimpleGrid,
  Text,
  forwardRef,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { ActionItem as ActionItemType } from "reducers/action-item/actionItemTypes";
import { formatDate } from "utils/common";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import Badge from "components/base/Badge";
import TooltipAvatar from "components/base/TooltipAvatar";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deleteActionItem, updateActionItem } from "reducers/action-item/actionItemReducer";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import ComboPopover from "components/base/ComboPopover";
import EnterInput from "components/base/EnterInput";
import { useState } from "react";
import Avatar from "components/base/Avatar";
import { PersonOption } from "components/contacts/table/Meta";

interface ActionItemProps extends BoxProps {
  actionItem: ActionItemType;
  onToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDateChange: (date: Date | null) => void;
  onAssigneesChange: (assignees: number[]) => void;
  onNameChange: (name: string) => void;
}

const ActionItem: React.FC<ActionItemProps> = ({
  actionItem,
  onToggle,
  onDateChange,
  onAssigneesChange,
  onNameChange,
}) => {
  const dispatch = useAppDispatch();

  const meetingState = useAppSelector((state) => state.meeting);
  const workspaceState = useAppSelector((state) => state.workspace);

  const [name, setName] = useState(actionItem.name);

  const renderAssignees = () => {
    return (
      <AvatarGroup size={"xs"} spacing={"-0.5rem"}>
        {actionItem.assignees?.map((assignee) => {
          const user = workspaceState.users.find((user) => user.id === assignee);
          if (user)
            return (
              <TooltipAvatar
                onClick={() => {
                  onAssigneesChange(actionItem.assignees.filter((old) => old !== assignee));
                }}
                key={user.id}
                name={user.first_name + " " + user.last_name}
                cursor={"pointer"}
              />
            );
        })}
        {actionItem.assignees.length < workspaceState.usersOption.length && (
          <ComboPopover
            options={workspaceState.usersOption}
            type="multi-person"
            onItemClick={(item: PersonOption) => {
              onAssigneesChange([...actionItem.assignees, Number(item.id)]);
            }}
            selected={actionItem.assignees.map((assignee) => {
              return { value: assignee.toString() };
            })}
            onRemove={(id) => {
              onAssigneesChange(actionItem.assignees.filter((old) => old !== Number(id)));
            }}
          >
            <Avatar
              sx={
                actionItem.done
                  ? { bgColor: "gray.100", color: "gray.400" }
                  : { bgColor: "gray.200", color: "gray.800" }
              }
              _hover={actionItem.done ? { bgColor: "gray.200" } : { bgColor: "gray.300" }}
              size={"xs"}
              name="+"
              cursor={"pointer"}
            />
          </ComboPopover>
        )}
      </AvatarGroup>
    );
  };

  const DateButton = forwardRef(({ onClick, clearDate }, ref) => {
    return (
      <Center onClick={onClick} ref={ref} cursor={"pointer"}>
        {actionItem.due_date ? (
          <HStack transform={"translateX(1rem)"} _hover={{ "& .date-close": { opacity: 1, visibility: "visible" } }}>
            <Box as="span">Due: {formatDate(actionItem.due_date)}</Box>
            <IconButton
              className="date-close"
              icon={CloseIcon}
              opacity={0}
              visibility="hidden"
              onClick={(e) => {
                e.stopPropagation();
                clearDate();
              }}
            />
          </HStack>
        ) : (
          <Badge icon={AddIcon} iconColor={actionItem.done ? "gray.400" : "gray.800"} onClick={() => {}}>
            <Text color={actionItem.done ? "gray.400" : "gray.800"} fontSize={"sm"} py={0.5}>
              Add due date
            </Text>
          </Badge>
        )}
      </Center>
    );
  });

  return (
    <SimpleGrid
      templateColumns={"6fr 2fr 4fr 4fr 1fr"}
      key={actionItem.id}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      fontSize={"sm"}
      fontWeight={500}
      color={"gray.800"}
      px={4}
      py={1}
      pos={"relative"}
      _hover={{ "& .icon-button": { display: "flex" }, bgColor: "gray.50" }}
    >
      <HStack>
        <Checkbox defaultChecked={actionItem.done} onChange={onToggle} />
        <EnterInput
          className="enter-input"
          color={actionItem.done ? "gray.400" : ""}
          noBorder
          mb={0}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={(e) => {
            onNameChange(e.target.value);
          }}
          // autoFocus
        />
      </HStack>
      <Center>{renderAssignees()}</Center>
      <Center color={actionItem.done ? "gray.400" : ""}>
        <DatePicker
          selected={new Date()}
          onChange={onDateChange}
          customInput={<DateButton clearDate={() => onDateChange(null)} />}
        />
      </Center>
      <Center>
        <HStack>
          {actionItem.created_on_meeting && (
            <Badge icon={LinkIcon} iconColor={actionItem.done ? "gray.400" : "gray.800"} onClick={() => {}}>
              <Text color={actionItem.done ? "gray.400" : "gray.800"} fontSize={"sm"} py={0.5}>
                {meetingState.meetings.find((meeting) => Number(meeting.id) === actionItem.created_on_meeting)?.name}
              </Text>
            </Badge>
          )}
        </HStack>
      </Center>
      <Center>
        <IconButton
          icon={CloseIcon}
          className="icon-button"
          display={"none"}
          onClick={() => {
            dispatch(deleteActionItem(actionItem.id));
          }}
        />
      </Center>
    </SimpleGrid>
  );
};

export default ActionItem;
