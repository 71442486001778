import { FormikProps } from "formik";

function checkOnlyNumbers(value: string) {
  return /^[0-9]*$/.test(value);
}

function checkHasSpecialCharacters(value: string) {
  return /[!@#$%^&*()_\n]/.test(value);
}

function checkHasSpace(value: string) {
  return /\s/.test(value);
}

function checkEmpty(value: string) {
  return value.length === 0;
}

function checkLength(value: string, min: number, max: number) {
  return value.length >= min && value.length <= max;
}

function removeDashes(value: string) {
  return value.replaceAll("-", "");
}

function checkValidEmail(value: string) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  );
}

const Validate = {
  entityIdentifier: (value: string) => {
    let entity = "identifier";
    let errors = [];
    if (checkEmpty(value)) {
      errors.push({ entity, msg: "Please provide an identifier" });
    }
    if (checkHasSpecialCharacters(value)) {
      errors.push({
        entity,
        msg: "The usage of the special characters !@#$%^&*()_ is not allowed",
      });
    }
    if (checkHasSpace(value)) {
      errors.push({
        entity,
        msg: "Whitespace is not allowed",
      });
    }
    return errors;
  },
  entityName: (value: string) => {
    let entity = "name";
    let errors = [];
    if (checkEmpty(value)) {
      errors.push({
        entity,
        msg: "Please provide a name",
      });
    }
    if (checkHasSpecialCharacters(value)) {
      errors.push({
        entity,
        msg: "The usage of the special characters !@#$%^&*()_ is not allowed",
      });
    }
    return errors;
  },
  entityDescription: (value: string) => {
    let entity = "description";
    let errors = [];
    if (checkEmpty(value)) {
      errors.push({ entity, msg: "Please provide description" });
    }
    return errors;
  },
  entityPrice: (value: string) => {
    let entity = "price";
    let errors = [];
    if (!checkOnlyNumbers(value)) {
      errors.push({ entity, msg: "Invalid price" });
    }
    return errors;
  },
  entityPassword: (password: string, confirmPassword: string) => {
    let entity = "password";
    let errors = [];
    const max = 5;
    if (checkLength(password, 1, max)) {
      errors.push({ entity, msg: `Password must be at least ${max + 1} characters` });
    }
    if (password !== confirmPassword) {
      errors.push({ entity, msg: "Passwords don't match" });
    }
    return errors;
  },
  APIKey: (value: string) => {
    if (checkEmpty(value) || typeof value !== "string") {
      return "API Key must be a string";
    }
  },
  featureLimit: (value: string) => {
    if (!checkOnlyNumbers(value) && value !== "Unlimited" && !checkEmpty(value)) {
      return "Limits should be a number or 'Unlimited'";
    }
  },
  email: (value: string) => {
    let errors = [];
    if (checkEmpty(value)) {
      errors.push("Please provide an email address");
    }
    if (!checkValidEmail(value)) {
      errors.push("Invalid email address");
    }
    return errors;
  },
};

export const parseServerError = (error: string) => {
  switch (error) {
    case "401":
      return "Wrong credentials";
    case "403":
      return "Unauthorized";
    case "409":
      return "Identifier duplicated";
    case "422":
      return "Email not valid";
    case "500":
      return "Server error. Please try again later";

    default:
      return "";
  }
};

const checkFalsy = (value: any) => {
  return ![false, 0, -0, "", null, undefined, NaN].includes(value);
};

export const getValidationProps = (value: string, formik: FormikProps<any>, onlyInvalid = false) => {
  if (onlyInvalid) {
    return {
      isInvalid: formik.touched[value] && checkFalsy(formik.errors[value]),
    };
  }
  return {
    isValid: formik.touched[value] && !checkFalsy(formik.errors[value]),
    isInvalid: formik.touched[value] && checkFalsy(formik.errors[value]),
  };
};

export default Validate;
