import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userReducer";
import uiConfigReducer from "./ui-config/uiConfigReducer";
import contactTableReducer from "./contact-table/contactTableReducer";
import contactReducer from "./contact/contactReducer";
import meetingReducer from "./meeting/meetingReducer";
import meetingTemplateReducer from "./meeting-template/meetingTemplateReducer";
import tagsReducer from "./tags/tagsReducer";
import actionItemReducer from "./action-item/actionItemReducer";
import workspaceReducer from "./workspace/workspaceReducer";
import googleReducer from "./google/googleReducer";

const combinedReducer = combineReducers({
  user: userReducer,
  uiConfig: uiConfigReducer,
  contactTable: contactTableReducer,
  contact: contactReducer,
  meeting: meetingReducer,
  meetingTemplate: meetingTemplateReducer,
  tag: tagsReducer,
  actionItem: actionItemReducer,
  workspace: workspaceReducer,
  google: googleReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "user/Logout/fulfilled") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
