import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { resetError, login, getUserInfo } from "reducers/user/userReducer";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { parseServerError } from "utils/validate";
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import IconButton from "components/base/IconButton";
import { ReactComponent as EyeOnIcon } from "assets/icons/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "assets/icons/eye-off.svg";

interface LoginProps extends Omit<ModalProps, "children"> {}

interface LoginValues {
  email: string;
  password: string;
}

const Login: React.FC<LoginProps> = ({ ...rest }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");

  //TODO: add setPasswordVis
  const [passwordVis, setPasswordVis] = useState(false);

  useEffect(() => {
    switch (user.status["connect"]) {
      case "fetching":
        setIsFetching(true);
        setErrMsg("");
        dispatch(resetError("connect"));
        break;

      case "fulfilled":
        setTimeout(() => {
          setIsFetching(false);
          dispatch(getUserInfo());
        }, 350);
        break;

      case "rejected":
        setIsFetching(false);
        if (user.errors["connect"]) setErrMsg(user.errors["connect"]);
        break;

      default:
        break;
    }
  }, [user.status["connect"]]);

  const initialValues = {
    email: "",
    password: "",
  };

  const schema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
  });

  const handleLogin = (values: LoginValues) => {
    const { email, password } = values;
    const body = { email: email, password };
    dispatch(login(body));
  };

  const renderErrorMessage = (formik: any) => {
    let message = "";
    if (formik.touched.email && formik.errors.email) {
      message = formik.errors.email;
    } else if (formik.touched.password && formik.errors.password) {
      message = formik.errors.password;
    } else if (errMsg) {
      message = parseServerError(errMsg);
    }
    return (
      <Text alignSelf={"start"} ml={2} mb={2} fontSize={"xs"} color={"red.500"} fontWeight={500}>
        {message}
      </Text>
    );
  };

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <ModalContent maxW={"25rem"}>
        <ModalHeader>Login</ModalHeader>
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleLogin}>
          {(formik) => (
            <>
              <ModalBody>
                <form id="login-form" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                  <Stack gap={2} justifyContent={"center"}>
                    <Input
                      autoComplete="off"
                      autoFocus
                      id="email"
                      name="email"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      fontSize={"sm"}
                      size={"sm"}
                      color={"gray.800"}
                      borderRadius={4}
                      outline={"1px"}
                      _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                    />
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        id="password"
                        name="password"
                        placeholder="Password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        fontSize={"sm"}
                        size={"sm"}
                        color={"gray.800"}
                        borderRadius={4}
                        outline={"1px"}
                        _focusVisible={{ boxShadow: "unset", borderColor: "echoBlue.500", bg: "white" }}
                        type={passwordVis ? "text" : "password"}
                      />
                      <InputRightElement p={0} h={8} w={12}>
                        <IconButton
                          icon={passwordVis ? EyeOffIcon : EyeOnIcon}
                          onClick={() => setPasswordVis((prev) => !prev)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Stack>
                </form>
              </ModalBody>
              <ModalFooter flexDir={"column"}>
                {renderErrorMessage(formik)}
                <Button colorScheme="echoBlue" form="login-form" type="submit" w={"80%"}>
                  Login
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default Login;
