import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  BoxProps,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import {
  getActionItems,
  getMeetingActionItems,
  resetStatus,
  updateActionItem,
} from "reducers/action-item/actionItemReducer";
import { ActionItem as ActionItemType } from "reducers/action-item/actionItemTypes";
import { sortByDate } from "utils/common";
import Format from "utils/format";
import ActionItem from "components/action-items/ActionItem";

interface MeetingTasksProps extends BoxProps {
  meetingId: number;
  cache: { [key: string]: boolean };
  setCache: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  searchTerm: string | null;
}

const MeetingTasks: React.FC<MeetingTasksProps> = ({ meetingId, cache, searchTerm, setCache }) => {
  const dispatch = useAppDispatch();
  const actionItemState = useAppSelector((state) => state.actionItem);

  const [actionItems, setActionItems] = useState<{ todo: ActionItemType[]; done: ActionItemType[] }>({
    todo: [],
    done: [],
  });

  useEffect(() => {
    if (!cache["tasks"] && meetingId) {
      dispatch(getMeetingActionItems(meetingId));
      setCache((prev) => {
        return { ...prev, tasks: true };
      });
    }
  }, [meetingId]);

  useEffect(() => {
    const sorted: ActionItemType[] = sortByDate(actionItemState.meetingActionItems, "created");

    const filtered: ActionItemType[] = searchTerm
      ? sorted.filter((actionItem) => actionItem.name.toLowerCase().includes(searchTerm.toLowerCase()))
      : sorted;
    setActionItems({
      todo: filtered.filter((ai) => !ai.done),
      done: filtered.filter((ai) => ai.done),
    });
    if (actionItemState.status["add_meeting"] === "fulfilled") {
      setTimeout(() => {
        const lastTodo = document.querySelector(".accordion-panel--todo")?.lastChild as HTMLDivElement;
        if (lastTodo) {
          (lastTodo.querySelector(".enter-input > input") as HTMLInputElement)?.focus();
        }
      }, 200);
      dispatch(resetStatus("add_meeting"));
    }
  }, [actionItemState.meetingActionItems, searchTerm]);

  return (
    <Accordion allowMultiple defaultIndex={[0]} sx={{ "& .chakra-collapse": { overflow: "visible !important" } }}>
      {["todo", "done"].map((key) => {
        return (
          <AccordionItem key={key}>
            <AccordionButton py={1} color={"gray.400"} bgColor={"gray.50"} _hover={{ bgColor: "gray.100" }}>
              <AccordionIcon fontSize={"sm"} mt={0.5} mr={2} />
              <Box as="span" flex="1" textAlign="left" fontSize={"sm"} fontWeight={500}>
                {Format.capitalize(key)} ({actionItems[key as "todo" | "done"].length})
              </Box>
            </AccordionButton>
            <AccordionPanel
              p={0}
              color={"gray.800"}
              className={"accordion-panel--" + key}
              borderTop={"1px"}
              borderColor={"gray.100"}
            >
              {actionItems[key as "todo" | "done"].map((actionItem) => (
                <ActionItem
                  key={actionItem.id}
                  actionItem={actionItem}
                  onToggle={() => {
                    dispatch(updateActionItem({ id: actionItem.id, done: !actionItem.done }));
                  }}
                  onDateChange={(date) => {
                    dispatch(
                      updateActionItem({
                        id: actionItem.id,
                        due_date: date ? date.toISOString().slice(0, -1) : null,
                      })
                    );
                  }}
                  onAssigneesChange={(assignees) => {
                    dispatch(
                      updateActionItem({
                        id: actionItem.id,
                        assignees: assignees,
                      })
                    );
                  }}
                  onNameChange={(name) => {
                    dispatch(
                      updateActionItem({
                        id: actionItem.id,
                        name,
                      })
                    );
                  }}
                />
              ))}
            </AccordionPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MeetingTasks;
