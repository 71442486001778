import React from "react";
import ReactDOM from "react-dom/client";
import "./sass/sass_index.scss";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "reducers/store";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  process.env.REACT_APP_MODE === "dev" ? (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </Provider>
  ) : (
    <React.StrictMode>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Provider>
    </React.StrictMode>
  )
);
