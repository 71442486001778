import {
  Box,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import Badge from "components/base/Badge";
import { ReactComponent as MeetingPlanIcon } from "assets/icons/meeting-plan.svg";
import { useAppSelector } from "hooks/reduxHooks";
import { useEffect, useState } from "react";
import { MeetingTemplate } from "components/meetings/types";
import MeetingPlan from "./MeetingPlan";
import { generateTimeBasedHash } from "utils/common";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface GroupedTemplates {
  [type: string]: MeetingTemplate[];
}

type MeetingTemplateTypes = "customer_success" | "sales" | "internal";

const MeetingTemplateTypeMap = {
  customer_success: "Customer Success",
  sales: "Sales",
  internal: "Internal",
};

interface MeetingTemplateModalProps extends Omit<ModalProps, "children"> {
  onUse: (mt: MeetingTemplate) => void;
}

const MeetingTemplateModal: React.FC<MeetingTemplateModalProps> = ({ isOpen, onClose, onUse }) => {
  const meetingTemplateState = useAppSelector((state) => state.meetingTemplate);

  const [selected, setSelected] = useState<number | null>(null);
  const [templateGroups, setTemplateGroups] = useState<GroupedTemplates>({});
  const [initialRender, setInitialRender] = useState(true);

  function groupMeetingTemplatesByType() {
    const initialAcc: GroupedTemplates = {
      "Customer Success": [],
      Sales: [],
      Internal: [],
    };

    // Process the templates to group them by type
    return meetingTemplateState.meetingTemplates.reduce((acc: GroupedTemplates, mt) => {
      const typeKey = MeetingTemplateTypeMap[mt.meta.type as MeetingTemplateTypes];
      if (typeKey) {
        acc[typeKey].push(mt);
      }
      return acc;
    }, initialAcc);
  }

  useEffect(() => {
    const groups = groupMeetingTemplatesByType();
    setTemplateGroups(groups);
    if (initialRender && Object.keys(groups).length > 0) {
      setInitialRender(false);
      const firstType = Object.keys(groups)[0];
      if (firstType && groups[firstType].length > 0) {
        setSelected(groups[firstType][0].id);
      }
    }
  }, [meetingTemplateState.meetingTemplates]);

  const renderMeetingTemplate = () => {
    const mt = meetingTemplateState.meetingTemplates.find((mt) => mt.id === selected);
    if (mt) {
      return (
        <Box
          bg={"white"}
          p={"14px"}
          m={"14px"}
          borderRadius={8}
          border={"1px"}
          borderColor={"gray.100"}
          maxH={"calc(100% - 28px)"}
          minH={"calc(100% - 28px)"}
          overflow={"auto"}
          sx={{ "&::-webkit-scrollbar": { scrollbarWidth: "none" } }}
        >
          <MeetingPlan key={mt.id} plan={mt} meetingId={undefined} meetingDuration={undefined} viewOnly={true} />
        </Box>
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"xl"}>
      <ModalOverlay />
      <ModalContent maxW="90%" maxH="90%" minH={"90%"}>
        <ModalBody display={"flex"} p={4} flex={1} overflow={"hidden"}>
          <Flex className="modal-menu" pr={4} flex={2} flexGrow={1} flexDir={"column"}>
            <HStack pos={"relative"} alignItems={"center"} justifyContent={"space-between"}>
              <Badge
                icon={MeetingPlanIcon}
                border={0}
                fontWeight={500}
                color="gray.800"
                iconColor="gray.800"
                fontSize={"lg"}
                sx={{ "& svg": { fontSize: "lg" } }}
                p={0}
                mb={2}
              >
                <Text>Templates</Text>
              </Badge>
              <IconButton icon={CloseIcon} transform={"translateY(-3px)"} onClick={onClose} />
            </HStack>
            <Text color={"gray.500"} fontWeight={500} fontSize={"sm"} mb={4}>
              We have created templates especially for you. Click on the template to speed up your work
            </Text>
            <Box flex={1} overflow={"auto"} mb={2} sx={{ "&::-webkit-scrollbar": { scrollbarWidth: "none" } }}>
              {Object.entries(templateGroups).map(([type, templates], index) => {
                if (templates.length > 0) {
                  return (
                    <Box key={type} mb={4}>
                      <Text color={"gray.500"} fontWeight={500} fontSize={"xs"} mb={2}>
                        {type}
                      </Text>
                      {templates.map((mt) => (
                        <Text
                          key={mt.id}
                          color={"gray.800"}
                          fontWeight={500}
                          fontSize={"sm"}
                          mb={2}
                          bg={mt.id === selected ? "gray.100" : ""}
                          _hover={mt.id !== selected ? { bg: "gray.50" } : {}}
                          borderRadius={8}
                          py={2}
                          px={3}
                          cursor={"pointer"}
                          onClick={() => {
                            setSelected(mt.id);
                          }}
                        >
                          {mt.name}
                        </Text>
                      ))}
                    </Box>
                  );
                }
              })}
            </Box>
            <Flex flexDir={"column"} gap={2}>
              <Button
                colorScheme="echoBlue"
                onClick={() => {
                  const mt = meetingTemplateState.meetingTemplates.find((mt) => mt.id === selected);
                  if (mt) {
                    onUse(mt);
                  }
                  onClose();
                }}
              >
                Use this template
              </Button>
              <Button
                color={"gray.500"}
                variant={"outline"}
                onClick={() => {
                  onUse({
                    id: Number(generateTimeBasedHash("new_mp")),
                    name: "",
                    description: "",
                    sections: [
                      {
                        id: generateTimeBasedHash("new_section"),
                        description: "Intro",
                        estimated: 10,
                        talking_points: [{ id: generateTimeBasedHash("new_tp"), name: "", items: [], prompt: null }],
                        type: null,
                      },
                    ],
                    meta: { checked: [] },
                    archived: false,
                    next_version: null,
                  });
                  onClose();
                }}
              >
                Create your own meeting plan
              </Button>
            </Flex>
          </Flex>
          <Box className="modal-template" bg={"gray.50"} borderRadius={8} flex={5} flexGrow={1}>
            {renderMeetingTemplate()}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MeetingTemplateModal;
