import { Box, BoxProps } from "@chakra-ui/react";
import {
  MeetingPlanSection as MeetingPlanSectionType,
  TalkingPoint as TalkingPointType,
} from "components/meetings/types";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import IconButton from "components/base/IconButton";
import { useEffect, useRef, useState } from "react";
import TalkingPoint from "./TalkingPoint";
import { bubbleElement, generateTimeBasedHash } from "utils/common";

interface MeetingPlanSectionProps extends BoxProps {
  section: MeetingPlanSectionType;
  onSectionChange: (section: MeetingPlanSectionType) => void;
  viewOnly?: boolean;
  meta: {
    checked: string[];
    [key: string]: any;
  };
  onCheckedChange: (newValue: string) => void;
  isTemplate?: boolean;
}

const MeetingPlanSection: React.FC<MeetingPlanSectionProps> = ({
  section,
  onSectionChange,
  viewOnly,
  meta,
  onCheckedChange,
  isTemplate,
}) => {
  const [autoFocusIndex, setAutoFocusIndex] = useState(-1);
  const [newSection, setNewSection] = useState<MeetingPlanSectionType | null>(null);

  const sectionRef = useRef(section);

  useEffect(() => {
    sectionRef.current = section; // Keep the ref up-to-date
  }, [section]);

  useEffect(() => {
    if (newSection) {
      onSectionChange(newSection);
      setNewSection(null);
    }
  }, [newSection]);

  const addTalkingPoint = (afterIndex: number = -1) => {
    const newTalkingPoint: TalkingPointType = {
      id: generateTimeBasedHash("new_tp"),
      name: "",
      items: [],
      prompt: null,
    };

    const section = sectionRef.current;

    if (afterIndex !== -1) {
      setAutoFocusIndex(afterIndex + 1);
      let newTalkingPoints = [
        ...section.talking_points.slice(0, afterIndex + 1), // Elements before and including afterIndex
        newTalkingPoint, // New talking point to add
        ...section.talking_points.slice(afterIndex + 1), // Elements after afterIndex
      ];

      setTimeout(() => {
        setNewSection({ ...section, talking_points: newTalkingPoints });
      }, 75);
      // onSectionChange();
    } else {
      setAutoFocusIndex(section.talking_points.length);
      setTimeout(() => {
        setNewSection({ ...section, talking_points: [...section.talking_points, newTalkingPoint] });
      }, 75);
    }
  };

  return (
    <Box className="meeting-plan-section" mb={viewOnly ? 0 : 4} pos={"relative"}>
      {section.talking_points.map((tp, tpIndex) => {
        return (
          <TalkingPoint
            key={tp.id}
            tp={tp}
            index={tpIndex}
            onValueChange={(tp) => {
              let newSection = { ...section };
              let talkingPoints = [...newSection.talking_points];
              if (tp && tp.name.length !== 0) {
                talkingPoints[tpIndex] = tp;
              } else {
                talkingPoints = talkingPoints.slice(0, tpIndex).concat(talkingPoints.slice(tpIndex + 1));
              }
              onSectionChange({ ...newSection, talking_points: talkingPoints });
            }}
            autoFocus={tpIndex === autoFocusIndex}
            onEnter={(tp) => {
              if (tp.name.length === 0) {
                let newSection = { ...section };
                let talkingPoints = [...newSection.talking_points];
                talkingPoints = talkingPoints.slice(0, tpIndex).concat(talkingPoints.slice(tpIndex + 1));
                onSectionChange({ ...newSection, talking_points: talkingPoints });
              } else {
                setTimeout(() => {
                  addTalkingPoint(tpIndex);
                }, 0);
              }
            }}
            initialChecked={meta?.checked?.includes(tp.id) as boolean}
            onCheckedChange={onCheckedChange}
            isTemplate={isTemplate}
            pos={
              section.talking_points.length === 1
                ? "sole"
                : tpIndex === 0
                ? "top"
                : tpIndex === section.talking_points.length - 1
                ? "bottom"
                : "middle"
            }
            onOrderChange={(direction) => {
              const newSection = { ...section };
              const talkingPoints = bubbleElement(newSection.talking_points, tpIndex, direction);
              onSectionChange({ ...newSection, talking_points: talkingPoints });
            }}
          />
        );
      })}
      {!viewOnly && <IconButton icon={AddIcon} onClick={() => addTalkingPoint()} color={"gray.500"} />}
      {viewOnly && <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="transparent" cursor="default" />}
    </Box>
  );
};

export default MeetingPlanSection;
