import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { GoogleState } from "./googleTypes";

export const getGoogleOAuth2 = createAsyncThunk("google-oauth2/getGoogleOAuth2", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/google-oauth2/`;
  return await get(url);
});

export const getGoogleRedirectLink = createAsyncThunk("google-oauth2/getRedirectLink", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/google-oauth2/get-oauth2-redirect`;
  return await get(url);
});

export const connectToRecall = createAsyncThunk("recall/calendar", async () => {
  const url = `${process.env.REACT_APP_TAILORR_API_ADDRESS}/recall/calendar`;
  return await post(url);
});

const pending = (key: extraReducersKey) => (state: GoogleState) => {
  state.status[key] = "fetching";
};

const fulfilled = (key: extraReducersKey) => (state: GoogleState, action: PayloadAction<boolean | string>) => {
  state.status[key] = "fulfilled";
  delete state.errors[key];

  switch (key) {
    case "primary":
      state.oAuth2.connected = action.payload === true;
      break;

    case "get_redirect":
      state.oAuth2.redirect_link = action.payload as string;
      break;

    default:
      break;
  }
};

const rejected = (key: extraReducersKey) => (state: GoogleState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: GoogleState = {
  oAuth2: { connected: false, redirect_link: null },
  errors: {},
  status: {},
};

export const googleOAuth2Slice = createSlice({
  name: "google-oauth2",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getGoogleOAuth2, "primary");
    handleAsyncActions(builder, getGoogleRedirectLink, "get_redirect");
    handleAsyncActions(builder, connectToRecall, "connect_recall");
  },
});

const handleAsyncActions = (
  builder: ActionReducerMapBuilder<GoogleState>,
  actionThunk: any,
  actionKey: extraReducersKey
) => {
  builder
    .addCase(actionThunk.pending, pending(actionKey))
    .addCase(actionThunk.fulfilled, fulfilled(actionKey))
    .addCase(actionThunk.rejected, rejected(actionKey));
};

type extraReducersKey = "primary" | "get_redirect" | "connect_recall";

export const { resetStatus, resetError } = googleOAuth2Slice.actions;
export default googleOAuth2Slice.reducer;
