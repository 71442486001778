import { ReactNode } from "react";
import MyAccount from "components/my-account/MyAccount";

export interface AppRoute {
  path: string;
  component: ReactNode;
}

const routes = [
  {
    path: "/my-account",
    component: <MyAccount />,
  },
];

export default routes;
