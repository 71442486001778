import { useState } from "react";
import Workspace from "../layout/Workspace";
import Profile from "./Profile";

const MyAccount = () => {
  const [tabValue, setTabValue] = useState("profile");

  const renderTitles = () => {
    return (
      <Workspace.Titles>
        <Workspace.Title className={tabValue === "profile" ? "active" : ""} onClick={() => setTabValue("profile")}>
          Profile
        </Workspace.Title>
        <Workspace.Title className={tabValue === "api-keys" ? "active" : ""} onClick={() => setTabValue("api-keys")}>
          Api keys
        </Workspace.Title>
      </Workspace.Titles>
    );
  };

  return (
    <Workspace>
      <Workspace.Body className="my-account">
        {tabValue === "profile" && <Profile renderTitles={renderTitles} />}
      </Workspace.Body>
    </Workspace>
  );
};

export default MyAccount;
