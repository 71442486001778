import { CellContext } from "@tanstack/react-table";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Contact, Status as StatusType } from "../types";
import {
  Box,
  Center,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { StatusOption } from "./Meta";
import { useAppDispatch } from "hooks/reduxHooks";
import { updateContact } from "reducers/contact/contactReducer";
import { Meeting } from "../../meetings/types";
import EnterInput from "components/base/EnterInput";
import Status from "components/base/Status";

interface StatusCellProps extends CellContext<Contact, StatusType> {}

const StatusCell: React.FC<StatusCellProps> = ({ getValue, row, column, table }) => {
  const dispatch = useAppDispatch();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const popContentRef = useRef<HTMLDivElement>(null);

  const initialValue = getValue();
  const [selected, setSelected] = useState<StatusType | null>(initialValue);
  const [searchValue, setSearchValue] = useState("");

  let options: StatusOption[] = [];
  const meta = column.columnDef.meta;
  if (meta?.options) {
    options = meta.options.values as StatusOption[];
  }

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isOpen && popContentRef.current) {
      const enterInput = popContentRef.current.querySelector(".enter-input") as HTMLInputElement;
      setTimeout(() => {
        enterInput?.focus();
      }, 10);
    }
  }, [isOpen, popContentRef.current]);

  const onItemClick = (newValue: StatusOption) => {
    setSelected({ value: newValue.id });
    table.options.meta?.updateData(row.index, column.id, { value: newValue.id });
    dispatch(updateContact({ id: row.original.id, columns: { [column.id]: { value: newValue.id } } }));
    onClose();
  };

  const handleRemoveItem = () => {
    setSelected(null);
    table.options.meta?.updateData(row.index, column.id, null);
    dispatch(updateContact({ id: row.original.id, columns: { [column.id]: null } }));
    onClose();
  };

  const renderMenuItems = (): ReactNode => {
    return options?.map((option) => {
      if (option.value.toLocaleLowerCase().startsWith(searchValue.toLocaleLowerCase())) {
        if (selected?.value !== option.id) {
          return (
            <Center
              className="menu-item"
              cursor={"pointer"}
              // eventKey={option.id}
              justifyContent={"center"}
              key={option.id}
              // p="0.5rem 2.5rem"
              // w="fit-content"
              borderRadius={2}
              color={"gray.600"}
              fontWeight={500}
              h="2rem"
              onClick={() => onItemClick(option)}
              backgroundColor={`var(--chakra-colors-${option.color}-200)`}
            >
              <Text>{option.value}</Text>
            </Center>
          );
        }
      }
    });
  };

  return (
    <Box className="status-cell">
      <Popover gutter={0} isLazy={true} placement="bottom-start" isOpen={isOpen} onClose={onClose}>
        <Center>
          <PopoverTrigger>
            <Box w={"100%"}>
              <Status selected={selected} options={options} onClick={onToggle} />
            </Box>
          </PopoverTrigger>
        </Center>
        <PopoverContent ref={popContentRef} w={"fit-content"}>
          <PopoverBody>
            <Stack gap={1}>
              <EnterInput
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onBlur={(e, isEnterKey) => {
                  if (isEnterKey) {
                    (popContentRef.current?.querySelector(".menu-item") as HTMLDivElement)?.click();
                    onClose();
                  }
                  setTimeout(() => {
                    setSearchValue("");
                  }, 100);
                }}
              />
              {renderMenuItems()}
              <Center onClick={handleRemoveItem} h="2rem" bgColor={"gray.50"} cursor={"pointer"}>
                <Text fontSize={"sm"} color="gray.400">
                  (empty)
                </Text>
              </Center>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default StatusCell;
