import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MeetingPlanSection as MeetingPlanSectionType, MeetingPlan as MeetingPlanType } from "../meetings/types";
import Badge from "components/base/Badge";
import { ReactComponent as MeetingPlanIcon } from "assets/icons/meeting-plan.svg";
import MeetingPlanSectionHeader from "./MeetingPlanSectionHeader";
import MeetingPlanSection from "./MeetingPlanSection";
import DynamicInput from "components/base/DynamicInput";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { getMeetingTemplates } from "reducers/meeting-template/meetingTemplateReducer";
import {
  adjustIndexArrayAfterReorder,
  bubbleElement,
  debounce,
  formatMinutes,
  generateTimeBasedHash,
} from "utils/common";
import { updateMeeting } from "reducers/meeting/meetingReducer";
import MeetingTemplateModal from "./MeetingTemplateModal";
import { ReactComponent as SaveCopyIcon } from "assets/icons/save-copy.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import MeetingPlanSectionMenu from "./MeetingPlanSectionMenu";

interface MeetingPlanProps {
  plan: MeetingPlanType | null | undefined;
  meetingId: string | undefined;
  viewOnly?: boolean;
  isTemplate?: boolean;
  onSaveTemplate?: (asNew: boolean, mp: MeetingPlanType) => void;
  editMode?: boolean;
  onEditTemplate?: (enable: boolean) => void;
  meetingDuration: number | undefined;
}

const default_section: MeetingPlanSectionType = {
  id: generateTimeBasedHash("default_section"),
  estimated: 10,
  description: "Intro",
  talking_points: [
    {
      id: generateTimeBasedHash("default_tp"),
      name: "",
      items: [],
      prompt: null,
    },
  ],
  type: null,
};

const MeetingPlan: React.FC<MeetingPlanProps> = ({
  plan,
  meetingId,
  meetingDuration,
  viewOnly,
  isTemplate,
  onSaveTemplate,
  editMode,
  onEditTemplate,
}) => {
  const dispatch = useAppDispatch();

  const [name, setName] = useState(plan ? plan.name : "");
  const [description, setDescription] = useState(plan ? plan.description : "");
  const [sections, setSections] = useState(plan ? plan.sections : []);
  const [needToSave, setNeedToSave] = useState(false);
  const [accordionIndex, setAccordionIndex] = useState<number[]>([]);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [newSectionIndex, setNewSectionIndex] = useState(-1);
  const [accordionHeight, setAccordionHeight] = useState("80vh");
  const [meta, setMeta] = useState<
    | {
        checked: string[];
        [key: string]: any;
      }
    | { checked: string[] }
  >(plan?.meta ? plan.meta : { checked: [] });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const needToSaveRef = useRef(needToSave);
  const nameRef = useRef(name);
  const descriptionRef = useRef(description);
  const sectionsRef = useRef(sections);
  const metaRef = useRef(meta);
  const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    needToSaveRef.current = needToSave;
  }, [needToSave]);

  useEffect(() => {
    nameRef.current = name;
  }, [name]);

  useEffect(() => {
    descriptionRef.current = description;
  }, [description]);

  useEffect(() => {
    metaRef.current = meta;
  }, [meta]);

  useEffect(() => {
    if (accordionRef.current) {
      setAccordionHeight(`calc(100vh - ${accordionRef.current.getBoundingClientRect().y + 10}px)`);
    }
  }, [accordionRef.current]);

  useEffect(() => {
    sectionsRef.current = sections;
    if (accordionIndex.length === 0) {
      setAccordionIndex(sections.map((_, index) => index));
    }
  }, [sections]);

  useEffect(() => {
    if (plan) {
      setName(plan.name);
      setDescription(plan.description);
      setSections(plan.sections.length > 0 ? plan.sections : [default_section]);
      setMeta(plan.meta);
    } else if (plan === null) {
      setSections([default_section]);
    }
  }, [plan]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
    }
  }, [textareaRef.current, description]);

  const updateSections = (section: MeetingPlanSectionType | null, index: number) => {
    if (plan) {
      let currSections = [...sections];
      if (section === null) {
        currSections = currSections.slice(0, index).concat(currSections.slice(index + 1));
      } else {
        currSections[index] = section;
      }
      setSections(currSections);
      if (!needToSave) setNeedToSave(true);
    }
  };

  const saveChanges = () => {
    if (needToSaveRef.current) {
      if (!isTemplate) {
        dispatch(
          updateMeeting({
            id: Number(meetingId),
            meeting_plan: {
              name: nameRef.current,
              description: descriptionRef.current,
              sections: sectionsRef.current,
              meta: metaRef.current,
            },
          })
        );
      }
      setNeedToSave(false);
    }
  };

  const debouncedSaveChanges = useRef(debounce(saveChanges, 1)).current; //not in use as of now

  useEffect(() => {
    if (needToSave) {
      debouncedSaveChanges();
    }
  }, [name, description, sections, needToSave, debouncedSaveChanges]);

  const toggleAccordion = (index: number) => {
    if (accordionIndex.includes(index)) {
      setAccordionIndex((prev) => prev.filter((prevIndex) => prevIndex !== index));
    } else {
      setAccordionIndex((prev) => [...prev, index]);
    }
  };

  const renderTotalTime = () => {
    const total = sections.reduce((acc, section) => {
      return acc + (section.estimated ? section.estimated : 0);
    }, 0);
    if ((total === 0 && !meetingDuration) || (meetingDuration && meetingDuration <= 0)) return <></>;

    const color = meetingDuration && total > meetingDuration ? "red.400" : "gray.400";

    return (
      <HStack gap={1} mx={2}>
        <Icon as={ClockIcon} fontSize={"sm"} sx={{ "& path": { fill: color } }} />
        <Text color={color} fontSize={"sm"} fontWeight={500}>
          {`${total}${meetingDuration ? "/" + meetingDuration : ""}min`}
        </Text>
      </HStack>
    );
  };

  return (
    <>
      <Stack className="meeting-plan" px={6}>
        <HStack>
          <Center flex={1} justifyContent={"left"}>
            {viewOnly ? (
              <Text fontSize={"xl"} fontWeight={600} color={"gray.800"}>
                {name}
              </Text>
            ) : (
              <DynamicInput
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onBlur={() => {
                  if (!needToSave) setNeedToSave(true);
                }}
                placeholder="Untitled meeting"
                fontSize={"xl"}
                fontWeight={600}
                color={"gray.800"}
              />
            )}
            {renderTotalTime()}
          </Center>
          {!viewOnly && !isTemplate ? (
            <>
              <Badge
                icon={MeetingPlanIcon}
                iconColor="echoBlue.500"
                onClick={() => {
                  dispatch(getMeetingTemplates());
                  setIsTemplateModalOpen(true);
                }}
              >
                <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                  Start from template
                </Text>
              </Badge>
              <Badge
                icon={SaveCopyIcon}
                iconColor="echoBlue.500"
                onClick={() => {
                  onSaveTemplate &&
                    onSaveTemplate(true, {
                      name: nameRef.current,
                      description: descriptionRef.current,
                      sections: sectionsRef.current,
                      meta,
                    });
                }}
              >
                <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                  Save as a new template
                </Text>
              </Badge>
            </>
          ) : (
            <HStack>
              {editMode ? (
                <>
                  <Badge
                    icon={MeetingPlanIcon}
                    iconColor="echoBlue.500"
                    onClick={() => {
                      onSaveTemplate &&
                        onSaveTemplate(false, {
                          name: nameRef.current,
                          description: descriptionRef.current,
                          sections: sectionsRef.current,
                          meta: metaRef.current,
                        });
                    }}
                  >
                    <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                      Save
                    </Text>
                  </Badge>
                  <Badge
                    icon={SaveCopyIcon}
                    iconColor="echoBlue.500"
                    onClick={() => {
                      onSaveTemplate &&
                        onSaveTemplate(true, {
                          name: nameRef.current,
                          description: descriptionRef.current,
                          sections: sectionsRef.current,
                          meta: metaRef.current,
                        });
                    }}
                  >
                    <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                      Save as a new template
                    </Text>
                  </Badge>
                  <IconButton icon={CloseIcon} onClick={() => onEditTemplate && onEditTemplate(false)} />
                </>
              ) : (
                <Badge icon={EditIcon} iconColor="echoBlue.500" onClick={() => onEditTemplate && onEditTemplate(true)}>
                  <Text color="echoBlue.500" fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                    Edit template
                  </Text>
                </Badge>
              )}
            </HStack>
          )}
        </HStack>
        {viewOnly ? (
          <Text color={"gray.500"} fontSize={"sm"} fontWeight={500}>
            {description}
          </Text>
        ) : (
          <Textarea
            ref={textareaRef}
            value={description ? description : ""}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onBlur={() => {
              if (!needToSave) setNeedToSave(true);
            }}
            placeholder="Add description..."
            border={0}
            _focusVisible={{ border: 0 }}
            resize={"none"}
            color={"gray.500"}
            size={"sm"}
            fontWeight={500}
            rows={1}
            overflow={"hidden"}
            sx={{ "&::placeholder": { color: "gray.300" } }}
          />
        )}
        <Divider />
        <Accordion
          ref={accordionRef}
          allowMultiple
          index={accordionIndex}
          height={accordionHeight}
          overflow={"auto"}
          sx={{
            "& .chakra-collapse": { overflow: "auto !important", opacity: "1", height: "auto", display: "block" },
            "&::-webkit-scrollbar": { width: 0 },
          }}
        >
          {sections.map((section, sectionIndex) => {
            return (
              <AccordionItem key={section.id} border={0}>
                <HStack
                  _hover={{
                    "& .section-menu-icon": { visibility: "visible", opacity: 1 },
                  }}
                >
                  <AccordionButton
                    onClick={() => toggleAccordion(sectionIndex)}
                    _hover={{
                      backgroundColor: "gray.50", // Example default hover style
                      "& .section-add": { opacity: 1, visibility: "visible" },
                    }}
                    w={"unset"}
                    flex={1}
                  >
                    <AccordionIcon />
                    <HStack flex={1} justifyContent={"space-between"}>
                      <Box onClick={(e) => e.stopPropagation()} ml={1}>
                        <MeetingPlanSectionHeader
                          section={section}
                          onSectionChange={(newSection) => {
                            updateSections(newSection, sectionIndex);
                            if (newSection === null) {
                              let updated = accordionIndex.filter((index) => index !== sectionIndex);
                              updated = updated.map((index) => {
                                if (index < sectionIndex) return index;
                                return index - 1;
                              });
                              setAccordionIndex(updated);
                            }
                          }}
                          viewOnly={viewOnly}
                          autoFocus={sectionIndex === newSectionIndex}
                        />
                      </Box>
                      {!viewOnly && (
                        <Box
                          onClick={(e) => e.stopPropagation()}
                          className="section-add"
                          opacity={0}
                          visibility="hidden"
                        >
                          <Badge
                            icon={AddIcon}
                            onClick={() => {
                              const newSection: MeetingPlanSectionType = {
                                id: generateTimeBasedHash("new_section"),
                                type: null,
                                description: null,
                                estimated: 10,
                                talking_points: [
                                  { id: generateTimeBasedHash("new_tp"), name: "", items: [], prompt: null },
                                ],
                              };
                              const updatedSections = [...sections];
                              updatedSections.splice(sectionIndex, 0, newSection); // Insert before current

                              setSections(updatedSections);
                              setNeedToSave(true);
                              setNewSectionIndex(sectionIndex);
                              setAccordionIndex((prev) => {
                                const newIndices = prev.map((index) => (index >= sectionIndex ? index + 1 : index));
                                // Insert the new section's index at its position and open it
                                newIndices.splice(sectionIndex, 0, sectionIndex);
                                return newIndices;
                              });
                            }}
                          >
                            <Text fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                              Section above
                            </Text>
                          </Badge>
                        </Box>
                      )}
                    </HStack>
                    {!viewOnly && (
                      <MeetingPlanSectionMenu
                        ml={2}
                        pos={
                          sections.length === 1
                            ? "sole"
                            : sectionIndex === 0
                            ? "top"
                            : sectionIndex === sections.length - 1
                            ? "bottom"
                            : "middle"
                        }
                        onDelete={() => {
                          updateSections(null, sectionIndex);
                          let updated = accordionIndex.filter((index) => index !== sectionIndex);
                          updated = updated.map((index) => {
                            if (index < sectionIndex) return index;
                            return index - 1;
                          });
                          setAccordionIndex(updated);
                        }}
                        onOrderChange={(direction) => {
                          const updatedSections = bubbleElement(sections, sectionIndex, direction);
                          const updatedIndexes = adjustIndexArrayAfterReorder(accordionIndex, sectionIndex, direction);

                          setSections(updatedSections);
                          setAccordionIndex(updatedIndexes);
                          setNeedToSave(true);
                        }}
                      />
                    )}
                  </AccordionButton>
                </HStack>

                <AccordionPanel pb={2}>
                  <MeetingPlanSection
                    section={section}
                    onSectionChange={(section: MeetingPlanSectionType) => {
                      updateSections(section, sectionIndex);
                    }}
                    viewOnly={viewOnly}
                    meta={meta}
                    onCheckedChange={(tpId) => {
                      let currMeta = { ...metaRef.current };
                      let updatedChecked = currMeta.checked ? [...currMeta.checked] : [];
                      if (updatedChecked.includes(tpId)) {
                        updatedChecked = updatedChecked.filter((id) => id !== tpId);
                      } else {
                        // Add the string to the array and return the new array
                        updatedChecked = [...updatedChecked, tpId];
                      }
                      setMeta({ ...currMeta, checked: updatedChecked });
                      if (!needToSave) setNeedToSave(true);
                    }}
                    isTemplate={isTemplate}
                  />
                </AccordionPanel>
              </AccordionItem>
            );
          })}
          {!viewOnly && (
            <Badge
              icon={AddIcon}
              mt={2}
              onClick={() => {
                const newSection: MeetingPlanSectionType = {
                  id: generateTimeBasedHash("new_section"),
                  type: null,
                  description: null,
                  estimated: 10,
                  talking_points: [],
                };
                setSections([...sections, sections.length === 0 ? default_section : newSection]);
                setNeedToSave(true);
                setNewSectionIndex(sections.length);
                setAccordionIndex((prev) => [...prev, sections.length]);
              }}
            >
              <Text fontSize={"sm"} py={0.5} whiteSpace={"nowrap"}>
                Section
              </Text>
            </Badge>
          )}
        </Accordion>
      </Stack>
      <MeetingTemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => {
          setIsTemplateModalOpen(false);
        }}
        onUse={(mt) => {
          dispatch(
            updateMeeting({
              id: Number(meetingId),
              meeting_plan: {
                name: mt.name,
                description: mt.description,
                sections: mt.sections,
                meta: mt.meta,
              },
            })
          );
        }}
      />
    </>
  );
};

export default MeetingPlan;
