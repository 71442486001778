import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  SerializedError,
  ActionReducerMapBuilder,
} from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { ActionItem, ActionItemState } from "./actionItemTypes";
import { addResource, deleteResource, updateResource } from "utils/common";

const API_BASE = process.env.REACT_APP_TAILORR_API_ADDRESS;

export const getActionItems = createAsyncThunk("action-item/getActionItems", async () => {
  const url = `${API_BASE}/action-item/`;
  return await get(url);
});

export const getContactActionItems = createAsyncThunk("action-item/getContactActionItems", async (id: number) => {
  const url = `${API_BASE}/action-item/contact/${id}`;
  return await get(url);
});

export const getMeetingActionItems = createAsyncThunk("action-item/getMeetingActionItems", async (id: number) => {
  const url = `${API_BASE}/action-item/meeting/${id}`;
  return await get(url);
});

export const updateActionItem = createAsyncThunk("action-item/updateActionItem", async (body: Partial<ActionItem>) => {
  const { id, ...rest } = body;
  const url = `${API_BASE}/action-item/${id}`;
  return await put(url, rest);
});

export const addContactActionItem = createAsyncThunk(
  "action-item/addContactActionItem",
  async (body: Partial<ActionItem>) => {
    const url = `${API_BASE}/action-item/`;
    return await post(url, body);
  }
);

export const addMeetingActionItem = createAsyncThunk(
  "action-item/addMeetingActionItem",
  async (body: Partial<ActionItem>) => {
    const url = `${API_BASE}/action-item/`;
    return await post(url, body);
  }
);

export const deleteActionItem = createAsyncThunk("action-item/deleteActionItem", async (id: number) => {
  const url = `${API_BASE}/action-item/${id}`;
  await http_delete(url);
  return id;
});

const pending = (key: extraReducersKey) => (state: ActionItemState) => {
  state.status[key] = "fetching";
};

const fulfilled =
  (key: extraReducersKey) => (state: ActionItemState, action: PayloadAction<ActionItem | ActionItem[] | number>) => {
    state.status[key] = "fulfilled";
    delete state.errors[key];

    switch (key) {
      case "primary":
        state.actionItems = action.payload as ActionItem[];
        break;

      case "get_contact":
        state.contactActionItems = action.payload as ActionItem[];
        break;

      case "get_meeting":
        state.meetingActionItems = action.payload as ActionItem[];
        break;

      case "add_contact":
        state.actionItems = addResource(state.actionItems, action.payload as ActionItem);
        state.contactActionItems = addResource(state.contactActionItems, action.payload as ActionItem);
        break;

      case "add_meeting":
        state.actionItems = addResource(state.actionItems, action.payload as ActionItem);
        state.meetingActionItems = addResource(state.meetingActionItems, action.payload as ActionItem);
        break;

      case "update":
        state.actionItems = updateResource(state.actionItems, action.payload as ActionItem);
        state.contactActionItems = updateResource(state.contactActionItems, action.payload as ActionItem);
        state.meetingActionItems = updateResource(state.meetingActionItems, action.payload as ActionItem);
        break;

      case "delete":
        state.actionItems = deleteResource(state.actionItems, action.payload as number);
        state.contactActionItems = deleteResource(state.contactActionItems, action.payload as number);
        state.meetingActionItems = deleteResource(state.meetingActionItems, action.payload as number);
        break;

      default:
        break;
    }
  };

const rejected = (key: extraReducersKey) => (state: ActionItemState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;
};

const initialState: ActionItemState = {
  actionItems: [],
  contactActionItems: [],
  meetingActionItems: [],
  errors: {},
  status: {},
};

export const actionItemSlice = createSlice({
  name: "action-item",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getActionItems, "primary");
    handleAsyncActions(builder, getContactActionItems, "get_contact");
    handleAsyncActions(builder, getMeetingActionItems, "get_meeting");
    handleAsyncActions(builder, updateActionItem, "update");
    handleAsyncActions(builder, addContactActionItem, "add_contact");
    handleAsyncActions(builder, addMeetingActionItem, "add_meeting");
    handleAsyncActions(builder, deleteActionItem, "delete");
  },
});

const handleAsyncActions = (
  builder: ActionReducerMapBuilder<ActionItemState>,
  actionThunk: any,
  actionKey: extraReducersKey
) => {
  builder
    .addCase(actionThunk.pending, pending(actionKey))
    .addCase(actionThunk.fulfilled, fulfilled(actionKey))
    .addCase(actionThunk.rejected, rejected(actionKey));
};

type extraReducersKey = "primary" | "update" | "add_contact" | "add_meeting" | "delete" | "get_contact" | "get_meeting";

export const { resetStatus, resetError } = actionItemSlice.actions;
export default actionItemSlice.reducer;
