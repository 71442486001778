import { Box, TextareaProps, ResponsiveValue, Textarea } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import * as CSS from "csstype";

interface DynamicTextareaProps extends TextareaProps {
  value?: string | number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  placeholderColor?: ResponsiveValue<CSS.Property.Color>;
  autoFocus?: boolean;
  onEnter?: () => void;
  maxWidthInPx?: number;
  fullWidth?: boolean;
}

const DynamicTextarea: React.FC<DynamicTextareaProps> = ({
  value,
  placeholder,
  placeholderColor,
  onChange,
  autoFocus,
  onEnter,
  maxWidthInPx,
  fullWidth,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const spanRef = useRef<HTMLDivElement>(null);

  const [rows, setRows] = useState(1);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (textareaRef.current && spanRef.current) {
      const computedStyle = window.getComputedStyle(textareaRef.current);

      spanRef.current.style.fontSize = computedStyle.fontSize;
      spanRef.current.style.fontFamily = computedStyle.fontFamily;
      spanRef.current.style.fontWeight = computedStyle.fontWeight;
      spanRef.current.style.fontStyle = computedStyle.fontStyle;
      spanRef.current.style.lineHeight = computedStyle.lineHeight;

      spanRef.current.style.paddingTop = computedStyle.paddingTop;
      spanRef.current.style.paddingRight = computedStyle.paddingRight;
      spanRef.current.style.paddingBottom = computedStyle.paddingBottom;
      spanRef.current.style.paddingLeft = computedStyle.paddingLeft;
      spanRef.current.style.borderWidth = computedStyle.borderWidth;
      spanRef.current.style.borderStyle = computedStyle.borderStyle;
    }
  }, [textareaRef.current, spanRef.current]);

  useEffect(() => {
    setTimeout(() => {
      if (textareaRef.current && spanRef.current) {
        const width = spanRef.current.getBoundingClientRect().width;
        if (width === 0) {
          return;
        }
        if (!fullWidth) {
          textareaRef.current.style.width = width + 12 + "px";
          if (textareaRef.current.parentElement) {
            const parentWidth = textareaRef.current.parentElement.getBoundingClientRect().width;

            if (parentWidth > 0 && width > 0) {
              const maxWidth = Math.min(parentWidth, maxWidthInPx ? maxWidthInPx : 99999999999);
              textareaRef.current.style.maxWidth = maxWidth + "px";
              setRows(Math.ceil(width / maxWidth));
            }
          }
        } else {
          const maxWidth = Math.min(
            textareaRef.current.getBoundingClientRect().width,
            maxWidthInPx ? maxWidthInPx : 99999999999
          );
          const rows = Math.ceil(width / maxWidth);
          const enters = (textareaRef.current.innerHTML.match(/\n/g) || []).length;
          setRows(rows + enters);
        }
      }
    }, 0);
  }, [localValue, textareaRef.current, spanRef.current]);

  useEffect(() => {
    if (textareaRef.current && autoFocus) {
      setTimeout(() => {
        textareaRef.current?.focus();
      }, 100);
    }
  }, [textareaRef.current]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      textareaRef.current?.blur();
      onEnter && onEnter();
    } else if (e.key === "Escape") {
      textareaRef.current?.blur();
    }
  };

  return (
    <>
      <Textarea
        className="dynamic-textarea"
        ref={textareaRef}
        resize={"none"}
        value={localValue}
        onChange={(e) => {
          onChange && onChange(e);
          setLocalValue(e.target.value);
        }}
        border={0}
        borderRadius={0}
        _focusVisible={{ border: 0 }}
        placeholder={placeholder}
        minH={0}
        px={0}
        rows={rows}
        {...rest}
        onKeyDown={handleKeyDown}
        {...(maxWidthInPx ? { maxW: maxWidthInPx + "px" } : {})}
        sx={{
          ...rest.sx,
          ...{
            "&::placeholder": placeholderColor ? { color: placeholderColor } : { color: "gray.300" },
            "&::-webkit-scrollbar": { scrollbarWidth: "none" },
          },
        }}
      />
      <Box
        ref={spanRef}
        w={"fit-content"}
        whiteSpace={"nowrap"}
        opacity={0}
        visibility={"hidden"}
        pos={"absolute"}
        top={0}
        right={0}
        minW={10}
      >
        {localValue ? localValue : placeholder ? placeholder : "---"}
      </Box>
    </>
  );
};

export default DynamicTextarea;
