import { Meeting, MeetingsByDate } from "components/meetings/types";

export const subtractArrays = <T>(arr1: T[], arr2: T[]) => {
  return arr1.filter((item) => !arr2.includes(item));
};

export const pickRandomItem = (arr: any[]) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const shallowCopy = <T>(value: T): T => {
  return JSON.parse(JSON.stringify(value));
};

export const getMaxValue = (arr: any[], key: string) => {
  return arr.reduce((max, obj) => Math.max(max, Number(obj[key])), -Infinity);
};

export const sortByDate = (arr: any[], key: string) => {
  return [...arr].sort((a, b) => new Date(a[key]).getTime() - new Date(b[key]).getTime());
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", { month: "long", day: "2-digit", year: "numeric", timeZone: "UTC" }).format(
    date
  );
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func.apply(this, args), wait);
  };
};

const isPriorityKey = (key: string): key is "past" | "today" | "tomorrow" => {
  return ["past", "today", "tomorrow"].includes(key);
};

export const sortMeetingKeys = (keys: string[]): string[] => {
  const priority = { past: 1, today: 2, tomorrow: 3 };

  return keys.sort((a, b) => {
    if (isPriorityKey(a) && isPriorityKey(b)) {
      return priority[a] - priority[b];
    } else if (isPriorityKey(a)) {
      return -1;
    } else if (isPriorityKey(b)) {
      return 1;
    }

    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  });
};

export const formatMeetingDate = (dateStr: string) => {
  const userLocale = navigator.language;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  const date = new Date(dateStr);
  const formattedDate = date.toLocaleDateString(userLocale, options);

  return formattedDate.replace(/[\.\-]/g, "/");
};

export const formatMeetingDuration = (date: string, duration: number): string => {
  const startDate = new Date(`${date}Z`); // Adding 'Z' ensures the date is treated as UTC
  const endDate = new Date(startDate.getTime() + duration * 60000); // Duration in milliseconds
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const startTime = startDate.toLocaleTimeString("en-US", timeOptions);
  const endTime = endDate.toLocaleTimeString("en-US", timeOptions);

  return `${startTime} - ${endTime}`;
};

export const formatMeetingTime = (startDateStr: string, endDateStr: string): string => {
  const startDate = new Date(`${startDateStr}Z`); // Ensures the date is treated as UTC
  const endDate = new Date(`${endDateStr}Z`); // Ensures the date is treated as UTC

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const startTime = startDate.toLocaleTimeString("en-US", timeOptions);
  const endTime = endDate.toLocaleTimeString("en-US", timeOptions);

  return `${startTime} - ${endTime}`;
};

export const formatMeetingTitle = (key: string): string => {
  const dateFormatter = new Intl.DateTimeFormat("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });

  if (key === "past") {
    return "Past meetings";
  } else if (key === "today") {
    return `Today • ${dateFormatter.format(new Date())}`;
  } else if (key === "tomorrow") {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return `Tomorrow • ${dateFormatter.format(tomorrow)}`;
  } else {
    // Assuming the key is a date for future meetings
    const meetingDate = new Date(key);
    return dateFormatter.format(meetingDate);
  }
};

export const splitMeetings = (
  meetings: Meeting[],
  timeFilter: "past" | "future" | "all" = "all",
  limitDays?: number
): MeetingsByDate => {
  const meetingsSplitted: MeetingsByDate = {
    past: [],
    today: [],
    tomorrow: [],
  };

  // Get today's and tomorrow's date in UTC
  const nowStr = new Date().toUTCString();
  const today = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()));
  const tomorrow = new Date(today);
  tomorrow.setUTCDate(today.getUTCDate() + 1);

  const sortedMeetings = sortByDate(
    limitDays
      ? meetings.filter(
          (meeting) =>
            subtractDatesStrInMinutes(meeting.start_time, today.toDateString()) > 0 ||
            subtractDatesStrInMinutes(today.toDateString(), meeting.start_time) < limitDays * 24 * 60
        )
      : meetings,
    "start_time"
  );
  sortedMeetings.forEach((meeting) => {
    // Convert meeting date to a Date object and extract the date part in UTC
    const meetingDate = new Date(meeting.start_time);
    const meetingDateStr = meetingDate.toISOString().split("T")[0];

    // Check if the meeting date is in the past, today, or tomorrow, and categorize accordingly
    if (meetingDate < today || subtractDatesStrInMinutes(nowStr, meeting.end_time + "Z") < 0) {
      if (timeFilter === "all" || timeFilter === "past") meetingsSplitted.past.push(meeting);
    } else if (meetingDateStr === today.toISOString().split("T")[0]) {
      if (timeFilter === "all" || timeFilter === "future") meetingsSplitted.today.push(meeting);
    } else if (meetingDateStr === tomorrow.toISOString().split("T")[0]) {
      if (timeFilter === "all" || timeFilter === "future") meetingsSplitted.tomorrow.push(meeting);
    } else {
      // For future dates, group by date (in UTC)
      if (timeFilter === "all" || timeFilter === "future") {
        if (!meetingsSplitted[meetingDateStr]) {
          meetingsSplitted[meetingDateStr] = [];
        }
        meetingsSplitted[meetingDateStr].push(meeting);
      }
    }
  });

  return meetingsSplitted;
};

export const addResource = <T>(array: T[], added: T): T[] => {
  return [...array, added];
};

export const updateResource = <T extends { id: number }>(array: T[], updated: T): T[] => {
  return array.map((old) => (old.id === updated.id ? updated : old));
};

export const deleteResource = <T extends { id: number }>(array: T[], deleteId: number): T[] => {
  return array.filter((old) => old.id !== Number(deleteId));
};

export function hasCommonElement<T, K extends keyof T>(arr1: T[], arr2: T[], key?: K): boolean {
  if (key) {
    const set = new Set(arr1.map((item) => item[key]));
    return arr2.some((item) => set.has(item[key]));
  } else {
    const set = new Set(arr1);
    return arr2.some((element) => set.has(element));
  }
}

export function hasCommonElementInObjects<T>(
  obj1: { [key: string]: T[] },
  obj2: { [key: string]: T[] },
  key: string
): boolean {
  const arr1 = obj1[key];
  const arr2 = obj2[key];
  return hasCommonElement(arr1, arr2);
}

export function formatMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${minutes}min`;
  } else if (minutes < 60 * 24) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}hr, ${remainingMinutes}min`;
  } else {
    const days = Math.floor(minutes / (60 * 24));
    const remainingHours = Math.floor((minutes % (60 * 24)) / 60);
    const remainingMinutes = minutes % 60;
    return `${days}days, ${remainingHours}hr, ${remainingMinutes}min`;
  }
}

export function simpleHash(input: string, length = 16) {
  let hash = 0;
  let extendedHash = "";
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer

    // Convert to hex and append to extendedHash
    const partialHash = hash.toString(16);
    extendedHash += partialHash;
  }

  extendedHash = Math.abs(hash).toString(16) + extendedHash; // Ensure starting with a numerical base hash
  extendedHash = extendedHash.substring(0, length).padStart(length, "0");
  return extendedHash;
}

export function generateTimeBasedHash(extraString = "") {
  // Use the current timestamp and optional string
  const input = `${Date.now()}${extraString}`;

  // Create a SHA-256 hash of the input

  return simpleHash(input);
}

export const subtractDatesStrInMinutes = (startStr?: string, endStr?: string) => {
  if (!startStr || !endStr) return -1;
  const startDate = new Date(startStr);
  const endDate = new Date(endStr);
  const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
  return Math.round(differenceInMilliseconds / 60000);
};

export function isDateInPast(dateString: string): boolean {
  const inputDate = new Date(dateString + "Z");
  const now = new Date();

  return inputDate.getTime() < now.getTime();
}

export const bubbleElement = <T>(array: T[], index: number, direction: "up" | "down") => {
  if (direction !== "up" && direction !== "down") return array;
  if ((direction === "up" && index <= 0) || (direction === "down" && index >= array.length - 1)) return array;

  let updatedArray = [...array];

  if (direction === "up") {
    [updatedArray[index], updatedArray[index - 1]] = [updatedArray[index - 1], updatedArray[index]];
  } else {
    //direction === down
    [updatedArray[index], updatedArray[index + 1]] = [updatedArray[index + 1], updatedArray[index]];
  }
  return updatedArray;
};

export const adjustIndexArrayAfterReorder = (indexArray: number[], currentIndex: number, direction: "up" | "down") => {
  return indexArray.map((index) => {
    if (index === currentIndex) {
      return direction === "up" ? currentIndex - 1 : currentIndex + 1;
    } else if (direction === "up" ? index === currentIndex - 1 : index === currentIndex + 1) {
      return currentIndex;
    }
    return index;
  });
};
