import { Box, BoxProps, HStack, Text } from "@chakra-ui/react";
import { MeetingResultsSection as MeetingResultsSectionType } from "components/meetings/types";
import { ReactComponent as MeetingSectionIcon } from "assets/icons/meeting-section.svg";
import DynamicInput from "components/base/DynamicInput";
import Icon from "components/base/Icon";
import { useState } from "react";
import IconButton from "components/base/IconButton";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface MeetingResultsSectionHeaderProps extends BoxProps {
  section: MeetingResultsSectionType;
  onSectionChange: (section: MeetingResultsSectionType | null) => void;
  viewOnly?: boolean;
  autoFocus?: boolean;
}

const MeetingResultsSectionHeader: React.FC<MeetingResultsSectionHeaderProps> = ({
  section,
  onSectionChange,
  viewOnly,
  autoFocus,
  ...rest
}) => {
  const [name, setName] = useState(section.name ? section.name : "");

  return (
    <Box position="relative" {...rest}>
      <HStack className="meeting-results-section-header" gap={3}>
        <Text color={"gray.800"} fontWeight={500} fontSize={"xl"}>
          {name}
        </Text>
      </HStack>
      {viewOnly && <Box position="absolute" top={0} left={0} right={0} bottom={0} bg="transparent" cursor="default" />}
    </Box>
  );
};

export default MeetingResultsSectionHeader;
