import { createSlice, createAsyncThunk, PayloadAction, ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { get, http_delete, post, put } from "api/requests";
import { Meeting, MeetingResultsSection, MeetingTemplate, MeetingVideo, Tag } from "components/meetings/types";
import { MeetingState } from "./meetingTypes";
import { AsyncThunkActionError } from "reducers/baseTypes";
import { addResource, deleteResource, updateResource } from "utils/common";

const API_BASE = process.env.REACT_APP_TAILORR_API_ADDRESS;

export const getMeetings = createAsyncThunk("meeting/getMeetings", async () => {
  const url = `${API_BASE}/meeting/`;
  return await get(url);
});

export const getMyMeetings = createAsyncThunk("meeting/getMyMeetings", async () => {
  const url = `${API_BASE}/meeting/me`;
  return await get(url);
});

export const getContactMeetings = createAsyncThunk("meeting/getContactMeetings", async (id: number) => {
  const url = `${API_BASE}/meeting/contact/${id}`;
  return await get(url);
});

export const getMeetingVideo = createAsyncThunk("meeting/getMeetingVideo", async (id: number) => {
  const url = `${API_BASE}/meeting-video/${id}`;
  return await get(url);
});

export const updateMeetingVideo = createAsyncThunk(
  "meeting/updateMeetingVideo",
  async (body: { id: number; meeting_results: MeetingResultsSection[] }) => {
    const { id, ...rest } = body;
    const url = `${API_BASE}/meeting-video/${id}`;
    return await put(url, rest);
  }
);

export const updateMeeting = createAsyncThunk("meeting/updateMeeting", async (body: Partial<Meeting>) => {
  const { id, ...rest } = body;
  const url = `${API_BASE}/meeting/${id}`;
  return await put(url, rest);
});

export const addMeeting = createAsyncThunk("meeting/addMeeting", async (body: Partial<Meeting>) => {
  const url = `${API_BASE}/meeting/`;
  return await post(url, body);
});

export const addMeetingTag = createAsyncThunk("meeting/addMeetingTag", async (body: Tag) => {
  const { id, ...rest } = body;

  const url = `${API_BASE}/meeting/${id}/tag`;
  return await post(url, rest);
});

export const addMeetingTemplate = createAsyncThunk(
  "meeting/addMeetingTemplate",
  async (body: Partial<MeetingTemplate> & { meetingId: number }) => {
    const { meetingId, ...rest } = body;

    const url = `${API_BASE}/meeting/${meetingId}/template`;
    return await post(url, rest);
  }
);

export const deleteMeeting = createAsyncThunk("meeting/deleteMeeting", async (id: number) => {
  const url = `${API_BASE}/meeting/${id}`;
  await http_delete(url);
  return id;
});

const pending = (key: extraReducersKey) => (state: MeetingState) => {
  state.status[key] = "fetching";
};

const fulfilled =
  (key: extraReducersKey) =>
  (state: MeetingState, action: PayloadAction<Meeting[] | Meeting | number | MeetingVideo>) => {
    state.status[key] = "fulfilled";
    delete state.errors[key];

    switch (key) {
      case "primary":
        state.meetings = action.payload as Meeting[];
        break;

      case "get_contact":
        state.contactMeetings = action.payload as Meeting[];
        break;

      case "get_mine":
        state.myMeetings = action.payload as Meeting[];
        break;

      case "delete":
        state.meetings = deleteResource(state.meetings, action.payload as number);
        state.contactMeetings = deleteResource(state.contactMeetings, action.payload as number);
        state.myMeetings = deleteResource(state.myMeetings, action.payload as number);
        break;

      case "add":
        state.meetings = addResource(state.meetings, action.payload as Meeting);
        break;

      case "update":
      case "add_tag":
      case "add_template":
        state.meetings = updateResource(state.meetings, action.payload as Meeting);
        state.contactMeetings = updateResource(state.contactMeetings, action.payload as Meeting);
        state.myMeetings = updateResource(state.myMeetings, action.payload as Meeting);
        break;

      case "get_video":
      case "update_video":
        state.meetingVideo = action.payload as MeetingVideo;
        break;

      default:
        break;
    }
  };

const rejected = (key: extraReducersKey) => (state: MeetingState, action: any) => {
  let typedAction = action as AsyncThunkActionError;
  state.status[key] = "rejected";
  state.errors[key] = typedAction.error.message;

  switch (key) {
    case "get_video":
      state.meetingVideo = null;
      break;

    default:
      break;
  }
};

const initialState: MeetingState = {
  meetings: [],
  contactMeetings: [],
  myMeetings: [],
  meetingVideo: null,
  errors: {},
  status: {},
};

export const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    resetError: (state, action: PayloadAction<string>) => {
      delete state.errors[action.payload];
    },
    resetStatus: (state, action: PayloadAction<string>) => {
      delete state.status[action.payload];
    },
  },
  extraReducers: (builder) => {
    handleAsyncActions(builder, getMeetings, "primary");
    handleAsyncActions(builder, updateMeeting, "update");
    handleAsyncActions(builder, addMeeting, "add");
    handleAsyncActions(builder, addMeetingTag, "add_tag");
    handleAsyncActions(builder, addMeetingTemplate, "add_template");
    handleAsyncActions(builder, deleteMeeting, "delete");
    handleAsyncActions(builder, getContactMeetings, "get_contact");
    handleAsyncActions(builder, getMyMeetings, "get_mine");
    handleAsyncActions(builder, getMeetingVideo, "get_video");
    handleAsyncActions(builder, updateMeetingVideo, "update_video");
  },
});

const handleAsyncActions = (
  builder: ActionReducerMapBuilder<MeetingState>,
  actionThunk: any,
  actionKey: extraReducersKey
) => {
  builder
    .addCase(actionThunk.pending, pending(actionKey))
    .addCase(actionThunk.fulfilled, fulfilled(actionKey))
    .addCase(actionThunk.rejected, rejected(actionKey));
};

type extraReducersKey =
  | "primary"
  | "update"
  | "add"
  | "delete"
  | "get_mine"
  | "add_tag"
  | "get_contact"
  | "add_template"
  | "get_video"
  | "update_video";

export const { resetStatus, resetError } = meetingSlice.actions;
export default meetingSlice.reducer;
