import { Box, BoxProps, Divider, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import EnterInput from "components/base/EnterInput";
import { ReactComponent as CheckmarkIcon } from "assets/icons/checkmark.svg";
import { SelectOption, StatusOption } from "./Meta";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";

interface OptionMenuProps extends BoxProps {
  options?: string[];
  selected?: string;
  onOptionSelect?: (selected: string) => void;
  name: string;
  onDelete: React.MouseEventHandler<HTMLDivElement>;
  onNameChange: React.ChangeEventHandler<HTMLInputElement>;
  onNameBlur: React.FocusEventHandler<HTMLInputElement>;
}

const OptionMenu: React.FC<OptionMenuProps> = ({
  options,
  selected,
  onOptionSelect,
  name,
  onNameChange,
  onNameBlur,
  onDelete,
  ...rest
}) => {
  return (
    <Box p={2} {...rest}>
      <EnterInput
        value={name}
        onChange={onNameChange}
        onBlur={(e, isEnterKey) => {
          e.currentTarget.blur();
          onNameBlur(e);
        }}
      />
      <HStack
        onClick={onDelete}
        _hover={{ color: "red.500", "& svg path": { fill: "red.500" } }}
        cursor={"pointer"}
        px={1}
        py={0.5}
        borderRadius={4}
      >
        <Icon as={DeleteIcon} fontSize={"sm"} ml={1} />
        <Box fontSize={"sm"}>Delete</Box>
      </HStack>
      {options && onOptionSelect && (
        <>
          <Divider my={2} />
          <Flex flexDir={"column"} gap={0.5}>
            {options.map((option) => {
              return (
                <HStack
                  cursor={"pointer"}
                  px={1}
                  py={0.25}
                  borderRadius={4}
                  _hover={{ bgColor: "gray.50" }}
                  _active={{ bgColor: "gray.100" }}
                  key={option}
                  alignItems={"center"}
                  onClick={() => {
                    onOptionSelect(option);
                  }}
                >
                  <Box
                    ml={1}
                    h={4}
                    w={4}
                    borderRadius={4}
                    bgColor={`${option}.100`}
                    border={"1px"}
                    borderColor={`${option}.200`}
                    // color={`${option}.600`}
                  />
                  <Box fontSize={"sm"} flex={1}>
                    {option}
                  </Box>
                  {option === selected && <Icon as={CheckmarkIcon} fontSize={"sm"} mr={1} />}
                </HStack>
              );
            })}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default OptionMenu;
