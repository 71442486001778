import React, { useEffect, useRef, useState, useCallback } from "react";
import Quill from "quill";
import { Box, Flex } from "@chakra-ui/react";
import { debounce } from "utils/common";
import { Delta } from "quill/core";

interface QuillEditorProps {
  defaultEditor: Delta | null | undefined;
  saveEditor: (editor: Delta) => void;
}

const QuillEditor: React.FC<QuillEditorProps> = ({ defaultEditor, saveEditor }) => {
  const quillRef = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<Quill | null>(null);

  useEffect(() => {
    if (quillRef.current && !editor) {
      const newEditor = new Quill(quillRef.current, {
        theme: "snow",
        modules: {
          toolbar: [
            [{ font: [] }],
            [{ size: ["small", "normal", "large", "huge"] }], // custom dropdown
            ["bold", "italic", "underline", "strike", { color: [] }, { background: [] }], // toggled buttons
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ],
        },
        placeholder: "Write something...",
      });

      if (defaultEditor) newEditor.setContents(defaultEditor, "api");
      setEditor(newEditor);
    }
  }, [editor, defaultEditor]);

  const debouncedSave = useCallback(
    debounce(() => {
      if (editor) {
        const delta = editor.getContents();
        saveEditor(delta);
      }
    }, 1500),
    [editor, saveEditor]
  );

  useEffect(() => {
    if (editor) {
      editor.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          debouncedSave();
        }
      });

      // Cleanup function to remove the event listener
      return () => {
        editor.off("text-change");
      };
    }
  }, [editor, debouncedSave]);

  return (
    <Box id="contact-notes" color="gray.800">
      <Flex flexDir="column-reverse" px={4} pb={4}>
        <div ref={quillRef} />
      </Flex>
    </Box>
  );
};

export default QuillEditor;
